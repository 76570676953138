import { Choice, choiceFactory, ChoiceFactoryOption } from '@/features/choices'
import { SimpleStaffResponseV1 } from '@/lib/api-client/aspida/@types'

export type SelectableStaffs = Pick<
  SimpleStaffResponseV1,
  'id' | 'full_name' | 'is_past' | 'in_use'
>[]
type Props = {
  value: SelectableStaffs
  option?: ChoiceFactoryOption
}

const mapToChoice = (value: SelectableStaffs) => {
  return value.map(({ id, full_name, in_use, is_past }) => {
    const label = !in_use
      ? `${full_name} (停止中)`
      : is_past
      ? `${full_name} (過去所属)`
      : full_name

    const choice: Choice = {
      value: id,
      label,
    }
    return choice
  })
}

export const createStaffsChoices = ({
  value,
  option = {
    noBlank: true,
  },
}: Props) => {
  const createChoices = choiceFactory(option)

  const choices = createChoices(mapToChoice(value))

  return choices
}
