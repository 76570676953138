import { PrivilegeKind } from '@/constants/kinds/PrivilegeKind'
import { ReportPrivilegeKind } from '@/constants/kinds/ReportPrivilegeKind'
import type * as Types from '@/lib/api-client/aspida/@types'

export class MeModel {
  private response: Types.StaffResponseV1
  private roles: Types.StaffRoleResponseV1[]

  constructor(
    response: Types.StaffResponseV1,
    roles: Types.StaffRoleResponseV1[]
  ) {
    this.response = response
    this.roles = roles
  }

  /**
   * ロールへのアクセサ
   * スタッフの権限とレポートの権限をまとめたオブジェクト
   * */
  public get role() {
    const staffRole = createStaffRole({
      staff: this.response,
      roles: this.roles,
    })

    return {
      ...staffRole,
      report: createReportPrivilege({ staffRole }),
    }
  }

  public getResponse() {
    return this.response
  }

  /** メイン店舗 + サブ店舗の集合 */
  public getShops() {
    return [this.response.primary_shop, ...this.response.secondary_shops]
  }
}

type StaffRoleInput = {
  /** 該当スタッフ */
  staff: Types.StaffResponseV1

  /**
   * ロール一覧
   * GET /meのレスポンスには該当スタッフの権限の詳細が含まれないため個別に受け取る
   * */
  roles: Types.StaffRoleResponseV1[]
}

type StaffRoleReturn = Pick<
  Types.StaffRoleResponseV1,
  'is_admin' | 'privileges' | 'report_privileges'
> & {
  /** 該当の権限を持っているか */
  has: (privilegeKind: PrivilegeKind.ids) => boolean

  /** 顧客管理参照の参照権限を持っているか */
  canShowCustomersPage: boolean

  /** 予約管理参照の参照権限を持っているか */
  canShowReservationsPage: boolean

  /** 店舗管理参照の参照権限を持っているか */
  canShowShopsPage: boolean

  /** マスタ管理参照の参照権限を持っているか */
  canShowMastersPage: boolean

  /** 設定管理参照の参照権限を持っているか */
  canShowSettingsPage: boolean

  /** 電子カルテ参照の参照権限を持っているか */
  canShowEkartePage: boolean
}

const createStaffRole = (input: StaffRoleInput): StaffRoleReturn => {
  const { staff, roles } = input

  const role = roles.find(({ id }) => id === staff.staff_role.id)

  if (role === undefined) {
    throw Error(
      `role cannot find ${JSON.stringify(staff, null, 2)} , ${JSON.stringify(
        roles,
        null,
        2
      )}`
    )
  }

  const { is_admin } = role

  const has = (privilegeKind: PrivilegeKind.ids) => {
    if (is_admin) {
      return true
    }

    return !!role.privileges.find((p) => p.privilege_kind === privilegeKind)
  }

  return {
    ...role,

    has,
    canShowCustomersPage: has(PrivilegeKind.Customer.id),
    canShowReservationsPage: has(PrivilegeKind.Reservation.id),
    canShowShopsPage: has(PrivilegeKind.Shop.id),
    canShowMastersPage: has(PrivilegeKind.Master.id),
    canShowSettingsPage: has(PrivilegeKind.Setting.id),
    canShowEkartePage: has(PrivilegeKind.Ekarte.id),
  }
}

type ReportPrivilegeInput = {
  staffRole: ReturnType<typeof createStaffRole>
}

type ReportPrivilegeReturn = {
  /** 該当の権限を持っているか */
  has: (reportPrivilegeKind: ReportPrivilegeKind.ids) => boolean

  /**
   * レポートのページ自体の参照権限を持ってるか
   * 各レポートいずれかの参照権限を持っていたら参照可能
   * */
  canShowReportPage: boolean

  /** 経営レポートの参照権限を持っているか */
  canShowBusinessPage: boolean

  /** 売上レポートの参照権限を持っているか */
  canShowSalesPage: boolean

  /** スタッフレポートの参照権限を持っているか */
  canShowStaffPage: boolean

  /** アンケートレポートの参照権限を持っているか */
  canShowEnquetePage: boolean

  /** 販売レポートの参照権限を持っているか */
  canShowContractPage: boolean

  /** 予約レポートの参照権限を持っているか */
  canShowReservationsPage: boolean
}

const createReportPrivilege = (
  input: ReportPrivilegeInput
): ReportPrivilegeReturn => {
  const {
    staffRole: { is_admin, report_privileges },
  } = input

  const has = (reportPrivilegeKind: ReportPrivilegeKind.ids) => {
    if (is_admin) {
      return true
    }

    return !!report_privileges.find(
      (p) => p.report_privilege_kind === reportPrivilegeKind
    )
  }

  return {
    has,

    canShowReportPage: is_admin || report_privileges.length > 0,
    canShowBusinessPage: has(ReportPrivilegeKind.Business.id),
    canShowSalesPage: has(ReportPrivilegeKind.Sales.id),
    canShowStaffPage: has(ReportPrivilegeKind.Staffs.id),
    canShowEnquetePage: has(ReportPrivilegeKind.Enquetes.id),
    canShowContractPage: has(ReportPrivilegeKind.Contracts.id),
    canShowReservationsPage: has(ReportPrivilegeKind.Reservations.id),
  }
}
