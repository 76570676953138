import { ErrorKind } from '@/constants/kinds/ErrorKind'
import { useTenantRootPath } from '@/features/states/url/dynamicPathState/queries'
import firebase from '@/lib/firebase'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

type Props = {
  errorKind: ErrorKind.ids
}

/** ErrorKindごとのエラーハンドリング実装 */
export const useErrorKindHandler = () => {
  const router = useRouter()
  const tenantRootPath = useTenantRootPath()

  const handleErrorKind = useCallback(
    ({ errorKind }: Props) => {
      switch (errorKind) {
        //認証失敗時はログイン画面へリダイレクト
        case ErrorKind.Unauthorized.id: {
          firebase.auth().signOut()
          router.push(tenantRootPath.login.$url())
          break
        }

        //コンフリクト時はアラート表示
        case ErrorKind.Conflict.id: {
          alert(
            '他端末で更新されたため、お手数ですがブラウザを更新後に再操作を行ってください。'
          )
          break
        }
      }
    },
    [router, tenantRootPath]
  )

  return handleErrorKind
}
