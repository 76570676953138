import { OverrideKeys } from '@/types/utility'
import { SnackbarProps } from '@material-ui/core'
import { AlertProps } from '@material-ui/lab'
import { atom } from 'recoil'

export type SnackbarState = OverrideKeys<
  SnackbarProps,
  {
    /** アラート種別 */
    severity?: AlertProps['severity']
    /** 強制非表示フラグ */
    forceClose?: boolean
  }
>

export const snackbarState = atom<SnackbarState>({
  key: 'snackbarState',
  default: {},
})
