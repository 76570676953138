/* eslint-disable @typescript-eslint/no-namespace */ import Kind from '@/document/management/kinds/ReportPrivilegeKind.json'
const ids = {
  Business: 1,
  Sales: 2,
  Staffs: 3,
  Enquetes: 4,
  Contracts: 5,
  Reservations: 6,
} as const
export namespace ReportPrivilegeKind {
  export type ids = (typeof ids)[keyof typeof ids]
  export const entries = Kind.entries
  export const getIdByLabel = (label: string) =>
    entries.find((kind) => kind.value === label)?.id || 0
  export const getLabelById = (id: number) =>
    entries.find((kind) => kind.id === id)?.value || 'undefined'
  export namespace Business {
    export const id = ids.Business
    export const label = getLabelById(id)
  }
  export namespace Sales {
    export const id = ids.Sales
    export const label = getLabelById(id)
  }
  export namespace Staffs {
    export const id = ids.Staffs
    export const label = getLabelById(id)
  }
  export namespace Enquetes {
    export const id = ids.Enquetes
    export const label = getLabelById(id)
  }
  export namespace Contracts {
    export const id = ids.Contracts
    export const label = getLabelById(id)
  }
  export namespace Reservations {
    export const id = ids.Reservations
    export const label = getLabelById(id)
  }
}
