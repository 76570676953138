import { ChoiceFactoryOption } from '@/features/choices'
import { createStaffsChoices } from '@/features/choices/values/Staffs/createStaffsChoices'
import type * as Types from '@/lib/api-client/aspida/@types'
import uniqBy from 'lodash.uniqby'

export class StaffAllModel {
  private response: Types.SimpleStaffResponseV1[]

  constructor(response: Types.SimpleStaffResponseV1[]) {
    this.response = response
  }

  public getResponse() {
    return this.response
  }

  /** 現在の有効なスタッフ */
  private get active() {
    return this.response.filter(
      ({ in_use, is_past, is_system }) => in_use && !is_past && !is_system
    )
  }

  /** 停止中のスタッフ */
  private get disabled() {
    return this.response.filter(
      ({ in_use, is_system }) => !in_use && !is_system
    )
  }

  /** 過去所属のスタッフ */
  private get past() {
    return this.response.filter(
      ({ is_past, is_system }) => is_past && !is_system
    )
  }

  /**
   * Choiceに変換して返す
   * REF: https://caloojp.atlassian.net/wiki/spaces/VIRGINIA/pages/3773724
   * */
  public getStaffChoices(option?: ChoiceFactoryOption) {
    //有効 > 過去所属 > 停止中の順番
    //スタッフが重複しないようにuniqueにする
    const selectableStaffs = uniqBy(
      [...this.active, ...this.past, ...this.disabled],
      'id'
    )

    const choices = createStaffsChoices({
      value: selectableStaffs,
      option,
    })

    return choices
  }
}
