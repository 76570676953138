import { FIREBASE_TENANT_ID_KEY } from '@/constants/localStorage'
import type * as Types from '@/lib/api-client/aspida/@types'

export class GetSigninInfoModel {
  private response: Types.SigninInfoResponseV1

  constructor(response: Types.SigninInfoResponseV1) {
    this.response = response
  }

  public getResponse() {
    return this.response
  }

  /** firebaseの認証に利用しているtenantIdと現在開いているURLのテナントのtenantIdと同一かどうか */
  public isDirtyTenantId() {
    const { firebase_tenant_id } = this.response
    const lastTenantId = localStorage.getItem(FIREBASE_TENANT_ID_KEY)

    if (
      process.env.NODE_ENV == 'development' &&
      process.env.NEXT_PUBLIC_USE_FIREBASE_EMULATOR == 'true'
    ) {
      return false
    }

    return firebase_tenant_id !== lastTenantId
  }
}
