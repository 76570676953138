import { RecoilValue, RecoilValueReadOnly, selector } from 'recoil'
import { uuidV4 } from '../../uuid'

/*
  マッピングSelectorのショートハンドユーティリティ
  targetAtomをmapして新しいSelectorを返す
*/
export const atomWithMapper = <T, Mapper extends (value: T) => any>(
  targetAtom: RecoilValue<T>,
  mapper: Mapper
): RecoilValueReadOnly<ReturnType<Mapper>> => {
  return selector({ key: uuidV4(), get: ({ get }) => mapper(get(targetAtom)) })
}
