/* eslint-disable @typescript-eslint/no-namespace */ import Kind from '@/document/management/kinds/PrivilegeKind.json'
const ids = {
  Customer: 1,
  Reservation: 2,
  Shop: 3,
  Master: 4,
  Setting: 5,
  Ekarte: 6,
} as const
export namespace PrivilegeKind {
  export type ids = (typeof ids)[keyof typeof ids]
  export const entries = Kind.entries
  export const getIdByLabel = (label: string) =>
    entries.find((kind) => kind.value === label)?.id || 0
  export const getLabelById = (id: number) =>
    entries.find((kind) => kind.id === id)?.value || 'undefined'
  export namespace Customer {
    export const id = ids.Customer
    export const label = getLabelById(id)
  }
  export namespace Reservation {
    export const id = ids.Reservation
    export const label = getLabelById(id)
  }
  export namespace Shop {
    export const id = ids.Shop
    export const label = getLabelById(id)
  }
  export namespace Master {
    export const id = ids.Master
    export const label = getLabelById(id)
  }
  export namespace Setting {
    export const id = ids.Setting
    export const label = getLabelById(id)
  }
  export namespace Ekarte {
    export const id = ids.Ekarte
    export const label = getLabelById(id)
  }
}
