export type ChoiceValue = number | string | undefined | null

export type Choice<T extends ChoiceValue = number> = {
  value: T
  label: React.ReactNode | string
  [k: string]: any
}

//Choiceとして利用可能なkey, `make generate/choiceable-keys` で生成する
type SelectableKey =
  | 'presetMachines'
  | 'productCategories'
  | 'reservationCategories'
  | 'shops'
  | 'staffs'
  | 'communicationCategories'
  | 'assetTypeKind'
  | 'assignmentKind'
  | 'assignmentStatusKind'
  | 'bankAccountTypeKind'
  | 'claimStatusKind'
  | 'communicationDetailableKind'
  | 'communicationManagementKind'
  | 'contracts'
  | 'contractPenaltyDetailableKind'
  | 'contractPenaltyKind'
  | 'contractPenaltyReasonKind'
  | 'contractResultKind'
  | 'contractUpdateKind'
  | 'contractInAble'
  | 'contractAll'
  | 'counselingResultKind'
  | 'counselings'
  | 'creditCardBrandKind'
  | 'customerInfoKind'
  | 'digitalTicketMenuActionKind'
  | 'digitalTicketMenuActionMenuKind'
  | 'digitalTicketMenuKind'
  | 'digitalTicketMenuTemplateKind'
  | 'digitalTicketStatusKind'
  | 'digitalTicketUseFeatureKind'
  | 'discountKind'
  | 'documentTemplateTargetKind'
  | 'existsMemoKind'
  | 'featureKind'
  | 'feeBurdenKind'
  | 'fileUploadStatusKind'
  | 'genderKind'
  | 'holidayKind'
  | 'iconKind'
  | 'industryKind'
  | 'jobKind'
  | 'lineSyncStatusKind'
  | 'loanPaymentStatusKind'
  | 'logicalOperatorKind'
  | 'machineKind'
  | 'messageKind'
  | 'messageSendTargetAgeKind'
  | 'messageSendTargetConditionKind'
  | 'messageSendTargetContractStatusKind'
  | 'messageSendTargetFutureReservationKind'
  | 'messageSendTargetGenderKind'
  | 'messageSendTargetVisitingStatusKind'
  | 'messageSendTimingDayOfMonthKind'
  | 'messageSendTimingEventKind'
  | 'messageSendTimingEventUnitKind'
  | 'messageSendTimingIntervalUnitKind'
  | 'messageSendTimingKind'
  | 'messageStatusKind'
  | 'messageTemplateOptionAttachmentKind'
  | 'messageTemplateOptionKind'
  | 'monthKind'
  | 'operatorKind'
  | 'paperlessTemplateTargetKind'
  | 'paymentMethodKind'
  | 'preContactKind'
  | 'prefectureKind'
  | 'privilegeKind'
  | 'productKind'
  | 'questionKind'
  | 'refundStatusKind'
  | 'reportKind'
  | 'reportPrivilegeKind'
  | 'reservationDetailableKind'
  | 'reservationGroupUpdateKind'
  | 'reservationKind'
  | 'reservationReceptionStartKind'
  | 'reservationRouteKind'
  | 'salesPromotions'
  | 'scheduleStatusKind'
  | 'settlementStatusKind'
  | 'shopKind'
  | 'shopResourceDetailableKind'
  | 'shopWorkingTimeWeekdayKind'
  | 'staffShiftScheduleWeekdayKind'
  | 'supportStatusKind'
  | 'taxRateKind'
  | 'templateFileTypeKind'
  | 'templateKind'
  | 'tenantLogoKind'
  | 'treatmentHistoryKind'
  | 'uncontractedReasonKind'
  | 'visitationStatusKind'
  | 'webReservationTargetKind'
  | 'weekdayKind'
  | 'staffRoles'
  | 'paymentLoanCompanies'
  | 'paymentAgencies'
  | 'paymentAgenciesPaymentMethodKind'
  | 'unitTimeKind'
  | 'products'
  | 'inflowRoutes'
  | 'machines'
  | 'rooms'
  | 'reservationContents'
  | 'reservationGroups'
  | 'customerRemarks'
  | 'mailTemplates'
  | 'smsTemplates'
  | 'lineTemplates'
  | 'messageSendTargetConditionExceptAllKind'
  | 'messageSendTargetInterviewAnswerKind'
  | 'messageSendTimingEventTimingOneKind'
  | 'messageSendTimingEventTimingTwoKind'
  | 'hourKind'
  | 'lineMessageTypeKind'
  | 'lineActionTypeKind'
  | 'layoutColorKind'
  | 'messageSendTargetDutyRemainingKind'
  | 'dutyProducts'
  | 'staffGroups'

export type Choices<
  T extends SelectableKey,
  Value extends ChoiceValue = number
> = {
  [K in T]: Choice<Value>[]
}

export type ChoiceFactoryOption = {
  noBlank?: boolean
}

/**
 * 未選択のChoice
 * */
export const BLANK_CHOICE = Object.freeze<Choice<typeof undefined>>({
  value: undefined,
  label: '',
})

/**
 * choiceのfactory method
 * optionをさばく
 * @param {ChoiceFactoryOption} { noBlank = false }
 * @returns
 */
export const choiceFactory = ({ noBlank = false }: ChoiceFactoryOption) => {
  const create = <T extends ChoiceValue = number>(
    choices: Choice<T>[]
  ): Choice<T>[] => {
    if (noBlank) {
      return [...choices]
    }

    return [{ ...BLANK_CHOICE } as Choice<T>, ...choices]
  }

  return create
}
