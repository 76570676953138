import { ErrorKind } from '@/constants/kinds/ErrorKind'
import { AxiosMethod } from '@/features/states/apiClient/interceptor/ResponseInterceptor/responseInterceptorActions'
import { useOpenSnackbar } from '@/features/states/snackbar/snackbarActions'
import {
  Error400ResponseV1,
  Error500ResponseV1,
} from '@/lib/api-client/aspida/@types'
import { AxiosError } from 'axios'
import { useCallback } from 'react'
import { useDecrementRequestCount } from '../interceptorActions'
import { DeleteResponseErrorInterceptor } from './impl/DeleteResponseErrorInterceptor'
import { GetResponseErrorInterceptor } from './impl/GetResponseErrorInterceptor'
import { PatchResponseErrorInterceptor } from './impl/PatchResponseErrorInterceptor'
import { PostResponseErrorInterceptor } from './impl/PostResponseErrorInterceptor'
import { PutResponseErrorInterceptor } from './impl/PutResponseErrorInterceptor'
import { useErrorKindHandler } from './useErrorKindHandler'
import { useRouter } from 'next/router'
import { useDynamicPath } from '@/features/states/url/dynamicPathState/queries'

/** API定義として持っているエラーの型 */
export type InternalErrorResponse = AxiosError<
  Error400ResponseV1 | Error500ResponseV1
>

export type ResponseInterceptorFactoryOption = {
  error: InternalErrorResponse
}

export type ResponseErrorInterceptorImpl = (
  response: InternalErrorResponse
) => void

/** InterceptorTypeと実装群のマッピングオブジェクト */
const RESPONSE_ERROR_INTERCEPTOR_METHOD_MAP: {
  [Method in AxiosMethod]: ResponseErrorInterceptorImpl
} = Object.freeze({
  get: GetResponseErrorInterceptor,
  post: PostResponseErrorInterceptor,
  put: PutResponseErrorInterceptor,
  patch: PatchResponseErrorInterceptor,
  delete: DeleteResponseErrorInterceptor,
})

/** AxiosErrorとInterceptorTypeをマッピングする */
const getAxiosMethodFromError = (
  error: ResponseInterceptorFactoryOption['error']
) => {
  const method = error.config.method
  if (!method) {
    console.error(`interceptor type: ${method} is not implemented `)
  }
  return method?.toLowerCase() as AxiosMethod
}

export const isUnderMaintenance = (error: InternalErrorResponse) => {
  return error.response?.data.error_kind === ErrorKind.UnderMaintenance.id
}

/**
 * Response Error Interceptorの共通処理をマージした、
 * HTTPメソッドごとのハンドラ実装を返すファクトリ
 */
export const useResponseErrorInterceptorFactory = () => {
  const decrementRequestCount = useDecrementRequestCount()
  const openSnackbar = useOpenSnackbar()
  const handleErrorKind = useErrorKindHandler()
  const router = useRouter()
  const dynamicPath = useDynamicPath()

  const factory = useCallback(
    ({ error }: ResponseInterceptorFactoryOption) => {
      const method = getAxiosMethodFromError(error)
      const intercept = RESPONSE_ERROR_INTERCEPTOR_METHOD_MAP[method]
      const isRedirect =
        router.pathname !== '/[tenantName]/maintenance' &&
        isUnderMaintenance(error)

      /** 共通処理 */
      const baseIntercept = () => {
        if (isRedirect) {
          router.push(
            {
              pathname: `/${dynamicPath.tenantName}/maintenance`,
              query: router.query,
            },
            `/${dynamicPath.tenantName}/maintenance`
          )
          return
        }

        //GET以外のリクエストは画面ロックしている
        const usedBackDrop = method !== 'get'

        if (usedBackDrop) {
          decrementRequestCount()
        }

        try {
          JSON.parse(JSON.stringify(error.response?.data))
        } catch (error) {
          openSnackbar({
            message:
              '予期せぬ問題が発生しました。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
            severity: 'error',
          })
          return
        }

        if (!error.response) {
          openSnackbar({ message: 'ネットワークエラー', severity: 'error' })
          return
        }

        const {
          response: {
            data: { error_kind, message },
          },
        } = error

        handleErrorKind({ errorKind: error_kind as ErrorKind.ids })

        const errorMessage = `${error_kind} : ${message} `
        openSnackbar({ message: errorMessage, severity: 'error' })
      }

      /** HTTPメソッドごとの実装  */
      const doIntercept = () => {
        baseIntercept()

        intercept(error)
      }

      return doIntercept
    },
    [decrementRequestCount, handleErrorKind, openSnackbar, router, dynamicPath]
  )

  return factory
}
