import { useRef, useEffect, EffectCallback } from 'react'

/**
 * React V18に向けた初回しか呼び出されないuseEffect
 * - https://github.com/reactwg/react-18/discussions/18
 *
 * react-useでは未対応
 * - https://github.com/streamich/react-use/blob/master/src/useEffectOnce.ts
 * */
export const useEffectOnce = (effect: EffectCallback) => {
  const didRun = useRef(false)

  useEffect(() => {
    if (!didRun.current) {
      didRun.current = true
      return effect()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
