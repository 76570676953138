import { decodeFirebaseJwt } from '@/lib/firebase'
import { isEmpty } from '@/lib/utility'
import axios, { AxiosInstance } from 'axios'
import { DateTime } from 'luxon'

export const http = axios.create({})

const createJwtStore = (axiosInstance: AxiosInstance) => {
  let cache = ''

  return {
    get: () => {
      return cache
    },
    set: (jwt: string) => {
      cache = jwt

      if (axiosInstance.defaults.headers === undefined) {
        return
      }

      if (jwt === '') {
        axiosInstance.defaults.headers.Authorization = ''
      } else {
        axiosInstance.defaults.headers.Authorization = `Bearer ${jwt}`
      }
    },
    /** JWTの有効期限を返す */
    getExpiresAt: () => {
      try {
        const jwt = cache
        const { exp } = decodeFirebaseJwt(jwt)

        if (isEmpty(exp)) {
          return DateTime.invalid('exp is Empty')
        }

        // FirebaseのJWTに含まれるtimestampは秒単位
        const datetime = DateTime.fromSeconds(exp)
        return datetime
      } catch (error) {
        return DateTime.invalid(JSON.stringify(error))
      }
    },
  }
}

/** JWTの状態コンテナ */
export const JWT = createJwtStore(http)
