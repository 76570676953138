/* eslint-disable react-hooks/rules-of-hooks */
import { AxiosRequestConfig } from 'axios'
import { useCallback } from 'react'
import { useIncrementRequestCount } from '../interceptorActions'
import { DeleteRequestInterceptor } from './impl/DeleteRequestInterceptor'
import { getRequestInterceptor } from './impl/GetRequestInterceptor'
import { PatchRequestInterceptor } from './impl/PatchRequestInterceptor'
import { PostRequestInterceptor } from './impl/PostRequestInterceptor'

export type RequestInterceptorFactoryOption = {
  request: AxiosRequestConfig
}

type InterceptorType = 'get' | 'post' | 'put' | 'patch' | 'delete'

export type RequestInterceptorImpl = (request: AxiosRequestConfig) => void

/** InterceptorTypeと実装群のマッピングオブジェクト */
const REQUEST_INTERCEPTOR_METHOD_MAP: {
  [Method in InterceptorType]: RequestInterceptorImpl
} = Object.freeze({
  get: getRequestInterceptor,
  post: PostRequestInterceptor,
  put: PostRequestInterceptor,
  patch: PatchRequestInterceptor,
  delete: DeleteRequestInterceptor,
})

/** axiosのrequest.methodとInterceptorTypeをマッピングする */
const getInterceptorType = (
  request: RequestInterceptorFactoryOption['request']
) => {
  const method = request.method
  if (!method) {
    console.error(`interceptor type: ${method} is not implemented `)
  }
  return method?.toLowerCase() as InterceptorType
}

/**
 * Request Interceptorの共通処理をマージした、
 * HTTPメソッドごとのハンドラ実装を返すファクトリ
 */
export const useRequestInterceptorFactory = () => {
  const incrementRequestCount = useIncrementRequestCount()

  const factory = useCallback(
    ({ request }: RequestInterceptorFactoryOption) => {
      const interceptorType = getInterceptorType(request)
      const intercept = REQUEST_INTERCEPTOR_METHOD_MAP[interceptorType]

      /** 共通処理 */
      const baseIntercept = () => {
        //GET以外のリクエストは画面ロックする
        const useBackDrop = interceptorType !== 'get'

        if (useBackDrop) {
          incrementRequestCount()
        }
      }

      /** HTTPメソッドごとの実装  */
      const doIntercept = () => {
        baseIntercept()

        intercept(request)
      }

      return doIntercept
    },
    [incrementRequestCount]
  )

  return factory
}
