import React, { createContext } from 'react'
import { MeModel } from '@/features/domains/tenants/_tenant_name@string/staffs/me/meModel'
import { useCanCallPrivateApi } from '@/features/states/auth/authQueries'
import Authorized from '@/components/middlewares/Authorized'
import { tenantApi } from '@/features/states/apiClient/apiClientState'
import { selectorFamily, useRecoilValueLoadable } from 'recoil'

const StaffRoleEntity = {
  id: 0,
  tenant_id: 0,
  name: 'string',
  order_number: 0,
  in_use: true,
  is_admin: false,
  version: 0,
  created_at: '2021-07-26T07:11:17.606Z',
  updated_at: '2021-07-26T07:11:17.606Z',
  not_editable: false,
}

const ShopEntity = {
  id: 0,
  tenant_id: 0,
  name: 'string',
  order_number: 0,
  shop_kind: 0,
  zip: 'string',
  prefecture: 'string',
  city: 'string',
  address: 'string',
  version: 0,
  created_at: '2021-07-26T07:11:17.606Z',
  updated_at: '2021-07-26T07:11:17.606Z',
  building: 'string',
  tel: 'string',
  shop_url: 'string',
  director_name: 'string',
  google_maps_url: 'string',
  is_closed: false,
}

const initState = new MeModel(
  {
    id: 0,
    system_id: 'string',
    first_name: 'string',
    last_name: 'string',
    full_name: 'string',
    email: 'string',
    in_use: true,
    joined_at: '2021-07-26T07:11:17.606Z',
    created_at: '2021-07-26T07:11:17.606Z',
    updated_at: '2021-07-26T07:11:17.606Z',
    sub: 'string',
    tenant_id: 0,
    is_system: true,
    version: 0,
    staff_role: StaffRoleEntity,
    primary_shop: ShopEntity,
    secondary_shops: [ShopEntity],
  },
  []
)

export const globalUserState = selectorFamily({
  key: 'globalUserProvider/me',
  get:
    ({ canCallApi }: { canCallApi: boolean }) =>
    async ({ get }) => {
      if (!canCallApi) {
        return initState
      }

      const api = get(tenantApi)

      const meResponse = await api.staffs.me.$get()
      const roleResponse = await api.staff_roles.$get({
        query: { show_unused: false },
      })

      return new MeModel(meResponse, roleResponse)
    },
})

type GlobalUserProviderProps = {
  children: React.ReactNode
}

export const GlobalUserContext = createContext<MeModel>(initState)

export default function GlobalUserProvider({
  children,
}: GlobalUserProviderProps) {
  const { isAuthStateSubscribed, isPublicPath } = useCanCallPrivateApi()
  const canCallApi = !isPublicPath && isAuthStateSubscribed

  const me = useRecoilValueLoadable(globalUserState({ canCallApi })).getValue()

  return (
    <Authorized>
      <GlobalUserContext.Provider value={me}>
        {children}
      </GlobalUserContext.Provider>
    </Authorized>
  )
}
