/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react-hooks/rules-of-hooks */

import { atomRewind } from '@/lib/recoil'
import { uuidV4 } from '@/lib/uuid'
import {
  atom,
  selector,
  Loadable,
  ReadOnlySelectorOptions,
  RecoilState,
  useRecoilCallback,
  useRecoilValueLoadable,
  RecoilValue,
  DefaultValue,
  RecoilLoadable,
} from 'recoil'

type GetAspidaMethod = {
  $get: (...option: any) => Promise<any>
}

type GetParams<M extends GetAspidaMethod> = NonNullable<
  Parameters<M['$get']>[0]
>

type GetReturn<M extends GetAspidaMethod> = Awaited<ReturnType<M['$get']>>

type AtomWithAspidaAction<M extends GetAspidaMethod = GetAspidaMethod> =
  | {
      type: 'reload'
      payload: GetParams<M>['query'] | undefined
    }
  | {
      type: 'refetch'
    }
  | {
      type: 'prefetch'
    }

type GetResult<M extends GetAspidaMethod, Response> = {
  queryState: RecoilState<Response>
  optionState: RecoilValue<GetParams<M>>
  dispatchState: RecoilState<AtomWithAspidaAction<M>>
  useQuery: () => Loadable<Response>
  useQueryDispatch: () => (action: AtomWithAspidaAction<M>) => void
}

type AtomWithAspidaReturn<M extends GetAspidaMethod, Response> = GetResult<
  M,
  Response
>

/**
 * aspidaのendpointを受け取り、データフェッチのHooksと参照先のRecoilValueを返す
 * @deprecated V2の利用を推奨
 * */
export const deprecated_atomWithAspida = <
  M extends GetAspidaMethod,
  Response = GetReturn<M>
>(args: {
  endpoint: ReadOnlySelectorOptions<M>['get']
  option: ReadOnlySelectorOptions<GetParams<M>>['get']
  enabled?: (
    opts: Parameters<ReadOnlySelectorOptions<boolean>['get']>[0] & {
      currentQueryParams: GetParams<M>['query']
    }
  ) => boolean
  key?: string
}): AtomWithAspidaReturn<M, Response> => {
  const { endpoint, option, enabled, key = uuidV4() } = args

  const delegatedEndpoint = selector({
    key: `${key}/delegatedEndpoint`,
    get: (opts) => endpoint(opts),
  })

  const delegatedOption = selector({
    key: `${key}/delegatedOption`,
    get: (opts) => option(opts),
  })

  const delegatedEnabled = selector({
    key: `${key}/delegatedEnabled`,
    get: (opts) => {
      if (enabled === undefined) {
        return true
      }

      return enabled({
        ...opts,
        currentQueryParams: opts.get(optionState)?.query,
      })
    },
  })

  const optionState = atom<GetParams<M>>({
    key: `${key}/optionState`,
    default: selector({
      key: `${key}/optionState/default`,
      get: ({ get }) => {
        const opt = get(delegatedOption)

        return opt
      },
    }),
  })

  const queryState = atomRewind({
    key: `${key}/queryState`,
    default: (opts) => {
      const { get } = opts

      const isEnabled = get(delegatedEnabled)

      if (!isEnabled) {
        return RecoilLoadable.loading() as unknown as Promise<any>
      }

      const api = get(delegatedEndpoint)
      const option = get(optionState)

      return api.$get(option)
    },
  }) as RecoilState<Response>

  const dispatchState = selector<AtomWithAspidaAction<M>>({
    key: `${key}/dispatchState`,
    get: () => null as any,
    set: ({ set, reset }, action) => {
      if (action instanceof DefaultValue) {
        reset(optionState)
        return
      }

      switch (action.type) {
        case 'reload': {
          set(optionState, (current) =>
            action.payload === undefined
              ? current
              : {
                  ...current,
                  query: action.payload,
                }
          )
          reset(queryState)

          break
        }

        case 'refetch': {
          reset(queryState)

          break
        }
      }
    },
  })

  const useQuery = () => {
    return useRecoilValueLoadable(queryState)
  }

  const useQueryDispatch = () => {
    const dispatch = useRecoilCallback(
      ({ set, snapshot }) =>
        (action: AtomWithAspidaAction) => {
          if (action.type === 'prefetch') {
            snapshot.getLoadable(queryState)
            return
          }

          set(dispatchState, action)
        },
      []
    )

    return dispatch
  }

  const readOnlyOptionState = selector({
    key: `${key}/readOnlyOptionState`,
    get: ({ get }) => get(optionState),
  })

  return {
    queryState,
    optionState: readOnlyOptionState,
    dispatchState,
    useQuery,
    useQueryDispatch,
  }
}
