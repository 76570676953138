import api from '@/lib/api-client/aspida/$api'
import axiosClient from '@aspida/axios'
import { dynamicPathState } from '@/features/states/url/dynamicPathState/dynamicPathState'
import { selector, selectorFamily, useResetRecoilState } from 'recoil'
import { globalShopIdState } from '../globalShop/globalShopState'
import { http } from '@/lib/api-client/http'
import { atomRewind } from '@/lib/recoil'
import { useEffectOnce } from '@/lib/hooks/useEffectOnce'
import { useCallback, useRef } from 'react'
import {
  AXIOS_MUTATION_METHODS,
  getAxiosMethod,
} from '@/features/states/apiClient/interceptor/ResponseInterceptor/responseInterceptorActions'
import { nextTick } from '@/lib/nextTick'

const getTenantName = () => {
  if (typeof window === 'undefined') {
    return ''
  }

  const tenantName = location.pathname.split('/')[1]

  return tenantName || ''
}

export const aspida = api(
  axiosClient(http, {
    baseURL: process.env.NEXT_PUBLIC_API_BASE_PATH,
  })
)

export const Api = {
  tenant: {
    v1: (() => {
      const tenantName = getTenantName()
      return aspida.api.v1.tenants._tenant_name(tenantName)
    })(),
    v2: (() => {
      const tenantName = getTenantName()
      return aspida.api.v2.tenants._tenant_name(tenantName)
    })(),
  },
}

export type GenericApiType = typeof aspida.api.v1
export type GenericApiTypeV2 = typeof aspida.api.v2

export type TenantApiType = ReturnType<
  GenericApiType['tenants']['_tenant_name']
>

export type CustomerApiType = ReturnType<
  TenantApiType['customers']['_customer_id']
>

export type ShopApiType = ReturnType<TenantApiType['shops']['_shop_id']>

export type BankApiType = ReturnType<GenericApiType['banks']['_bank_code']>

export type TenantApiTypeV2 = ReturnType<
  GenericApiTypeV2['tenants']['_tenant_name']
>

export type CustomerApiTypeV2 = ReturnType<
  TenantApiTypeV2['customers']['_customer_id']
>

export type ShopApiTypeV2 = ReturnType<TenantApiTypeV2['shops']['_shop_id']>
export const aspidaClientWithConfig = api(
  axiosClient(http, {
    baseURL: process.env.NEXT_PUBLIC_API_BASE_PATH,
  })
)

export const genericApi = atomRewind({
  key: 'genericApi',
  default: async ({ get }) => {
    return aspida.api.v1
  },
})

export const tenantApi = atomRewind({
  key: 'tenantApi',
  default: async ({ get }) => {
    const api = get(genericApi)
    const { tenantName } = get(dynamicPathState)
    return api.tenants._tenant_name(tenantName)
  },
})

export const currentCustomerApi = selector({
  key: 'customerApi',
  get: ({ get }) => {
    const api = get(tenantApi)
    const { customerId } = get(dynamicPathState)
    const customerIdNumber = customerId ? Number(customerId) : 0

    return api.customers._customer_id(customerIdNumber)
  },
})

export const customerApi = selectorFamily<CustomerApiType, number>({
  key: 'customerApi',
  get:
    (customerId) =>
    ({ get }) => {
      const api = get(tenantApi)

      return api.customers._customer_id(customerId)
    },
})

export const currentShopApi = selector({
  key: 'currentShopApi',
  get: ({ get }) => {
    const api = get(tenantApi)
    const shopId = get(globalShopIdState)

    return api.shops._shop_id(shopId)
  },
})

export const shopApi = selectorFamily<ShopApiType, number>({
  key: 'shopApi',
  get:
    (shopId) =>
    ({ get }) => {
      const api = get(tenantApi)

      return api.shops._shop_id(shopId)
    },
})

export const bankApi = selectorFamily<BankApiType, string>({
  key: 'bankApi',
  get:
    (bankCode: string) =>
    ({ get }) => {
      const api = get(genericApi)

      return api.banks._bank_code(bankCode)
    },
})

//V2系
export const genericApiV2 = atomRewind({
  key: 'genericApiV2',
  default: async ({ get }) => {
    return aspida.api.v2
  },
})

export const tenantApiV2 = atomRewind({
  key: 'tenantApiV2',
  default: async ({ get }) => {
    const api = get(genericApiV2)
    const { tenantName } = get(dynamicPathState)
    return api.tenants._tenant_name(tenantName)
  },
})

export const customerApiV2 = selectorFamily<CustomerApiTypeV2, number>({
  key: 'customerApi',
  get:
    (customerId) =>
    ({ get }) => {
      const api = get(tenantApiV2)

      return api.customers._customer_id(customerId)
    },
})

export const currentCustomerApiV2 = selector({
  key: 'currentCustomerApiV2',
  get: ({ get }) => {
    const api = get(tenantApiV2)
    const { customerId } = get(dynamicPathState)
    const customerIdNumber = customerId ? Number(customerId) : 0

    return api.customers._customer_id(customerIdNumber)
  },
})

export const currentShopApiV2 = selector({
  key: 'currentShopApiV2',
  get: ({ get }) => {
    const api = get(tenantApiV2)
    const shopId = get(globalShopIdState)

    return api.shops._shop_id(shopId)
  },
})

export const shopApiV2 = selectorFamily<ShopApiTypeV2, number>({
  key: 'shopApi',
  get:
    (shopId) =>
    ({ get }) => {
      const api = get(tenantApiV2)

      return api.shops._shop_id(shopId)
    },
})

/** APIキャッシュの管理フック */
export const useApiCacheManager = (options?: {
  /**
   * リソースを変化させた場合は即座にキャッシュリセットする
   * UI上シームレスに遷移可能な際や、関連エンティティが多い際のケースを想定
   * */
  immediate?: boolean
}) => {
  const { immediate = false } = options || {}

  const shouldResetCache = useRef(false)

  const resetGenericApi = useResetRecoilState(genericApi)
  const resetGenericApiV2 = useResetRecoilState(genericApiV2)
  const resetTenantApi = useResetRecoilState(tenantApi)
  const resetTenantApiV2 = useResetRecoilState(tenantApiV2)

  // APIキャッシュリセット
  const resetApi = useCallback(() => {
    // callstackを分けて重複実行を抑制する
    nextTick(() => {
      resetGenericApi()
      resetGenericApiV2()
      resetTenantApi()
      resetTenantApiV2()
    })
  }, [resetGenericApi, resetGenericApiV2, resetTenantApi, resetTenantApiV2])

  useEffectOnce(() => {
    // サーバのリソースを変化させた場合はリセットフラグを立てる
    const interceptorId = http.interceptors.response.use((response) => {
      const method = getAxiosMethod(response)
      const isMutated = AXIOS_MUTATION_METHODS.includes(method)

      if (isMutated) {
        shouldResetCache.current = true

        if (immediate) {
          resetApi()
        }
      }

      return response
    })

    return () => {
      http.interceptors.response.eject(interceptorId)

      if (shouldResetCache.current) {
        resetApi()
      }
    }
  })
}
