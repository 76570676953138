import { tenantApi } from '@/features/states/apiClient/apiClientState'
import type * as Types from '@/lib/api-client/aspida/@types'
import { atomFamily, DefaultValue, selectorFamily } from 'recoil'
import { GetSigninInfoModel } from './getSigninInfoModel'

export type GetSigninInfoFamily = { key?: string }
export type GetSigninInfoModelFamily = GetSigninInfoFamily & {
  response?: Types.SigninInfoResponseV1
}

const getSigninInfoQueryRefresh = atomFamily<number, GetSigninInfoFamily>({
  key: 'getSigninInfoQueryRefresh',
  default: 0,
})

export const getSigninInfoQuery = selectorFamily<
  Types.SigninInfoResponseV1,
  GetSigninInfoFamily
>({
  key: 'getSigninInfoQuery',
  get:
    ({ key }) =>
    ({ get }) => {
      get(getSigninInfoQueryRefresh({ key }))
      const api = get(tenantApi)

      return api.getSigninInfo.$get({
        config: {
          headers: {},
        },
      })
    },
  set:
    (family) =>
    ({ set }, value) => {
      if (value instanceof DefaultValue) {
        set(getSigninInfoQueryRefresh(family), (v) => v + 1)
      }
    },
})

export const getSigninInfoQueryWithModel = selectorFamily<
  GetSigninInfoModel,
  GetSigninInfoModelFamily
>({
  key: 'getSigninInfoQueryWithModel',
  get:
    ({ key, response }) =>
    ({ get }) => {
      return new GetSigninInfoModel(
        response || get(getSigninInfoQuery({ key }))
      )
    },
})
