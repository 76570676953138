/* eslint-disable react-hooks/rules-of-hooks */
import { useGetSigninInfoModel } from '@/features/domains/tenants/_tenant_name@string/getSigninInfo/getSigninInfoQueries'
import { useSubscribeAuth } from '@/features/states/auth/authActions'
import { useCanCallPrivateApi } from '@/features/states/auth/authQueries'
import { isAuthStateLoaded } from '@/features/states/auth/authState'
import { useEffectOnce } from '@/lib/hooks/useEffectOnce'
import { useSetRecoilState } from 'recoil'
import AppBackDrop from '../organisms/AppBackDrop/AppBackDrop'

type AuthorizedProps = {
  children: React.ReactNode
}

/**
 * firebaseへの認証が必要なページを開く際のミドルウェア
 * - firebase authのsubscribe
 * - JWTの保存
 * - 認証処理が終わるまでの画面ロック
 * */
function Authorized({ children }: AuthorizedProps) {
  const signinInfo = useGetSigninInfoModel({})
  const { isPublicPath } = useCanCallPrivateApi()

  //認証状態をsubscribeする
  const [isAuthStateLoaded, onAuthFailed] = useSubscribeAuth({
    sininInfoModel: signinInfo.getValue(),
  })

  if (isPublicPath) {
    return <>{children}</>
  }

  if (!isAuthStateLoaded) {
    return <AppBackDrop forceOpen />
  }

  if (signinInfo.getValue().isDirtyTenantId()) {
    onAuthFailed()
  }

  return <>{children}</>
}

const ignoreAuth = process.env.NEXT_PUBLIC_IGNORE_FIREBASE === 'true'

const proxy = ({ children }: AuthorizedProps) => {
  const setIsAuthStateSubscribed = useSetRecoilState(isAuthStateLoaded)

  useEffectOnce(() => {
    ignoreAuth && setIsAuthStateSubscribed(true)
  })

  return ignoreAuth ? <>{children}</> : <Authorized>{children}</Authorized>
}

export default proxy
