/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect } from 'react'
import { useEffectOnce } from '@/lib/hooks/useEffectOnce'
import { createMemoizedSelector } from '@/lib/recoil/shorthands/createMemoizedSelector'
import { uuidV4 } from '@/lib/uuid'
import { useState } from 'react'
import {
  FieldValues,
  useForm as useReactHookForm,
  UseFormProps,
  UseFormReturn,
  useWatch,
} from 'react-hook-form'
import {
  atom,
  ReadOnlySelectorOptions,
  selector,
  useRecoilCallback,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil'
import ComponentSuspence from '@/components/molecules/ComponentSuspence/ComponentSuspence'
import cloneDeep from 'lodash.clonedeep'

type UseFormPropsState<T extends FieldValues> = ReadOnlySelectorOptions<
  UseFormProps<T>
>['get']

type BuildRecoilHookFormArg<T extends FieldValues = FieldValues> = {
  useFormPropsState?: UseFormPropsState<T>
}

type FormProviderProps<T extends FieldValues> = {
  useFormProps?: UseFormProps<T>
}

const createRecoilHookFormNodes = <T extends FieldValues>(
  arg: BuildRecoilHookFormArg<T> = {}
) => {
  const { useFormPropsState } = arg

  // 引数でうけとるformProps
  const formPropsState = selector({
    key: uuidV4(),
    // eslint-disable-next-line react-hooks/rules-of-hooks
    get: (opts) => useFormPropsState?.(opts),
  })

  const formAtom = atom<T | undefined>({
    key: uuidV4(),
    default: undefined,
  })

  const formState = selector<T>({
    key: uuidV4(),
    get: ({ get }) => {
      const form = get(formAtom)

      if (!form) {
        throw new Error('formAtom is not initialized')
      }

      return form
    },
    set: ({ set }, newValue) => {
      set(formAtom, newValue)
    },
  })

  const formProxy = createMemoizedSelector(formState)

  type FormMethods = Omit<
    UseFormReturn<T>,
    'control' | 'register' | 'unregister' | 'watch'
  >

  const formMethodsAtom = atom<FormMethods | null>({
    key: uuidV4(),
    default: null,
  })

  const formMethodsState = selector<FormMethods>({
    key: uuidV4(),
    get: ({ get }) => {
      const methods = get(formMethodsAtom)

      if (!methods) {
        throw new Error('formMethodsAtom is not initialized')
      }

      return methods
    },
    set: ({ set }, newValue) => {
      set(formMethodsAtom, newValue)
    },
  })

  return {
    formPropsState,
    formAtom,
    formState,
    formProxy,
    formMethodsAtom,
    formMethodsState,
  } as const
}

/**
 * React Hook Form制御のボイラープレートフックを生成する
 * */
export const buildRecoilHookForm = <T extends FieldValues>(
  arg: BuildRecoilHookFormArg<T> = {}
) => {
  const {
    formPropsState,
    formAtom,
    formState,
    formProxy,
    formMethodsAtom,
    formMethodsState,
  } = createRecoilHookFormNodes(arg)

  const Context = React.createContext<UseFormReturn<T> | null>(null)

  const useCleanupForm = () =>
    useRecoilCallback(
      ({ reset, refresh }) =>
        () => {
          reset(formAtom)
          reset(formState)
          reset(formMethodsAtom)
          reset(formMethodsState)
          refresh(formState)
          refresh(formMethodsState)
        },
      []
    )

  /**
   * - RHFのformを初期化、Providerで提供する
   * - RHFのformの値をRecoil Stateと同期させる
   * - アンマウント時に状態をリセットする
   */
  const FormProvider: React.FC<FormProviderProps<T>> = ({
    children,
    useFormProps,
  }) => {
    const [isInitialized, setIsInitialized] = useState(false)
    const [isInitializedMethods, setIsInitializedMethods] = useState(false)

    const cleanup = useCleanupForm()

    useEffectOnce(() => {
      return () => {
        cleanup()
      }
    })

    // RHFをuseFormで宣言 & 戻り値をProviderで提供する
    const Provider: React.FC<FormProviderProps<T>> = ({
      children,
      useFormProps,
    }) => {
      const formProps = useRecoilValueLoadable(formPropsState).getValue()
      const form = useReactHookForm<T>({ ...formProps, ...useFormProps })

      // フォームの変化を検知してRecoil Nodeと同期する
      const FormSync: React.VFC<{ form: UseFormReturn<T> }> = ({ form }) => {
        const { control } = form

        const values = useWatch({ control })

        const setFormState = useSetRecoilState(formState)
        const setFormMethods = useSetRecoilState(formMethodsState)

        useEffect(() => {
          setFormState(cloneDeep(values as T))

          if (!isInitialized) {
            setIsInitialized(true)
          }

          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [values])

        useEffect(() => {
          const { control, register, unregister, watch, ...methods } = form

          setFormMethods(cloneDeep(methods))

          if (!isInitializedMethods) {
            setIsInitializedMethods(true)
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [form])

        return null
      }

      return (
        <>
          <FormSync form={form} />
          <Context.Provider value={form}>{children}</Context.Provider>
        </>
      )
    }

    return (
      <ComponentSuspence>
        <Provider useFormProps={useFormProps}>
          {/* 子要素はフォーム初期化後にレンダリング */}
          {isInitialized && isInitializedMethods ? children : null}
        </Provider>
      </ComponentSuspence>
    )
  }

  const useForm = () => {
    const context = React.useContext(Context)

    if (!context) {
      throw new Error('require wrapped by FormProvider ')
    }

    return context
  }

  return {
    FormProvider,
    nodes: { formState, formProxy, formMethods: formMethodsState },
    actions: { useCleanupForm },
    queries: { useForm },
  } as const
}
