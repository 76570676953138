import { FIREBASE_TENANT_ID_KEY } from '@/constants/localStorage'
import { GetSigninInfoModel } from '@/features/domains/tenants/_tenant_name@string/getSigninInfo/getSigninInfoModel'
import { tenantApi } from '@/features/states/apiClient/apiClientState'
import { JWT } from '@/lib/api-client/http'
import firebase from '@/lib/firebase'
import { useEffectOnce } from '@/lib/hooks/useEffectOnce'
import { nextTick } from '@/lib/nextTick'
import { isEmpty } from '@/lib/utility'
import { DateTime } from 'luxon'
import { useRouter } from 'next/router'
import { useRecoilCallback, useRecoilState } from 'recoil'
import { useTenantRootPath } from '../url/dynamicPathState/queries'
import { isAuthStateLoaded } from './authState'

type Props = {
  sininInfoModel: GetSigninInfoModel
}

const WITHOUT_SESSION_PATHNAMES = ['/[tenantName]/password']

export const useSubscribeAuth = ({ sininInfoModel }: Props) => {
  const [isAuthStateSubscribed, setIsAuthStateSubscribed] =
    useRecoilState(isAuthStateLoaded)

  const onAuthFailed = useLogoutAction()

  useEffectOnce(() => {
    if (isAuthStateSubscribed) {
      return
    }

    //テナントIDを設定する
    const { firebase_tenant_id } = sininInfoModel.getResponse()
    firebase.auth().tenantId = firebase_tenant_id
    localStorage.setItem(FIREBASE_TENANT_ID_KEY, firebase_tenant_id)

    const unsubscribe = firebase.auth().onIdTokenChanged(async (user) => {
      if (user && user.tenantId) {
        const token = await user.getIdToken()

        JWT.set(token)

        setIsAuthStateSubscribed(true)
      } else {
        JWT.set('')
        setIsAuthStateSubscribed(false)
        onAuthFailed()
      }
    })

    return () => {
      JWT.set('')
      setIsAuthStateSubscribed(false)
      unsubscribe()
    }
  })

  return [isAuthStateSubscribed, onAuthFailed] as const
}

export const useLogoutAction = () => {
  const router = useRouter()
  const tenantRootPath = useTenantRootPath()

  const onLogout = useRecoilCallback(
    ({ reset }) =>
      () => {
        //ログアウト
        firebase
          .auth()
          .signOut()
          .then(async () => {
            //ログイン画面へ
            if (!WITHOUT_SESSION_PATHNAMES.includes(router.pathname)) {
              await router.push(tenantRootPath.login.$url())
            }

            nextTick(() => {
              //JWTをリセット
              JWT.set('')

              // APIキャッシュをリセット
              reset(tenantApi)
            })
          })
      },
    [router, tenantRootPath.login]
  )

  return onLogout
}

/** JWTを再取得 */
export const useRefreshJwt = () => {
  const refresh = useRecoilCallback(({ set }) => async () => {
    if (process.env.NEXT_PUBLIC_USE_FIREBASE_EMULATOR == 'true') {
      return
    }

    const { currentUser } = firebase.auth()

    if (isEmpty(currentUser)) {
      JWT.set('')

      return
    }

    //force refresh
    const token = await currentUser.getIdToken(true)

    JWT.set(token)
  })

  return refresh
}

/**
 * JWTを永続化
 * ブラウザのイベントを購読するため、実行の制御はコンポーネント側で実施する
 * @see AppJwtPersister
 * */
export const usePersistJwt = (arg: { tick: number }) => {
  const { tick } = arg

  const refreshJwt = useRefreshJwt()

  const handler = useRecoilCallback(
    ({ snapshot }) =>
      async (arg: { currentDateTime: DateTime }) => {
        const { currentDateTime } = arg

        const jwtExpiresAt = JWT.getExpiresAt()

        if ([currentDateTime, jwtExpiresAt].some((d) => !d.isValid)) {
          return
        }

        // 厳密な比較のためにmillisecondsのUNIX Timestampにする
        const currentTimestamp = currentDateTime.toMillis()
        const expiresAt = jwtExpiresAt.toMillis()

        // 次のTICKで有効期限を超えてしまう場合はJWTを更新する
        if (currentTimestamp + tick >= expiresAt) {
          refreshJwt()
        }
      },
    [refreshJwt, tick]
  )

  return handler
}
