/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react'
import {
  atom,
  ReadOnlySelectorOptions,
  selector,
  useRecoilCallback,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil'
import {
  FieldValues,
  useForm as useReactHookForm,
  UseFormProps,
  UseFormReturn,
  useWatch,
} from 'react-hook-form'
import cloneDeep from 'lodash.clonedeep'
import { useEffectOnce } from '@/lib/hooks/useEffectOnce'
import { createMemoizedSelector } from '@/lib/recoil'
import { nanoid } from '@/lib/nanoid'
import { nextTick } from '@/lib/nextTick'
import ComponentSuspence from '@/components/molecules/ComponentSuspence/ComponentSuspence'

type UseFormPropsSelector<T extends FieldValues> = ReadOnlySelectorOptions<
  UseFormProps<T>
>['get']

type BuildRecoilHookFormArg<T extends FieldValues> = {
  key?: string
  formProps?: UseFormPropsSelector<T>
}

type FormProviderProps<T extends FieldValues> = {
  formProps?: UseFormProps<T>
}

const createNodes = <T extends FieldValues>(
  arg: BuildRecoilHookFormArg<T> = {}
) => {
  const { formProps, key = nanoid() } = arg

  const delegatedUseFormProps = selector({
    key: `${key}/delegatedUseFormProps`,
    get: (opts) => formProps?.(opts),
  })

  const baseFormValuesAtom = atom<T | undefined>({
    key: `${key}/baseFormValuesAtom`,
    default: undefined,
  })

  const formValuesState = selector<T>({
    key: `${key}/formValuesState`,
    get: ({ get }) => {
      const form = get(baseFormValuesAtom)

      if (!form) {
        throw new Error(`${baseFormValuesAtom.key} is not initialized`)
      }

      return form
    },
    set: ({ set }, newValue) => {
      set(baseFormValuesAtom, newValue)
    },
  })

  const field = createMemoizedSelector(formValuesState)

  type FormMethods = Omit<
    UseFormReturn<T>,
    'control' | 'register' | 'unregister' | 'watch'
  >

  const baseFormMethodsAtom = atom<FormMethods | null>({
    key: `${key}/baseformMethodsAtom`,
    default: null,
  })

  const formMethodsState = selector<FormMethods>({
    key: `${key}/formMethodsState`,
    get: ({ get }) => {
      const methods = get(baseFormMethodsAtom)

      if (!methods) {
        throw new Error(`${baseFormMethodsAtom.key} is not initialized`)
      }

      return methods
    },
    set: ({ set }, newValue) => {
      set(baseFormMethodsAtom, newValue)
    },
  })

  return {
    delegatedUseFormProps,
    baseFormValuesAtom,
    formValuesState,
    field,
    baseFormMethodsAtom,
    formMethodsState,
  } as const
}

/**
 * Recoil and react-hook-form integration
 * */
export const atomWithReactHookForm = <T extends FieldValues>(
  arg: BuildRecoilHookFormArg<T> = {}
) => {
  const {
    delegatedUseFormProps,
    baseFormValuesAtom,
    formValuesState,
    field,
    baseFormMethodsAtom,
    formMethodsState,
  } = createNodes(arg)

  const Context = React.createContext<UseFormReturn<T> | null>(null)

  const useCleanupForm = () =>
    useRecoilCallback(
      ({ reset, refresh }) =>
        () => {
          reset(baseFormValuesAtom)
          reset(formValuesState)
          reset(baseFormMethodsAtom)
          reset(formMethodsState)
          refresh(formValuesState)
          refresh(formMethodsState)
        },
      []
    )

  /**
   * - RHFのformをProviderで提供する
   * - RHFのformの値をRecoil Stateと同期させる
   * - アンマウント時に状態をリセットする
   */
  const FormProvider: React.FC<FormProviderProps<T>> = ({
    children,
    formProps,
  }) => {
    const [isInitialized, setIsInitialized] = useState(false)
    const [isInitializedMethods, setIsInitializedMethods] = useState(false)

    const cleanup = useCleanupForm()

    useEffectOnce(() => {
      return () => {
        nextTick(() => {
          cleanup()
        })
      }
    })

    // RHFをuseFormで宣言 & 戻り値をProviderで提供する
    const Provider: React.FC<FormProviderProps<T>> = ({
      children,
      formProps,
    }) => {
      const delegatedFormProps = useRecoilValueLoadable(
        delegatedUseFormProps
      ).getValue()
      const form = useReactHookForm<T>({ ...delegatedFormProps, ...formProps })

      // フォームの変化を検知してRecoil Nodeと同期する
      const FormSync: React.VFC<{ form: UseFormReturn<T> }> = ({ form }) => {
        const { control, register, unregister, watch, ...methods } = form
        const values = useWatch({ control })

        const setFormData = useSetRecoilState(formValuesState)
        const setFormMethods = useSetRecoilState(formMethodsState)

        setFormData(cloneDeep(values as T))
        setFormMethods(cloneDeep(methods))

        useEffectOnce(() => {
          if (!isInitialized) {
            setIsInitialized(true)
          }

          if (!isInitializedMethods) {
            setIsInitializedMethods(true)
          }
        })

        return null
      }

      return (
        <>
          <FormSync form={form} />
          <Context.Provider value={form}>{children}</Context.Provider>
        </>
      )
    }

    return (
      <ComponentSuspence>
        <Provider formProps={formProps}>
          {/* 子要素はフォーム初期化後にレンダリング */}
          {isInitialized && isInitializedMethods ? children : null}
        </Provider>
      </ComponentSuspence>
    )
  }

  const useForm = () => {
    const context = React.useContext(Context)

    if (!context) {
      throw new Error('require wrapped by FormProvider ')
    }

    return context
  }

  return {
    Provider: FormProvider,
    values: formValuesState,
    field,
    methods: formMethodsState,
    useCleanupForm,
    useForm,
  }
}
