import { useIsPublicPath } from '@/lib/hooks/useIsPublicPath'
import { useRecoilValue } from 'recoil'
import { isAuthStateLoaded } from './authState'

/** APIを呼べるかどうか */
export const useCanCallPrivateApi = () => {
  const isPublicPath = useIsPublicPath()
  const isAuthStateSubscribed = useRecoilValue(isAuthStateLoaded)

  return { isAuthStateSubscribed, isPublicPath } as const
}
