import { useRouter } from 'next/router'
import { useMemo } from 'react'

const PUBLIC_PATHS = Object.freeze(['/', '/login', '/password'])
const getLastPath = (path: string) =>
  // @ts-expect-error https://caloojp.atlassian.net/browse/SDEV-500
  path.split('?')[0].substring(path.lastIndexOf('/')) //Queryストリング対策

/** 認証が不要なURLかを判定する */
export const useIsPublicPath = () => {
  const { asPath } = useRouter()

  const isPublicPath = useMemo(() => {
    return PUBLIC_PATHS.some(
      (path) => getLastPath(path) === getLastPath(asPath)
    )
  }, [asPath])

  return isPublicPath
}
