import firebase from 'firebase'
import jwtDecode from 'jwt-decode'
import firebaseJSON from '../../firebase.json'

const AUTH_EMULATOR_PATH = `http://localhost:${firebaseJSON.emulators.auth.port}`

const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY || 'emulator',
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN || 'emulator',
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || 'emulator',
}

type UnixTimestampWith10digit = number

/** FirebaseのJWT */
type FirebaseJwt = {
  iss: string
  iat: UnixTimestampWith10digit
  sub: string
  aud: string
  user_id: string
  auth_time: UnixTimestampWith10digit
  exp: UnixTimestampWith10digit
  firebase: {
    identities: Record<string, unknown>
    sign_in_provider: string
    tenant: string
  }
}

export const decodeFirebaseJwt = (jwt: string) => {
  return jwtDecode<FirebaseJwt>(jwt)
}

export function initFirebase() {
  if (process.env.NEXT_PUBLIC_IGNORE_FIREBASE === 'true') {
    return
  }

  if (!firebase.apps.length) {
    firebase.initializeApp(config)

    if (
      process.env.NODE_ENV === 'development' &&
      process.env.NEXT_PUBLIC_USE_FIREBASE_EMULATOR === 'true'
    ) {
      firebase.auth().useEmulator(AUTH_EMULATOR_PATH)
      return
    }
  }
}

export default firebase
