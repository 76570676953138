import { useRecoilCallback } from 'recoil'
import { SnackbarState, snackbarState } from './snackbarState'

const DEFAULT_OPEN_SNACKBAR_PARAMS: SnackbarState = Object.freeze({
  open: true,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
}) as SnackbarState

const DEFAULT_CLOSE_SNACKBAR_PARAMS: SnackbarState = {
  open: false,
}

type OpenSnackbarParams = Pick<SnackbarState, 'message' | 'severity'>

export const useOpenSnackbar = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      async (params: OpenSnackbarParams) => {
        const snackBarState = await snapshot.getPromise(snackbarState)

        //非表示フラグが立っている場合は開かない
        if (snackBarState.forceClose) {
          return
        }

        const newValue: SnackbarState = {
          ...DEFAULT_OPEN_SNACKBAR_PARAMS,
          ...params,
        }

        set(snackbarState, newValue)
      }
  )
}

export const useCloseSnackbar = () => {
  return useRecoilCallback(({ set }) => () => {
    const newValue = DEFAULT_CLOSE_SNACKBAR_PARAMS

    set(snackbarState, newValue)
  })
}

/**
 * 特定promiseに対するsnackbarを非表示にするコールバックを返す
 */
export const useSuspendSnackbar = () => {
  const suspendSnackbar = useRecoilCallback(
    ({ set }) =>
      (promise: Promise<unknown>) => {
        //promise解決前: 強制非表示フラグを立てる
        set(snackbarState, (state) => {
          return {
            ...state,
            forceClose: true,
          }
        })

        //promise解決時: 強制非表示フラグを下ろす
        promise.finally(() => {
          set(snackbarState, (state) => {
            return {
              ...state,
              forceClose: false,
            }
          })
        })
      }
  )

  return suspendSnackbar
}
