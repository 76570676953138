import { MeModel } from '@/features/domains/tenants/_tenant_name@string/staffs/me/meModel'
import type * as Types from '@/lib/api-client/aspida/@types'
import { fetchFileFromS3 } from '@/lib/requests'

export class ShopsModel {
  private response: Types.ShopWithShopWorkingTimesResponseV1[]

  constructor(response: Types.ShopWithShopWorkingTimesResponseV1[]) {
    this.response = response
  }

  public getResponse() {
    return this.response
  }

  /** 開店している店舗のみ */
  public opened() {
    return this.response.filter((shop) => !shop.is_closed)
  }

  /** ログインユーザが選べる店舗 */
  public available(me: MeModel) {
    return me.getResponse().staff_role.is_admin
      ? this.getResponse() //管理者の場合はすべての店舗
      : me.getShops() //所属店舗
  }
}

export class SingleShopModel {
  private response: Types.ShopResponseV1

  constructor(response: Types.ShopResponseV1) {
    this.response = response
  }

  public getResponse() {
    return this.response
  }

  /** 画像をFileで取得 */
  public getImage() {
    const { image } = this.response
    if (!image) {
      return Promise.resolve(undefined)
    }

    return fetchFileFromS3(image.url, image.filename)
  }
}
