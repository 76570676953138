/** S3から画像をFileで取得する */
export const fetchFileFromS3 = (
  url: string,
  fileName: string
): Promise<File> => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }

        return response.blob()
      })
      .then((blob) => {
        const file = new File([blob], fileName, { type: blob.type })
        resolve(file)
      })
      .catch((error) => {
        console.debug(error)
        reject()
      })
  })
}

/** S3からテキストをstringで取得する */
export const fetchStringFromS3 = (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.text())
      .then((text) => {
        if (text === 'undefined') reject()
        resolve(text)
      })
      .catch((e) => {
        console.debug(e)
        reject()
      })
  })
}

/** S3で取得したbodyを返す */
export const fetchJsonFromS3 = <T = any>(url: string): Promise<T> => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        // 型がよめないのでデフォルトはanyで返す
        resolve(json)
      })
      .catch((e) => {
        console.debug(e)
        reject()
      })
  })
}
