import { useOpenSnackbar } from '@/features/states/snackbar/snackbarActions'
import { AxiosResponse } from 'axios'
import { useCallback } from 'react'
import { useDecrementRequestCount } from '../interceptorActions'
import { DeleteResponseInterceptor } from './impl/DeleteResponseInterceptor'
import { GetResponseInterceptor } from './impl/GetResponseInterceptor'
import { PatchResponseInterceptor } from './impl/PatchResponseInterceptor'
import { PostResponseInterceptor } from './impl/PostResponseInterceptor'
import { PutResponseInterceptor } from './impl/PutResponseInterceptor'

export type ResponseInterceptorFactoryOption = {
  response: AxiosResponse
}

export const AXIOS_MUTATION_METHODS = ['post', 'put', 'patch', 'delete']

export type AxiosMethod = 'get' | 'post' | 'put' | 'patch' | 'delete'

export type ResponseInterceptorImpl = (
  response: AxiosResponse,
  handler: { openSnackbar: ReturnType<typeof useOpenSnackbar> }
) => void

/** InterceptorTypeと実装群のマッピングオブジェクト */
const RESPONSE_INTERCEPTOR_METHOD_MAP: {
  [Method in AxiosMethod]: ResponseInterceptorImpl
} = Object.freeze({
  get: GetResponseInterceptor,
  post: PostResponseInterceptor,
  put: PutResponseInterceptor,
  patch: PatchResponseInterceptor,
  delete: DeleteResponseInterceptor,
})

/** AxiosResponseとInterceptorTypeをマッピングする */
export const getAxiosMethod = (
  response: ResponseInterceptorFactoryOption['response']
) => {
  const method = response.config.method
  if (!method) {
    console.error(`interceptor type: ${method} is not implemented `)
  }
  return method?.toLowerCase() as AxiosMethod
}

/**
 * Response Interceptorの共通処理をマージした、
 * HTTPメソッドごとのハンドラ実装を返すファクトリ
 */
export const useResponseInterceptorFactory = () => {
  const decrementRequestCount = useDecrementRequestCount()
  const openSnackbar = useOpenSnackbar()

  const factory = useCallback(
    ({ response }: ResponseInterceptorFactoryOption) => {
      const method = getAxiosMethod(response)
      const intercept = RESPONSE_INTERCEPTOR_METHOD_MAP[method]

      /** 共通処理 */
      const baseIntercept = () => {
        //GET以外のリクエストは画面ロックしている
        const usedBackDrop = method !== 'get'

        if (usedBackDrop) {
          decrementRequestCount()
        }
      }

      /** HTTPメソッドごとの実装  */
      const doIntercept = () => {
        baseIntercept()

        intercept(response, { openSnackbar })
      }

      return doIntercept
    },
    [decrementRequestCount, openSnackbar]
  )

  return factory
}
