import { globalUserState } from '@/components/providers/GlobalState/GlobalUserProvider'
import { ShopsModel } from '@/features/domains/tenants/_tenant_name@string/shops/shopsModel'
import { StaffAllModel } from '@/features/domains/tenants/_tenant_name@string/shops/_shop_id@number/staffs/all/staffAllModel'
import { atom, selector } from 'recoil'
import { currentShopApi, tenantApi } from '../apiClient/apiClientState'

/** shopIdはリクエスト等で利用するためrecoilで管理 */
export const globalShopIdState = atom({
  key: 'globalShopIdState',
  default: selector({
    key: 'globalShopIdState/default',
    get: async ({ get }) => {
      const me = get(globalUserState({ canCallApi: true }))

      //初期値はログインユーザーのメイン店舗ID
      const { id } = me.getResponse().primary_shop
      return id
    },
  }),
})

/** 現在のテナントの店舗一覧 */
export const globalShopsState = selector({
  key: 'globalShops',
  get: async ({ get }) => {
    const api = get(tenantApi)
    const shops = await api.shops.$get({ query: { show_closed: true } })

    return new ShopsModel(shops)
  },
})

/** 現在の店舗のスタッフ */
export const globalShopStaffs = selector({
  key: 'globalShopStaffs',
  get: async ({ get }) => {
    const api = get(currentShopApi)
    const staffs = await api.staffs.all.$get({
      query: { show_past: true, show_unused: true },
    })

    return new StaffAllModel(staffs)
  },
})
