import { http } from '@/lib/api-client/http'
import axios, {
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import { useMemo, useEffect } from 'react'
import { useRecoilCallback } from 'recoil'
import { requestCountState } from './interceptorState'
import { useRequestInterceptorFactory } from './RequestInterceptor/requestInterceptorActions'
import { useResponseErrorInterceptorFactory } from './ResponseErrorInterceptor/responseErrorInterceptorActions'
import { useResponseInterceptorFactory } from './ResponseInterceptor/responseInterceptorActions'

type RequestInterceptor = Parameters<
  AxiosInterceptorManager<AxiosRequestConfig>['use']
>

type ResponseInterceptor = Parameters<
  AxiosInterceptorManager<AxiosResponse>['use']
>

export const useIncrementRequestCount = () => {
  return useRecoilCallback(({ set }) => () => {
    set(requestCountState, (current) => current + 1)
  })
}

export const useDecrementRequestCount = () => {
  return useRecoilCallback(({ set }) => () => {
    set(requestCountState, (current) => current - 1)
  })
}

/**
 * axiosインスタンスへinterceptorを追加するhook
 * 具体的な処理は各InterceptorFactoryへ委譲する
 * */
export const useAxiosInterceptor = () => {
  const incrementRequestCount = useIncrementRequestCount()
  const requestInterceptorFactory = useRequestInterceptorFactory()
  const responseInterceptorFactory = useResponseInterceptorFactory()
  const responseErrorInterceptorFactory = useResponseErrorInterceptorFactory()

  const requestInterceptor: RequestInterceptor = useMemo(() => {
    return [
      (request) => {
        const intercept = requestInterceptorFactory({
          request,
        })

        intercept()

        return request
      },
      (error) => {
        incrementRequestCount()
        console.debug(error)
        return Promise.reject(error)
      },
    ]
  }, [incrementRequestCount, requestInterceptorFactory])

  const responseInterceptor: ResponseInterceptor = useMemo(() => {
    return [
      (response) => {
        const intercept = responseInterceptorFactory({
          response,
        })

        intercept()

        return response
      },
      (error) => {
        if (axios.isAxiosError(error)) {
          const intercept = responseErrorInterceptorFactory({
            error,
          })

          intercept()
        } else {
          //TODO unexpected error
          console.debug(error)
        }

        return Promise.reject(error)
      },
    ]
  }, [responseErrorInterceptorFactory, responseInterceptorFactory])

  useEffect(() => {
    const reqInterceptor = http.interceptors.request.use(...requestInterceptor)

    const resInterceptor = http.interceptors.response.use(
      ...responseInterceptor
    )

    return () => {
      http.interceptors.request.eject(reqInterceptor)
      http.interceptors.response.eject(resInterceptor)
    }
  }, [requestInterceptor, responseInterceptor])
}
