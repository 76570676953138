import React from 'react'
import Spinner from '@/components/molecules/Loading/Spinner/Spinner'

type ComponentSuspenceProps = {
  children: React.ReactNode
}

const FallBack = <Spinner />

export default function ComponentSuspence({
  children,
}: ComponentSuspenceProps) {
  if (typeof window === 'undefined') {
    return FallBack
  }

  return <React.Suspense fallback={FallBack}>{children}</React.Suspense>
}
