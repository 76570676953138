/* eslint-disable */
// prettier-ignore
import { AspidaClient, BasicHeaders, dataToURLString } from 'aspida'
// prettier-ignore
import { Methods as Methods0 } from './api/v1/banks'
// prettier-ignore
import { Methods as Methods1 } from './api/v1/banks/_bank_code@string'
// prettier-ignore
import { Methods as Methods2 } from './api/v1/banks/_bank_code@string/branches'
// prettier-ignore
import { Methods as Methods3 } from './api/v1/banks/_bank_code@string/branches/_branch_code@string'
// prettier-ignore
import { Methods as Methods4 } from './api/v1/payment_settings'
// prettier-ignore
import { Methods as Methods5 } from './api/v1/postal_code/_postal_code@string'
// prettier-ignore
import { Methods as Methods6 } from './api/v1/preset_machines'
// prettier-ignore
import { Methods as Methods7 } from './api/v1/resolveTenant'
// prettier-ignore
import { Methods as Methods8 } from './api/v1/tenant_route_hosts'
// prettier-ignore
import { Methods as Methods9 } from './api/v1/tenants'
// prettier-ignore
import { Methods as Methods10 } from './api/v1/tenants/_tenant_name@string'
// prettier-ignore
import { Methods as Methods11 } from './api/v1/tenants/_tenant_name@string/assets/upload'
// prettier-ignore
import { Methods as Methods12 } from './api/v1/tenants/_tenant_name@string/assets/uploadPrivate'
// prettier-ignore
import { Methods as Methods13 } from './api/v1/tenants/_tenant_name@string/background_jobs/_background_job_id@string'
// prettier-ignore
import { Methods as Methods14 } from './api/v1/tenants/_tenant_name@string/changeStaffPassword'
// prettier-ignore
import { Methods as Methods15 } from './api/v1/tenants/_tenant_name@string/claim_categories'
// prettier-ignore
import { Methods as Methods16 } from './api/v1/tenants/_tenant_name@string/claim_categories/_claim_category_id@number'
// prettier-ignore
import { Methods as Methods17 } from './api/v1/tenants/_tenant_name@string/claim_categories/batchUpdate'
// prettier-ignore
import { Methods as Methods18 } from './api/v1/tenants/_tenant_name@string/communication_categories'
// prettier-ignore
import { Methods as Methods19 } from './api/v1/tenants/_tenant_name@string/communication_categories/_communication_category_id@number'
// prettier-ignore
import { Methods as Methods20 } from './api/v1/tenants/_tenant_name@string/communication_categories/batchUpdate'
// prettier-ignore
import { Methods as Methods21 } from './api/v1/tenants/_tenant_name@string/customer_remarks'
// prettier-ignore
import { Methods as Methods22 } from './api/v1/tenants/_tenant_name@string/customer_remarks/_customer_remark_id@number'
// prettier-ignore
import { Methods as Methods23 } from './api/v1/tenants/_tenant_name@string/customer_remarks/batchUpdate'
// prettier-ignore
import { Methods as Methods24 } from './api/v1/tenants/_tenant_name@string/customers'
// prettier-ignore
import { Methods as Methods25 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number'
// prettier-ignore
import { Methods as Methods26 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/changeShop'
// prettier-ignore
import { Methods as Methods27 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/communication_histories'
// prettier-ignore
import { Methods as Methods28 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/communications'
// prettier-ignore
import { Methods as Methods29 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/communications/_communication_id@number'
// prettier-ignore
import { Methods as Methods30 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/contract_details'
// prettier-ignore
import { Methods as Methods31 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/contract_details/getSummary'
// prettier-ignore
import { Methods as Methods32 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/contracts'
// prettier-ignore
import { Methods as Methods33 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/contracts/_contract_id@number'
// prettier-ignore
import { Methods as Methods34 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/contracts/_contract_id@number/contract_details/_contract_detail_id@number/contract_penalty'
// prettier-ignore
import { Methods as Methods35 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/contracts/_contract_id@number/contract_details/_contract_detail_id@number/executeCancel'
// prettier-ignore
import { Methods as Methods36 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/contracts/_contract_id@number/contract_payments'
// prettier-ignore
import { Methods as Methods37 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/contracts/_contract_id@number/contract_payments/_contract_payment_id@number/cancelOnlinePayment'
// prettier-ignore
import { Methods as Methods38 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/contracts/_contract_id@number/contract_payments/_contract_payment_id@number/publishOnlinePaymentUrl'
// prettier-ignore
import { Methods as Methods39 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/contracts/_contract_id@number/contract_penalty'
// prettier-ignore
import { Methods as Methods40 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/contracts/_contract_id@number/executeCancel'
// prettier-ignore
import { Methods as Methods41 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/contracts/all'
// prettier-ignore
import { Methods as Methods42 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/contracts/products'
// prettier-ignore
import { Methods as Methods43 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/counselings'
// prettier-ignore
import { Methods as Methods44 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/counselings/_counseling_id@number'
// prettier-ignore
import { Methods as Methods45 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/detail'
// prettier-ignore
import { Methods as Methods46 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/documents'
// prettier-ignore
import { Methods as Methods47 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/documents/_document_id@number'
// prettier-ignore
import { Methods as Methods48 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/documents/createSnapshot'
// prettier-ignore
import { Methods as Methods49 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/ekartes'
// prettier-ignore
import { Methods as Methods50 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/ekartes/_ekarte_id@number'
// prettier-ignore
import { Methods as Methods51 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/ekartes/_ekarte_id@number/assets'
// prettier-ignore
import { Methods as Methods52 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/ekartes/asset_labels'
// prettier-ignore
import { Methods as Methods53 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/ekartes/assets'
// prettier-ignore
import { Methods as Methods54 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/ekartes/assets/_ekarte_asset_id@number'
// prettier-ignore
import { Methods as Methods55 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/ekartes/createSnapshot'
// prettier-ignore
import { Methods as Methods56 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/inflow'
// prettier-ignore
import { Methods as Methods57 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/interview_answers'
// prettier-ignore
import { Methods as Methods58 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/interview_answers/_interview_answer_id@number'
// prettier-ignore
import { Methods as Methods59 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/relevant_documents'
// prettier-ignore
import { Methods as Methods60 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/relevant_documents/_relevant_document_id@number'
// prettier-ignore
import { Methods as Methods61 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/revertDeletion'
// prettier-ignore
import { Methods as Methods62 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/sendMessage'
// prettier-ignore
import { Methods as Methods63 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/shop_change_histories'
// prettier-ignore
import { Methods as Methods64 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/shops/staffs'
// prettier-ignore
import { Methods as Methods65 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/summary'
// prettier-ignore
import { Methods as Methods66 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/treatment_histories'
// prettier-ignore
import { Methods as Methods67 } from './api/v1/tenants/_tenant_name@string/customers/_customer_id@number/treatment_histories/_treatment_history_id@number'
// prettier-ignore
import { Methods as Methods68 } from './api/v1/tenants/_tenant_name@string/customers/reduced'
// prettier-ignore
import { Methods as Methods69 } from './api/v1/tenants/_tenant_name@string/digital_ticket_menus'
// prettier-ignore
import { Methods as Methods70 } from './api/v1/tenants/_tenant_name@string/digital_ticket_settings'
// prettier-ignore
import { Methods as Methods71 } from './api/v1/tenants/_tenant_name@string/document_templates'
// prettier-ignore
import { Methods as Methods72 } from './api/v1/tenants/_tenant_name@string/document_templates/_document_template_id@number'
// prettier-ignore
import { Methods as Methods73 } from './api/v1/tenants/_tenant_name@string/document_templates/batchUpdate'
// prettier-ignore
import { Methods as Methods74 } from './api/v1/tenants/_tenant_name@string/ekarte_templates'
// prettier-ignore
import { Methods as Methods75 } from './api/v1/tenants/_tenant_name@string/ekarte_templates/_ekarte_template_id@number'
// prettier-ignore
import { Methods as Methods76 } from './api/v1/tenants/_tenant_name@string/ekarte_templates/batchUpdate'
// prettier-ignore
import { Methods as Methods77 } from './api/v1/tenants/_tenant_name@string/enquetes'
// prettier-ignore
import { Methods as Methods78 } from './api/v1/tenants/_tenant_name@string/enquetes/_enquete_id@number'
// prettier-ignore
import { Methods as Methods79 } from './api/v1/tenants/_tenant_name@string/enquetes/all'
// prettier-ignore
import { Methods as Methods80 } from './api/v1/tenants/_tenant_name@string/ex/checkIn'
// prettier-ignore
import { Methods as Methods81 } from './api/v1/tenants/_tenant_name@string/ex/confirmChangeEmail'
// prettier-ignore
import { Methods as Methods82 } from './api/v1/tenants/_tenant_name@string/ex/customers/_customer_id@number'
// prettier-ignore
import { Methods as Methods83 } from './api/v1/tenants/_tenant_name@string/ex/customers/_customer_id@number/contracts'
// prettier-ignore
import { Methods as Methods84 } from './api/v1/tenants/_tenant_name@string/ex/customers/_customer_id@number/documents'
// prettier-ignore
import { Methods as Methods85 } from './api/v1/tenants/_tenant_name@string/ex/customers/_customer_id@number/online_payment_user'
// prettier-ignore
import { Methods as Methods86 } from './api/v1/tenants/_tenant_name@string/ex/customers/_customer_id@number/reservation_groups'
// prettier-ignore
import { Methods as Methods87 } from './api/v1/tenants/_tenant_name@string/ex/customers/_customer_id@string/contracts/_contract_associate_id@string'
// prettier-ignore
import { Methods as Methods88 } from './api/v1/tenants/_tenant_name@string/ex/customers/_customer_id@string/disconnectLineId'
// prettier-ignore
import { Methods as Methods89 } from './api/v1/tenants/_tenant_name@string/ex/customers/confirmConnectLineId'
// prettier-ignore
import { Methods as Methods90 } from './api/v1/tenants/_tenant_name@string/ex/customers/connectLineId'
// prettier-ignore
import { Methods as Methods91 } from './api/v1/tenants/_tenant_name@string/ex/customers/verifyLineIdToken'
// prettier-ignore
import { Methods as Methods92 } from './api/v1/tenants/_tenant_name@string/ex/enquetes/_enquete_id@number'
// prettier-ignore
import { Methods as Methods93 } from './api/v1/tenants/_tenant_name@string/ex/interview_answers'
// prettier-ignore
import { Methods as Methods94 } from './api/v1/tenants/_tenant_name@string/ex/interviews'
// prettier-ignore
import { Methods as Methods95 } from './api/v1/tenants/_tenant_name@string/ex/online_payment'
// prettier-ignore
import { Methods as Methods96 } from './api/v1/tenants/_tenant_name@string/ex/web_reservation_settings'
// prettier-ignore
import { Methods as Methods97 } from './api/v1/tenants/_tenant_name@string/getRelevantDocumentNames'
// prettier-ignore
import { Methods as Methods98 } from './api/v1/tenants/_tenant_name@string/getSigninInfo'
// prettier-ignore
import { Methods as Methods99 } from './api/v1/tenants/_tenant_name@string/inflow_routes'
// prettier-ignore
import { Methods as Methods100 } from './api/v1/tenants/_tenant_name@string/inflow_routes/_inflow_route_id@number'
// prettier-ignore
import { Methods as Methods101 } from './api/v1/tenants/_tenant_name@string/inflow_routes/batchUpdate'
// prettier-ignore
import { Methods as Methods102 } from './api/v1/tenants/_tenant_name@string/interviews'
// prettier-ignore
import { Methods as Methods103 } from './api/v1/tenants/_tenant_name@string/logo_images'
// prettier-ignore
import { Methods as Methods104 } from './api/v1/tenants/_tenant_name@string/machines'
// prettier-ignore
import { Methods as Methods105 } from './api/v1/tenants/_tenant_name@string/machines/_machine_id@number'
// prettier-ignore
import { Methods as Methods106 } from './api/v1/tenants/_tenant_name@string/machines/batchUpdate'
// prettier-ignore
import { Methods as Methods107 } from './api/v1/tenants/_tenant_name@string/message_settings'
// prettier-ignore
import { Methods as Methods108 } from './api/v1/tenants/_tenant_name@string/message_settings/_message_setting_id@number'
// prettier-ignore
import { Methods as Methods109 } from './api/v1/tenants/_tenant_name@string/message_settings/_message_setting_id@number/getTargetCustomers'
// prettier-ignore
import { Methods as Methods110 } from './api/v1/tenants/_tenant_name@string/message_settings/sendMessages'
// prettier-ignore
import { Methods as Methods111 } from './api/v1/tenants/_tenant_name@string/message_templates'
// prettier-ignore
import { Methods as Methods112 } from './api/v1/tenants/_tenant_name@string/message_templates/_message_template_id@number'
// prettier-ignore
import { Methods as Methods113 } from './api/v1/tenants/_tenant_name@string/message_templates/_message_template_id@number/sendTestingMessage'
// prettier-ignore
import { Methods as Methods114 } from './api/v1/tenants/_tenant_name@string/message_templates/template_kind/_template_kind/batchUpdate'
// prettier-ignore
import { Methods as Methods115 } from './api/v1/tenants/_tenant_name@string/payment_settings'
// prettier-ignore
import { Methods as Methods116 } from './api/v1/tenants/_tenant_name@string/product_categories'
// prettier-ignore
import { Methods as Methods117 } from './api/v1/tenants/_tenant_name@string/product_categories/_product_category_id@number'
// prettier-ignore
import { Methods as Methods118 } from './api/v1/tenants/_tenant_name@string/product_categories/batchUpdate'
// prettier-ignore
import { Methods as Methods119 } from './api/v1/tenants/_tenant_name@string/products'
// prettier-ignore
import { Methods as Methods120 } from './api/v1/tenants/_tenant_name@string/products/_product_id@number'
// prettier-ignore
import { Methods as Methods121 } from './api/v1/tenants/_tenant_name@string/products/batchUpdate'
// prettier-ignore
import { Methods as Methods122 } from './api/v1/tenants/_tenant_name@string/report/business/daily'
// prettier-ignore
import { Methods as Methods123 } from './api/v1/tenants/_tenant_name@string/report/business/daily/export'
// prettier-ignore
import { Methods as Methods124 } from './api/v1/tenants/_tenant_name@string/report/business/monthly'
// prettier-ignore
import { Methods as Methods125 } from './api/v1/tenants/_tenant_name@string/report/business/monthly/export'
// prettier-ignore
import { Methods as Methods126 } from './api/v1/tenants/_tenant_name@string/report/business/shop'
// prettier-ignore
import { Methods as Methods127 } from './api/v1/tenants/_tenant_name@string/report/business/shop/export'
// prettier-ignore
import { Methods as Methods128 } from './api/v1/tenants/_tenant_name@string/report/contract/contract_penalties'
// prettier-ignore
import { Methods as Methods129 } from './api/v1/tenants/_tenant_name@string/report/contract/contract_penalties/export'
// prettier-ignore
import { Methods as Methods130 } from './api/v1/tenants/_tenant_name@string/report/contract/payment_amount_by_payment_method'
// prettier-ignore
import { Methods as Methods131 } from './api/v1/tenants/_tenant_name@string/report/contract/payment_amount_by_payment_method/export'
// prettier-ignore
import { Methods as Methods132 } from './api/v1/tenants/_tenant_name@string/report/contract/payment_items_by_payment_method'
// prettier-ignore
import { Methods as Methods133 } from './api/v1/tenants/_tenant_name@string/report/contract/payment_items_by_payment_method/export'
// prettier-ignore
import { Methods as Methods134 } from './api/v1/tenants/_tenant_name@string/report/contract/treatment_histories'
// prettier-ignore
import { Methods as Methods135 } from './api/v1/tenants/_tenant_name@string/report/contract/treatment_histories/export'
// prettier-ignore
import { Methods as Methods136 } from './api/v1/tenants/_tenant_name@string/report/contract/uncontracted_counselings'
// prettier-ignore
import { Methods as Methods137 } from './api/v1/tenants/_tenant_name@string/report/contract/uncontracted_counselings/export'
// prettier-ignore
import { Methods as Methods138 } from './api/v1/tenants/_tenant_name@string/report/enquete'
// prettier-ignore
import { Methods as Methods139 } from './api/v1/tenants/_tenant_name@string/report/enquetes'
// prettier-ignore
import { Methods as Methods140 } from './api/v1/tenants/_tenant_name@string/report/enquetes/_enquete_id@number/answers'
// prettier-ignore
import { Methods as Methods141 } from './api/v1/tenants/_tenant_name@string/report/enquetes/_enquete_id@number/export'
// prettier-ignore
import { Methods as Methods142 } from './api/v1/tenants/_tenant_name@string/report/enquetes/_enquete_id@number/memos'
// prettier-ignore
import { Methods as Methods143 } from './api/v1/tenants/_tenant_name@string/report/enquetes/_enquete_id@number/summary'
// prettier-ignore
import { Methods as Methods144 } from './api/v1/tenants/_tenant_name@string/report/enquetes/_enquete_id@number/transitions'
// prettier-ignore
import { Methods as Methods145 } from './api/v1/tenants/_tenant_name@string/report/reservations/daily'
// prettier-ignore
import { Methods as Methods146 } from './api/v1/tenants/_tenant_name@string/report/reservations/daily/export'
// prettier-ignore
import { Methods as Methods147 } from './api/v1/tenants/_tenant_name@string/report/reservations/monthly'
// prettier-ignore
import { Methods as Methods148 } from './api/v1/tenants/_tenant_name@string/report/reservations/monthly/export'
// prettier-ignore
import { Methods as Methods149 } from './api/v1/tenants/_tenant_name@string/report/reservations/shop'
// prettier-ignore
import { Methods as Methods150 } from './api/v1/tenants/_tenant_name@string/report/reservations/shop/export'
// prettier-ignore
import { Methods as Methods151 } from './api/v1/tenants/_tenant_name@string/report/sales/contract_detail'
// prettier-ignore
import { Methods as Methods152 } from './api/v1/tenants/_tenant_name@string/report/sales/contract_detail/export'
// prettier-ignore
import { Methods as Methods153 } from './api/v1/tenants/_tenant_name@string/report/sales/private_expenses'
// prettier-ignore
import { Methods as Methods154 } from './api/v1/tenants/_tenant_name@string/report/sales/private_expenses/export'
// prettier-ignore
import { Methods as Methods155 } from './api/v1/tenants/_tenant_name@string/report/sales/product'
// prettier-ignore
import { Methods as Methods156 } from './api/v1/tenants/_tenant_name@string/report/sales/product/export'
// prettier-ignore
import { Methods as Methods157 } from './api/v1/tenants/_tenant_name@string/report/staff/_staff_id@number/counseling'
// prettier-ignore
import { Methods as Methods158 } from './api/v1/tenants/_tenant_name@string/report/staff/_staff_id@number/counseling/contract_penalties_reason_descriptions'
// prettier-ignore
import { Methods as Methods159 } from './api/v1/tenants/_tenant_name@string/report/staff/_staff_id@number/counseling/uncontracted_reason_details'
// prettier-ignore
import { Methods as Methods160 } from './api/v1/tenants/_tenant_name@string/report/staff/_staff_id@number/sales'
// prettier-ignore
import { Methods as Methods161 } from './api/v1/tenants/_tenant_name@string/report/staff/_staff_id@number/treatments'
// prettier-ignore
import { Methods as Methods162 } from './api/v1/tenants/_tenant_name@string/report/staff/sales'
// prettier-ignore
import { Methods as Methods163 } from './api/v1/tenants/_tenant_name@string/report/staff/sales/export'
// prettier-ignore
import { Methods as Methods164 } from './api/v1/tenants/_tenant_name@string/report/staff/treatment'
// prettier-ignore
import { Methods as Methods165 } from './api/v1/tenants/_tenant_name@string/reservation_categories/_reservation_category_id@number'
// prettier-ignore
import { Methods as Methods166 } from './api/v1/tenants/_tenant_name@string/reservation_categories/batchUpdate'
// prettier-ignore
import { Methods as Methods167 } from './api/v1/tenants/_tenant_name@string/reservation_settings'
// prettier-ignore
import { Methods as Methods168 } from './api/v1/tenants/_tenant_name@string/sales_promotions'
// prettier-ignore
import { Methods as Methods169 } from './api/v1/tenants/_tenant_name@string/sales_promotions/_sales_promotion_id@number'
// prettier-ignore
import { Methods as Methods170 } from './api/v1/tenants/_tenant_name@string/sales_promotions/batchUpdate'
// prettier-ignore
import { Methods as Methods171 } from './api/v1/tenants/_tenant_name@string/shops'
// prettier-ignore
import { Methods as Methods172 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number'
// prettier-ignore
import { Methods as Methods173 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number/accounts_receivables'
// prettier-ignore
import { Methods as Methods174 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number/claims'
// prettier-ignore
import { Methods as Methods175 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number/claims/_claim_id@number'
// prettier-ignore
import { Methods as Methods176 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number/extra_working_times'
// prettier-ignore
import { Methods as Methods177 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number/getCheckinQrCode'
// prettier-ignore
import { Methods as Methods178 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number/holidays'
// prettier-ignore
import { Methods as Methods179 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number/reservation_groups/batchUpdate'
// prettier-ignore
import { Methods as Methods180 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number/resources/rooms/batchUpdate'
// prettier-ignore
import { Methods as Methods181 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number/shop_resource_blocks'
// prettier-ignore
import { Methods as Methods182 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number/shop_resource_blocks/_shop_resource_block_id@number'
// prettier-ignore
import { Methods as Methods183 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number/staff_shift_schedule_rules'
// prettier-ignore
import { Methods as Methods184 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number/staff_shift_schedule_settings'
// prettier-ignore
import { Methods as Methods185 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number/staff_shift_schedules'
// prettier-ignore
import { Methods as Methods186 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number/staffs/all'
// prettier-ignore
import { Methods as Methods187 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number/staffs/batchUpdate'
// prettier-ignore
import { Methods as Methods188 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number/supports'
// prettier-ignore
import { Methods as Methods189 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number/supports/_support_id@number'
// prettier-ignore
import { Methods as Methods190 } from './api/v1/tenants/_tenant_name@string/shops/_shop_id@number/working_times'
// prettier-ignore
import { Methods as Methods191 } from './api/v1/tenants/_tenant_name@string/shops/batchUpdate'
// prettier-ignore
import { Methods as Methods192 } from './api/v1/tenants/_tenant_name@string/signin'
// prettier-ignore
import { Methods as Methods193 } from './api/v1/tenants/_tenant_name@string/staff_roles'
// prettier-ignore
import { Methods as Methods194 } from './api/v1/tenants/_tenant_name@string/staff_roles/_staff_role_id@number'
// prettier-ignore
import { Methods as Methods195 } from './api/v1/tenants/_tenant_name@string/staff_roles/batchUpdate'
// prettier-ignore
import { Methods as Methods196 } from './api/v1/tenants/_tenant_name@string/staffs'
// prettier-ignore
import { Methods as Methods197 } from './api/v1/tenants/_tenant_name@string/staffs/_staff_id@number'
// prettier-ignore
import { Methods as Methods198 } from './api/v1/tenants/_tenant_name@string/staffs/_staff_id@number/acceptPasswordChange'
// prettier-ignore
import { Methods as Methods199 } from './api/v1/tenants/_tenant_name@string/staffs/all'
// prettier-ignore
import { Methods as Methods200 } from './api/v1/tenants/_tenant_name@string/staffs/me'
// prettier-ignore
import { Methods as Methods201 } from './api/v1/tenants/_tenant_name@string/template_files/_template_file_id@number'
// prettier-ignore
import { Methods as Methods202 } from './api/v1/tenants/_tenant_name@string/tenant_assets'
// prettier-ignore
import { Methods as Methods203 } from './api/v1/tenants/_tenant_name@string/tenant_assets/_tenant_asset_id@number'
// prettier-ignore
import { Methods as Methods204 } from './api/v1/tenants/_tenant_name@string/visitation_statuses'
// prettier-ignore
import { Methods as Methods205 } from './api/v1/tenants/_tenant_name@string/visitation_statuses/batchUpdate'
// prettier-ignore
import { Methods as Methods206 } from './api/v1/tenants/_tenant_name@string/voucher_document_settings'
// prettier-ignore
import { Methods as Methods207 } from './api/v1/tenants/_tenant_name@string/web_reservation_areas'
// prettier-ignore
import { Methods as Methods208 } from './api/v1/tenants/_tenant_name@string/web_reservation_areas/_web_reservation_area_id@number'
// prettier-ignore
import { Methods as Methods209 } from './api/v1/tenants/_tenant_name@string/web_reservation_areas/batchUpdate'
// prettier-ignore
import { Methods as Methods210 } from './api/v1/tenants/_tenant_name@string/web_reservation_enquetes'
// prettier-ignore
import { Methods as Methods211 } from './api/v1/tenants/_tenant_name@string/web_reservation_menus/batchUpdate'
// prettier-ignore
import { Methods as Methods212 } from './api/v1/tenants/_tenant_name@string/web_reservation_settings'
// prettier-ignore
import { Methods as Methods213 } from './api/v1/tenants/_tenant_name@string/web_reservation_texts'
// prettier-ignore
import { Methods as Methods214 } from './api/v2/tenants/_tenant_name@string/customers/_customer_id@number/contracts'
// prettier-ignore
import { Methods as Methods215 } from './api/v2/tenants/_tenant_name@string/customers/_customer_id@number/contracts/_contract_associate_id@number'
// prettier-ignore
import { Methods as Methods216 } from './api/v2/tenants/_tenant_name@string/customers/_customer_id@number/contracts/_contract_associate_id@number/createDocument'
// prettier-ignore
import { Methods as Methods217 } from './api/v2/tenants/_tenant_name@string/customers/_customer_id@number/contracts/_contract_associate_id@number/fixToContract'
// prettier-ignore
import { Methods as Methods218 } from './api/v2/tenants/_tenant_name@string/customers/_customer_id@number/reservation_categories/_reservation_category_id@number/reservation_contents'
// prettier-ignore
import { Methods as Methods219 } from './api/v2/tenants/_tenant_name@string/customers/_customer_id@number/reservation_groups'
// prettier-ignore
import { Methods as Methods220 } from './api/v2/tenants/_tenant_name@string/customers/_customer_id@number/reservation_groups/_reservation_group_id@number'
// prettier-ignore
import { Methods as Methods221 } from './api/v2/tenants/_tenant_name@string/customers/_customer_id@number/reservation_groups/_reservation_group_id@number/execCancel'
// prettier-ignore
import { Methods as Methods222 } from './api/v2/tenants/_tenant_name@string/ex/line/fetchCustomer'
// prettier-ignore
import { Methods as Methods223 } from './api/v2/tenants/_tenant_name@string/ex/reservation_candidate_dates'
// prettier-ignore
import { Methods as Methods224 } from './api/v2/tenants/_tenant_name@string/ex/reservation_candidate_times'
// prettier-ignore
import { Methods as Methods225 } from './api/v2/tenants/_tenant_name@string/ex/reservation_groups'
// prettier-ignore
import { Methods as Methods226 } from './api/v2/tenants/_tenant_name@string/ex/web_reservation_areas'
// prettier-ignore
import { Methods as Methods227 } from './api/v2/tenants/_tenant_name@string/ex/web_reservation_menus'
// prettier-ignore
import { Methods as Methods228 } from './api/v2/tenants/_tenant_name@string/ex/web_reservation_menus/_web_reservation_menu_id@number'
// prettier-ignore
import { Methods as Methods229 } from './api/v2/tenants/_tenant_name@string/ex/web_reservation_menus/_web_reservation_menu_id@number/staffs'
// prettier-ignore
import { Methods as Methods230 } from './api/v2/tenants/_tenant_name@string/ex/web_reservation_shops'
// prettier-ignore
import { Methods as Methods231 } from './api/v2/tenants/_tenant_name@string/reservation_categories'
// prettier-ignore
import { Methods as Methods232 } from './api/v2/tenants/_tenant_name@string/room_usages'
// prettier-ignore
import { Methods as Methods233 } from './api/v2/tenants/_tenant_name@string/room_usages/_room_usage_id@number'
// prettier-ignore
import { Methods as Methods234 } from './api/v2/tenants/_tenant_name@string/room_usages/batchUpdate'
// prettier-ignore
import { Methods as Methods235 } from './api/v2/tenants/_tenant_name@string/shops/_shop_id@number/checkReservableShopResources'
// prettier-ignore
import { Methods as Methods236 } from './api/v2/tenants/_tenant_name@string/shops/_shop_id@number/reservation_groups'
// prettier-ignore
import { Methods as Methods237 } from './api/v2/tenants/_tenant_name@string/shops/_shop_id@number/reservation_groups/_reservation_group_id@number'
// prettier-ignore
import { Methods as Methods238 } from './api/v2/tenants/_tenant_name@string/shops/_shop_id@number/reservation_groups/list'
// prettier-ignore
import { Methods as Methods239 } from './api/v2/tenants/_tenant_name@string/shops/_shop_id@number/resources/machines'
// prettier-ignore
import { Methods as Methods240 } from './api/v2/tenants/_tenant_name@string/shops/_shop_id@number/resources/machines/_shop_machine_id@number'
// prettier-ignore
import { Methods as Methods241 } from './api/v2/tenants/_tenant_name@string/shops/_shop_id@number/resources/rooms'
// prettier-ignore
import { Methods as Methods242 } from './api/v2/tenants/_tenant_name@string/shops/_shop_id@number/resources/rooms/_shop_resource_id@number'
// prettier-ignore
import { Methods as Methods243 } from './api/v2/tenants/_tenant_name@string/shops/_shop_id@number/searchReservationCandidateDates'
// prettier-ignore
import { Methods as Methods244 } from './api/v2/tenants/_tenant_name@string/shops/_shop_id@number/staffs'
// prettier-ignore
import { Methods as Methods245 } from './api/v2/tenants/_tenant_name@string/shops/_shop_id@number/staffs/_staff_id@number'
// prettier-ignore
import { Methods as Methods246 } from './api/v2/tenants/_tenant_name@string/staff_groups'
// prettier-ignore
import { Methods as Methods247 } from './api/v2/tenants/_tenant_name@string/staff_groups/_staff_group_id@number'
// prettier-ignore
import { Methods as Methods248 } from './api/v2/tenants/_tenant_name@string/staff_groups/batchUpdate'
// prettier-ignore
import { Methods as Methods249 } from './api/v2/tenants/_tenant_name@string/web_reservation_customer_info_items'
// prettier-ignore
import { Methods as Methods250 } from './api/v2/tenants/_tenant_name@string/web_reservation_menus'
// prettier-ignore
import { Methods as Methods251 } from './api/v2/tenants/_tenant_name@string/web_reservation_menus/_web_reservation_menu_id@number'

// prettier-ignore
const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'http://localhost:8001' : baseURL).replace(/\/$/, '')
  const PATH0 = '/api/v1/banks'
  const PATH1 = '/branches'
  const PATH2 = '/api/v1/payment_settings'
  const PATH3 = '/api/v1/postal_code'
  const PATH4 = '/api/v1/preset_machines'
  const PATH5 = '/api/v1/resolveTenant'
  const PATH6 = '/api/v1/tenant_route_hosts'
  const PATH7 = '/api/v1/tenants'
  const PATH8 = '/assets/upload'
  const PATH9 = '/assets/uploadPrivate'
  const PATH10 = '/background_jobs'
  const PATH11 = '/changeStaffPassword'
  const PATH12 = '/claim_categories'
  const PATH13 = '/claim_categories/batchUpdate'
  const PATH14 = '/communication_categories'
  const PATH15 = '/communication_categories/batchUpdate'
  const PATH16 = '/customer_remarks'
  const PATH17 = '/customer_remarks/batchUpdate'
  const PATH18 = '/customers'
  const PATH19 = '/changeShop'
  const PATH20 = '/communication_histories'
  const PATH21 = '/communications'
  const PATH22 = '/contract_details'
  const PATH23 = '/contract_details/getSummary'
  const PATH24 = '/contracts'
  const PATH25 = '/contract_penalty'
  const PATH26 = '/executeCancel'
  const PATH27 = '/contract_payments'
  const PATH28 = '/cancelOnlinePayment'
  const PATH29 = '/publishOnlinePaymentUrl'
  const PATH30 = '/contracts/all'
  const PATH31 = '/contracts/products'
  const PATH32 = '/counselings'
  const PATH33 = '/detail'
  const PATH34 = '/documents'
  const PATH35 = '/documents/createSnapshot'
  const PATH36 = '/ekartes'
  const PATH37 = '/assets'
  const PATH38 = '/ekartes/asset_labels'
  const PATH39 = '/ekartes/assets'
  const PATH40 = '/ekartes/createSnapshot'
  const PATH41 = '/inflow'
  const PATH42 = '/interview_answers'
  const PATH43 = '/relevant_documents'
  const PATH44 = '/revertDeletion'
  const PATH45 = '/sendMessage'
  const PATH46 = '/shop_change_histories'
  const PATH47 = '/shops/staffs'
  const PATH48 = '/summary'
  const PATH49 = '/treatment_histories'
  const PATH50 = '/customers/reduced'
  const PATH51 = '/digital_ticket_menus'
  const PATH52 = '/digital_ticket_settings'
  const PATH53 = '/document_templates'
  const PATH54 = '/document_templates/batchUpdate'
  const PATH55 = '/ekarte_templates'
  const PATH56 = '/ekarte_templates/batchUpdate'
  const PATH57 = '/enquetes'
  const PATH58 = '/enquetes/all'
  const PATH59 = '/ex/checkIn'
  const PATH60 = '/ex/confirmChangeEmail'
  const PATH61 = '/ex/customers'
  const PATH62 = '/online_payment_user'
  const PATH63 = '/reservation_groups'
  const PATH64 = '/disconnectLineId'
  const PATH65 = '/ex/customers/confirmConnectLineId'
  const PATH66 = '/ex/customers/connectLineId'
  const PATH67 = '/ex/customers/verifyLineIdToken'
  const PATH68 = '/ex/enquetes'
  const PATH69 = '/ex/interview_answers'
  const PATH70 = '/ex/interviews'
  const PATH71 = '/ex/online_payment'
  const PATH72 = '/ex/web_reservation_settings'
  const PATH73 = '/getRelevantDocumentNames'
  const PATH74 = '/getSigninInfo'
  const PATH75 = '/inflow_routes'
  const PATH76 = '/inflow_routes/batchUpdate'
  const PATH77 = '/interviews'
  const PATH78 = '/logo_images'
  const PATH79 = '/machines'
  const PATH80 = '/machines/batchUpdate'
  const PATH81 = '/message_settings'
  const PATH82 = '/getTargetCustomers'
  const PATH83 = '/message_settings/sendMessages'
  const PATH84 = '/message_templates'
  const PATH85 = '/sendTestingMessage'
  const PATH86 = '/message_templates/template_kind'
  const PATH87 = '/batchUpdate'
  const PATH88 = '/payment_settings'
  const PATH89 = '/product_categories'
  const PATH90 = '/product_categories/batchUpdate'
  const PATH91 = '/products'
  const PATH92 = '/products/batchUpdate'
  const PATH93 = '/report/business/daily'
  const PATH94 = '/report/business/daily/export'
  const PATH95 = '/report/business/monthly'
  const PATH96 = '/report/business/monthly/export'
  const PATH97 = '/report/business/shop'
  const PATH98 = '/report/business/shop/export'
  const PATH99 = '/report/contract/contract_penalties'
  const PATH100 = '/report/contract/contract_penalties/export'
  const PATH101 = '/report/contract/payment_amount_by_payment_method'
  const PATH102 = '/report/contract/payment_amount_by_payment_method/export'
  const PATH103 = '/report/contract/payment_items_by_payment_method'
  const PATH104 = '/report/contract/payment_items_by_payment_method/export'
  const PATH105 = '/report/contract/treatment_histories'
  const PATH106 = '/report/contract/treatment_histories/export'
  const PATH107 = '/report/contract/uncontracted_counselings'
  const PATH108 = '/report/contract/uncontracted_counselings/export'
  const PATH109 = '/report/enquete'
  const PATH110 = '/report/enquetes'
  const PATH111 = '/answers'
  const PATH112 = '/export'
  const PATH113 = '/memos'
  const PATH114 = '/transitions'
  const PATH115 = '/report/reservations/daily'
  const PATH116 = '/report/reservations/daily/export'
  const PATH117 = '/report/reservations/monthly'
  const PATH118 = '/report/reservations/monthly/export'
  const PATH119 = '/report/reservations/shop'
  const PATH120 = '/report/reservations/shop/export'
  const PATH121 = '/report/sales/contract_detail'
  const PATH122 = '/report/sales/contract_detail/export'
  const PATH123 = '/report/sales/private_expenses'
  const PATH124 = '/report/sales/private_expenses/export'
  const PATH125 = '/report/sales/product'
  const PATH126 = '/report/sales/product/export'
  const PATH127 = '/report/staff'
  const PATH128 = '/counseling'
  const PATH129 = '/counseling/contract_penalties_reason_descriptions'
  const PATH130 = '/counseling/uncontracted_reason_details'
  const PATH131 = '/sales'
  const PATH132 = '/treatments'
  const PATH133 = '/report/staff/sales'
  const PATH134 = '/report/staff/sales/export'
  const PATH135 = '/report/staff/treatment'
  const PATH136 = '/reservation_categories'
  const PATH137 = '/reservation_categories/batchUpdate'
  const PATH138 = '/reservation_settings'
  const PATH139 = '/sales_promotions'
  const PATH140 = '/sales_promotions/batchUpdate'
  const PATH141 = '/shops'
  const PATH142 = '/accounts_receivables'
  const PATH143 = '/claims'
  const PATH144 = '/extra_working_times'
  const PATH145 = '/getCheckinQrCode'
  const PATH146 = '/holidays'
  const PATH147 = '/reservation_groups/batchUpdate'
  const PATH148 = '/resources/rooms/batchUpdate'
  const PATH149 = '/shop_resource_blocks'
  const PATH150 = '/staff_shift_schedule_rules'
  const PATH151 = '/staff_shift_schedule_settings'
  const PATH152 = '/staff_shift_schedules'
  const PATH153 = '/staffs/all'
  const PATH154 = '/staffs/batchUpdate'
  const PATH155 = '/supports'
  const PATH156 = '/working_times'
  const PATH157 = '/shops/batchUpdate'
  const PATH158 = '/signin'
  const PATH159 = '/staff_roles'
  const PATH160 = '/staff_roles/batchUpdate'
  const PATH161 = '/staffs'
  const PATH162 = '/acceptPasswordChange'
  const PATH163 = '/staffs/me'
  const PATH164 = '/template_files'
  const PATH165 = '/tenant_assets'
  const PATH166 = '/visitation_statuses'
  const PATH167 = '/visitation_statuses/batchUpdate'
  const PATH168 = '/voucher_document_settings'
  const PATH169 = '/web_reservation_areas'
  const PATH170 = '/web_reservation_areas/batchUpdate'
  const PATH171 = '/web_reservation_enquetes'
  const PATH172 = '/web_reservation_menus/batchUpdate'
  const PATH173 = '/web_reservation_settings'
  const PATH174 = '/web_reservation_texts'
  const PATH175 = '/api/v2/tenants'
  const PATH176 = '/createDocument'
  const PATH177 = '/fixToContract'
  const PATH178 = '/reservation_contents'
  const PATH179 = '/execCancel'
  const PATH180 = '/ex/line/fetchCustomer'
  const PATH181 = '/ex/reservation_candidate_dates'
  const PATH182 = '/ex/reservation_candidate_times'
  const PATH183 = '/ex/reservation_groups'
  const PATH184 = '/ex/web_reservation_areas'
  const PATH185 = '/ex/web_reservation_menus'
  const PATH186 = '/ex/web_reservation_shops'
  const PATH187 = '/room_usages'
  const PATH188 = '/room_usages/batchUpdate'
  const PATH189 = '/checkReservableShopResources'
  const PATH190 = '/reservation_groups/list'
  const PATH191 = '/resources/machines'
  const PATH192 = '/resources/rooms'
  const PATH193 = '/searchReservationCandidateDates'
  const PATH194 = '/staff_groups'
  const PATH195 = '/staff_groups/batchUpdate'
  const PATH196 = '/web_reservation_customer_info_items'
  const PATH197 = '/web_reservation_menus'
  const GET = 'GET'
  const POST = 'POST'
  const PUT = 'PUT'
  const DELETE = 'DELETE'
  const PATCH = 'PATCH'

  return {
    api: {
      v1: {
        banks: {
          _bank_code: (val3: string) => {
            const prefix3 = `${PATH0}/${val3}`

            return {
              branches: {
                _branch_code: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH1}/${val5}`

                  return {
                    get: (option?: { query?: Methods3['get']['query'], config?: T }) =>
                      fetch<Methods3['get']['resBody'], BasicHeaders, Methods3['get']['status']>(prefix, prefix5, GET, option).json(),
                    $get: (option?: { query?: Methods3['get']['query'], config?: T }) =>
                      fetch<Methods3['get']['resBody'], BasicHeaders, Methods3['get']['status']>(prefix, prefix5, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods3['get']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                get: (option?: { query?: Methods2['get']['query'], config?: T }) =>
                  fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, `${prefix3}${PATH1}`, GET, option).json(),
                $get: (option?: { query?: Methods2['get']['query'], config?: T }) =>
                  fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, `${prefix3}${PATH1}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods2['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH1}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              get: (option?: { query?: Methods1['get']['query'], config?: T }) =>
                fetch<Methods1['get']['resBody'], BasicHeaders, Methods1['get']['status']>(prefix, prefix3, GET, option).json(),
              $get: (option?: { query?: Methods1['get']['query'], config?: T }) =>
                fetch<Methods1['get']['resBody'], BasicHeaders, Methods1['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get'; query: Methods1['get']['query'] }) =>
                `${prefix}${prefix3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
            }
          },
          get: (option?: { query?: Methods0['get']['query'], config?: T }) =>
            fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json(),
          $get: (option?: { query?: Methods0['get']['query'], config?: T }) =>
            fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get'; query: Methods0['get']['query'] }) =>
            `${prefix}${PATH0}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        payment_settings: {
          get: (option?: { config?: T }) =>
            fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH2, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH2, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH2}`
        },
        postal_code: {
          _postal_code: (val3: string) => {
            const prefix3 = `${PATH3}/${val3}`

            return {
              get: (option?: { config?: T }) =>
                fetch<Methods5['get']['resBody'], BasicHeaders, Methods5['get']['status']>(prefix, prefix3, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods5['get']['resBody'], BasicHeaders, Methods5['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`
            }
          }
        },
        preset_machines: {
          get: (option?: { query?: Methods6['get']['query'], config?: T }) =>
            fetch<Methods6['get']['resBody'], BasicHeaders, Methods6['get']['status']>(prefix, PATH4, GET, option).json(),
          $get: (option?: { query?: Methods6['get']['query'], config?: T }) =>
            fetch<Methods6['get']['resBody'], BasicHeaders, Methods6['get']['status']>(prefix, PATH4, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get'; query: Methods6['get']['query'] }) =>
            `${prefix}${PATH4}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        resolveTenant: {
          get: (option: { query: Methods7['get']['query'], config?: T }) =>
            fetch<Methods7['get']['resBody'], BasicHeaders, Methods7['get']['status']>(prefix, PATH5, GET, option).json(),
          $get: (option: { query: Methods7['get']['query'], config?: T }) =>
            fetch<Methods7['get']['resBody'], BasicHeaders, Methods7['get']['status']>(prefix, PATH5, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get'; query: Methods7['get']['query'] }) =>
            `${prefix}${PATH5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        tenant_route_hosts: {
          get: (option?: { config?: T }) =>
            fetch<Methods8['get']['resBody'], BasicHeaders, Methods8['get']['status']>(prefix, PATH6, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods8['get']['resBody'], BasicHeaders, Methods8['get']['status']>(prefix, PATH6, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH6}`
        },
        tenants: {
          _tenant_name: (val3: string) => {
            const prefix3 = `${PATH7}/${val3}`

            return {
              assets: {
                upload: {
                  post: (option: { body: Methods11['post']['reqBody'], config?: T }) =>
                    fetch<Methods11['post']['resBody'], BasicHeaders, Methods11['post']['status']>(prefix, `${prefix3}${PATH8}`, POST, option, 'FormData').json(),
                  $post: (option: { body: Methods11['post']['reqBody'], config?: T }) =>
                    fetch<Methods11['post']['resBody'], BasicHeaders, Methods11['post']['status']>(prefix, `${prefix3}${PATH8}`, POST, option, 'FormData').json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH8}`
                },
                uploadPrivate: {
                  post: (option: { body: Methods12['post']['reqBody'], config?: T }) =>
                    fetch<Methods12['post']['resBody'], BasicHeaders, Methods12['post']['status']>(prefix, `${prefix3}${PATH9}`, POST, option, 'FormData').json(),
                  $post: (option: { body: Methods12['post']['reqBody'], config?: T }) =>
                    fetch<Methods12['post']['resBody'], BasicHeaders, Methods12['post']['status']>(prefix, `${prefix3}${PATH9}`, POST, option, 'FormData').json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH9}`
                }
              },
              background_jobs: {
                _background_job_id: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH10}/${val5}`

                  return {
                    get: (option?: { config?: T }) =>
                      fetch<Methods13['get']['resBody'], BasicHeaders, Methods13['get']['status']>(prefix, prefix5, GET, option).json(),
                    $get: (option?: { config?: T }) =>
                      fetch<Methods13['get']['resBody'], BasicHeaders, Methods13['get']['status']>(prefix, prefix5, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix5}`
                  }
                }
              },
              changeStaffPassword: {
                post: (option: { body: Methods14['post']['reqBody'], config?: T }) =>
                  fetch<Methods14['post']['resBody'], BasicHeaders, Methods14['post']['status']>(prefix, `${prefix3}${PATH11}`, POST, option).json(),
                $post: (option: { body: Methods14['post']['reqBody'], config?: T }) =>
                  fetch<Methods14['post']['resBody'], BasicHeaders, Methods14['post']['status']>(prefix, `${prefix3}${PATH11}`, POST, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH11}`
              },
              claim_categories: {
                _claim_category_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH12}/${val5}`

                  return {
                    delete: (option: { query: Methods16['delete']['query'], config?: T }) =>
                      fetch<Methods16['delete']['resBody'], BasicHeaders, Methods16['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods16['delete']['query'], config?: T }) =>
                      fetch<Methods16['delete']['resBody'], BasicHeaders, Methods16['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods16['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                batchUpdate: {
                  post: (option: { body: Methods17['post']['reqBody'], config?: T }) =>
                    fetch<Methods17['post']['resBody'], BasicHeaders, Methods17['post']['status']>(prefix, `${prefix3}${PATH13}`, POST, option).json(),
                  $post: (option: { body: Methods17['post']['reqBody'], config?: T }) =>
                    fetch<Methods17['post']['resBody'], BasicHeaders, Methods17['post']['status']>(prefix, `${prefix3}${PATH13}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH13}`
                },
                get: (option: { query: Methods15['get']['query'], config?: T }) =>
                  fetch<Methods15['get']['resBody'], BasicHeaders, Methods15['get']['status']>(prefix, `${prefix3}${PATH12}`, GET, option).json(),
                $get: (option: { query: Methods15['get']['query'], config?: T }) =>
                  fetch<Methods15['get']['resBody'], BasicHeaders, Methods15['get']['status']>(prefix, `${prefix3}${PATH12}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods15['put']['reqBody'], config?: T }) =>
                  fetch<Methods15['put']['resBody'], BasicHeaders, Methods15['put']['status']>(prefix, `${prefix3}${PATH12}`, PUT, option).json(),
                $put: (option: { body: Methods15['put']['reqBody'], config?: T }) =>
                  fetch<Methods15['put']['resBody'], BasicHeaders, Methods15['put']['status']>(prefix, `${prefix3}${PATH12}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods15['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH12}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              communication_categories: {
                _communication_category_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH14}/${val5}`

                  return {
                    delete: (option: { query: Methods19['delete']['query'], config?: T }) =>
                      fetch<Methods19['delete']['resBody'], BasicHeaders, Methods19['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods19['delete']['query'], config?: T }) =>
                      fetch<Methods19['delete']['resBody'], BasicHeaders, Methods19['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods19['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                batchUpdate: {
                  post: (option: { body: Methods20['post']['reqBody'], config?: T }) =>
                    fetch<Methods20['post']['resBody'], BasicHeaders, Methods20['post']['status']>(prefix, `${prefix3}${PATH15}`, POST, option).json(),
                  $post: (option: { body: Methods20['post']['reqBody'], config?: T }) =>
                    fetch<Methods20['post']['resBody'], BasicHeaders, Methods20['post']['status']>(prefix, `${prefix3}${PATH15}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH15}`
                },
                get: (option: { query: Methods18['get']['query'], config?: T }) =>
                  fetch<Methods18['get']['resBody'], BasicHeaders, Methods18['get']['status']>(prefix, `${prefix3}${PATH14}`, GET, option).json(),
                $get: (option: { query: Methods18['get']['query'], config?: T }) =>
                  fetch<Methods18['get']['resBody'], BasicHeaders, Methods18['get']['status']>(prefix, `${prefix3}${PATH14}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods18['put']['reqBody'], config?: T }) =>
                  fetch<Methods18['put']['resBody'], BasicHeaders, Methods18['put']['status']>(prefix, `${prefix3}${PATH14}`, PUT, option).json(),
                $put: (option: { body: Methods18['put']['reqBody'], config?: T }) =>
                  fetch<Methods18['put']['resBody'], BasicHeaders, Methods18['put']['status']>(prefix, `${prefix3}${PATH14}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods18['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH14}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              customer_remarks: {
                _customer_remark_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH16}/${val5}`

                  return {
                    delete: (option: { query: Methods22['delete']['query'], config?: T }) =>
                      fetch<Methods22['delete']['resBody'], BasicHeaders, Methods22['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods22['delete']['query'], config?: T }) =>
                      fetch<Methods22['delete']['resBody'], BasicHeaders, Methods22['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods22['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                batchUpdate: {
                  post: (option: { body: Methods23['post']['reqBody'], config?: T }) =>
                    fetch<Methods23['post']['resBody'], BasicHeaders, Methods23['post']['status']>(prefix, `${prefix3}${PATH17}`, POST, option).json(),
                  $post: (option: { body: Methods23['post']['reqBody'], config?: T }) =>
                    fetch<Methods23['post']['resBody'], BasicHeaders, Methods23['post']['status']>(prefix, `${prefix3}${PATH17}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH17}`
                },
                get: (option: { query: Methods21['get']['query'], config?: T }) =>
                  fetch<Methods21['get']['resBody'], BasicHeaders, Methods21['get']['status']>(prefix, `${prefix3}${PATH16}`, GET, option).json(),
                $get: (option: { query: Methods21['get']['query'], config?: T }) =>
                  fetch<Methods21['get']['resBody'], BasicHeaders, Methods21['get']['status']>(prefix, `${prefix3}${PATH16}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods21['put']['reqBody'], config?: T }) =>
                  fetch<Methods21['put']['resBody'], BasicHeaders, Methods21['put']['status']>(prefix, `${prefix3}${PATH16}`, PUT, option).json(),
                $put: (option: { body: Methods21['put']['reqBody'], config?: T }) =>
                  fetch<Methods21['put']['resBody'], BasicHeaders, Methods21['put']['status']>(prefix, `${prefix3}${PATH16}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods21['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH16}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              customers: {
                _customer_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH18}/${val5}`

                  return {
                    changeShop: {
                      post: (option: { body: Methods26['post']['reqBody'], config?: T }) =>
                        fetch<Methods26['post']['resBody'], BasicHeaders, Methods26['post']['status']>(prefix, `${prefix5}${PATH19}`, POST, option).json(),
                      $post: (option: { body: Methods26['post']['reqBody'], config?: T }) =>
                        fetch<Methods26['post']['resBody'], BasicHeaders, Methods26['post']['status']>(prefix, `${prefix5}${PATH19}`, POST, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH19}`
                    },
                    communication_histories: {
                      get: (option?: { query?: Methods27['get']['query'], config?: T }) =>
                        fetch<Methods27['get']['resBody'], BasicHeaders, Methods27['get']['status']>(prefix, `${prefix5}${PATH20}`, GET, option).json(),
                      $get: (option?: { query?: Methods27['get']['query'], config?: T }) =>
                        fetch<Methods27['get']['resBody'], BasicHeaders, Methods27['get']['status']>(prefix, `${prefix5}${PATH20}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods27['get']['query'] }) =>
                        `${prefix}${prefix5}${PATH20}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    communications: {
                      _communication_id: (val7: number) => {
                        const prefix7 = `${prefix5}${PATH21}/${val7}`

                        return {
                          get: (option?: { config?: T }) =>
                            fetch<Methods29['get']['resBody'], BasicHeaders, Methods29['get']['status']>(prefix, prefix7, GET, option).json(),
                          $get: (option?: { config?: T }) =>
                            fetch<Methods29['get']['resBody'], BasicHeaders, Methods29['get']['status']>(prefix, prefix7, GET, option).json().then(r => r.body),
                          delete: (option: { query: Methods29['delete']['query'], config?: T }) =>
                            fetch<Methods29['delete']['resBody'], BasicHeaders, Methods29['delete']['status']>(prefix, prefix7, DELETE, option).json(),
                          $delete: (option: { query: Methods29['delete']['query'], config?: T }) =>
                            fetch<Methods29['delete']['resBody'], BasicHeaders, Methods29['delete']['status']>(prefix, prefix7, DELETE, option).json().then(r => r.body),
                          $path: (option?: { method: 'delete'; query: Methods29['delete']['query'] }) =>
                            `${prefix}${prefix7}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                        }
                      },
                      put: (option: { body: Methods28['put']['reqBody'], config?: T }) =>
                        fetch<Methods28['put']['resBody'], BasicHeaders, Methods28['put']['status']>(prefix, `${prefix5}${PATH21}`, PUT, option).json(),
                      $put: (option: { body: Methods28['put']['reqBody'], config?: T }) =>
                        fetch<Methods28['put']['resBody'], BasicHeaders, Methods28['put']['status']>(prefix, `${prefix5}${PATH21}`, PUT, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH21}`
                    },
                    contract_details: {
                      getSummary: {
                        get: (option?: { query?: Methods31['get']['query'], config?: T }) =>
                          fetch<Methods31['get']['resBody'], BasicHeaders, Methods31['get']['status']>(prefix, `${prefix5}${PATH23}`, GET, option).json(),
                        $get: (option?: { query?: Methods31['get']['query'], config?: T }) =>
                          fetch<Methods31['get']['resBody'], BasicHeaders, Methods31['get']['status']>(prefix, `${prefix5}${PATH23}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods31['get']['query'] }) =>
                          `${prefix}${prefix5}${PATH23}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      },
                      get: (option?: { query?: Methods30['get']['query'], config?: T }) =>
                        fetch<Methods30['get']['resBody'], BasicHeaders, Methods30['get']['status']>(prefix, `${prefix5}${PATH22}`, GET, option).json(),
                      $get: (option?: { query?: Methods30['get']['query'], config?: T }) =>
                        fetch<Methods30['get']['resBody'], BasicHeaders, Methods30['get']['status']>(prefix, `${prefix5}${PATH22}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods30['get']['query'] }) =>
                        `${prefix}${prefix5}${PATH22}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    contracts: {
                      _contract_id: (val7: number) => {
                        const prefix7 = `${prefix5}${PATH24}/${val7}`

                        return {
                          contract_details: {
                            _contract_detail_id: (val9: number) => {
                              const prefix9 = `${prefix7}${PATH22}/${val9}`

                              return {
                                contract_penalty: {
                                  patch: (option: { body: Methods34['patch']['reqBody'], config?: T }) =>
                                    fetch<Methods34['patch']['resBody'], BasicHeaders, Methods34['patch']['status']>(prefix, `${prefix9}${PATH25}`, PATCH, option).json(),
                                  $patch: (option: { body: Methods34['patch']['reqBody'], config?: T }) =>
                                    fetch<Methods34['patch']['resBody'], BasicHeaders, Methods34['patch']['status']>(prefix, `${prefix9}${PATH25}`, PATCH, option).json().then(r => r.body),
                                  delete: (option: { query: Methods34['delete']['query'], config?: T }) =>
                                    fetch<Methods34['delete']['resBody'], BasicHeaders, Methods34['delete']['status']>(prefix, `${prefix9}${PATH25}`, DELETE, option).json(),
                                  $delete: (option: { query: Methods34['delete']['query'], config?: T }) =>
                                    fetch<Methods34['delete']['resBody'], BasicHeaders, Methods34['delete']['status']>(prefix, `${prefix9}${PATH25}`, DELETE, option).json().then(r => r.body),
                                  $path: (option?: { method: 'delete'; query: Methods34['delete']['query'] }) =>
                                    `${prefix}${prefix9}${PATH25}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                                },
                                executeCancel: {
                                  post: (option: { body: Methods35['post']['reqBody'], config?: T }) =>
                                    fetch<Methods35['post']['resBody'], BasicHeaders, Methods35['post']['status']>(prefix, `${prefix9}${PATH26}`, POST, option).json(),
                                  $post: (option: { body: Methods35['post']['reqBody'], config?: T }) =>
                                    fetch<Methods35['post']['resBody'], BasicHeaders, Methods35['post']['status']>(prefix, `${prefix9}${PATH26}`, POST, option).json().then(r => r.body),
                                  $path: () => `${prefix}${prefix9}${PATH26}`
                                }
                              }
                            }
                          },
                          contract_payments: {
                            _contract_payment_id: (val9: number) => {
                              const prefix9 = `${prefix7}${PATH27}/${val9}`

                              return {
                                cancelOnlinePayment: {
                                  post: (option?: { config?: T }) =>
                                    fetch<Methods37['post']['resBody'], BasicHeaders, Methods37['post']['status']>(prefix, `${prefix9}${PATH28}`, POST, option).json(),
                                  $post: (option?: { config?: T }) =>
                                    fetch<Methods37['post']['resBody'], BasicHeaders, Methods37['post']['status']>(prefix, `${prefix9}${PATH28}`, POST, option).json().then(r => r.body),
                                  $path: () => `${prefix}${prefix9}${PATH28}`
                                },
                                publishOnlinePaymentUrl: {
                                  post: (option?: { config?: T }) =>
                                    fetch<Methods38['post']['resBody'], BasicHeaders, Methods38['post']['status']>(prefix, `${prefix9}${PATH29}`, POST, option).json(),
                                  $post: (option?: { config?: T }) =>
                                    fetch<Methods38['post']['resBody'], BasicHeaders, Methods38['post']['status']>(prefix, `${prefix9}${PATH29}`, POST, option).json().then(r => r.body),
                                  $path: () => `${prefix}${prefix9}${PATH29}`
                                }
                              }
                            },
                            put: (option: { body: Methods36['put']['reqBody'], config?: T }) =>
                              fetch<Methods36['put']['resBody'], BasicHeaders, Methods36['put']['status']>(prefix, `${prefix7}${PATH27}`, PUT, option).json(),
                            $put: (option: { body: Methods36['put']['reqBody'], config?: T }) =>
                              fetch<Methods36['put']['resBody'], BasicHeaders, Methods36['put']['status']>(prefix, `${prefix7}${PATH27}`, PUT, option).json().then(r => r.body),
                            $path: () => `${prefix}${prefix7}${PATH27}`
                          },
                          contract_penalty: {
                            patch: (option: { body: Methods39['patch']['reqBody'], config?: T }) =>
                              fetch<Methods39['patch']['resBody'], BasicHeaders, Methods39['patch']['status']>(prefix, `${prefix7}${PATH25}`, PATCH, option).json(),
                            $patch: (option: { body: Methods39['patch']['reqBody'], config?: T }) =>
                              fetch<Methods39['patch']['resBody'], BasicHeaders, Methods39['patch']['status']>(prefix, `${prefix7}${PATH25}`, PATCH, option).json().then(r => r.body),
                            delete: (option: { query: Methods39['delete']['query'], config?: T }) =>
                              fetch<Methods39['delete']['resBody'], BasicHeaders, Methods39['delete']['status']>(prefix, `${prefix7}${PATH25}`, DELETE, option).json(),
                            $delete: (option: { query: Methods39['delete']['query'], config?: T }) =>
                              fetch<Methods39['delete']['resBody'], BasicHeaders, Methods39['delete']['status']>(prefix, `${prefix7}${PATH25}`, DELETE, option).json().then(r => r.body),
                            $path: (option?: { method: 'delete'; query: Methods39['delete']['query'] }) =>
                              `${prefix}${prefix7}${PATH25}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                          },
                          executeCancel: {
                            post: (option: { body: Methods40['post']['reqBody'], config?: T }) =>
                              fetch<Methods40['post']['resBody'], BasicHeaders, Methods40['post']['status']>(prefix, `${prefix7}${PATH26}`, POST, option).json(),
                            $post: (option: { body: Methods40['post']['reqBody'], config?: T }) =>
                              fetch<Methods40['post']['resBody'], BasicHeaders, Methods40['post']['status']>(prefix, `${prefix7}${PATH26}`, POST, option).json().then(r => r.body),
                            $path: () => `${prefix}${prefix7}${PATH26}`
                          },
                          get: (option?: { config?: T }) =>
                            fetch<Methods33['get']['resBody'], BasicHeaders, Methods33['get']['status']>(prefix, prefix7, GET, option).json(),
                          $get: (option?: { config?: T }) =>
                            fetch<Methods33['get']['resBody'], BasicHeaders, Methods33['get']['status']>(prefix, prefix7, GET, option).json().then(r => r.body),
                          $path: () => `${prefix}${prefix7}`
                        }
                      },
                      all: {
                        get: (option?: { query?: Methods41['get']['query'], config?: T }) =>
                          fetch<Methods41['get']['resBody'], BasicHeaders, Methods41['get']['status']>(prefix, `${prefix5}${PATH30}`, GET, option).json(),
                        $get: (option?: { query?: Methods41['get']['query'], config?: T }) =>
                          fetch<Methods41['get']['resBody'], BasicHeaders, Methods41['get']['status']>(prefix, `${prefix5}${PATH30}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods41['get']['query'] }) =>
                          `${prefix}${prefix5}${PATH30}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      },
                      products: {
                        get: (option?: { query?: Methods42['get']['query'], config?: T }) =>
                          fetch<Methods42['get']['resBody'], BasicHeaders, Methods42['get']['status']>(prefix, `${prefix5}${PATH31}`, GET, option).json(),
                        $get: (option?: { query?: Methods42['get']['query'], config?: T }) =>
                          fetch<Methods42['get']['resBody'], BasicHeaders, Methods42['get']['status']>(prefix, `${prefix5}${PATH31}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods42['get']['query'] }) =>
                          `${prefix}${prefix5}${PATH31}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      },
                      get: (option?: { query?: Methods32['get']['query'], config?: T }) =>
                        fetch<Methods32['get']['resBody'], BasicHeaders, Methods32['get']['status']>(prefix, `${prefix5}${PATH24}`, GET, option).json(),
                      $get: (option?: { query?: Methods32['get']['query'], config?: T }) =>
                        fetch<Methods32['get']['resBody'], BasicHeaders, Methods32['get']['status']>(prefix, `${prefix5}${PATH24}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods32['get']['query'] }) =>
                        `${prefix}${prefix5}${PATH24}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    counselings: {
                      _counseling_id: (val7: number) => {
                        const prefix7 = `${prefix5}${PATH32}/${val7}`

                        return {
                          get: (option?: { config?: T }) =>
                            fetch<Methods44['get']['resBody'], BasicHeaders, Methods44['get']['status']>(prefix, prefix7, GET, option).json(),
                          $get: (option?: { config?: T }) =>
                            fetch<Methods44['get']['resBody'], BasicHeaders, Methods44['get']['status']>(prefix, prefix7, GET, option).json().then(r => r.body),
                          delete: (option?: { config?: T }) =>
                            fetch<Methods44['delete']['resBody'], BasicHeaders, Methods44['delete']['status']>(prefix, prefix7, DELETE, option).json(),
                          $delete: (option?: { config?: T }) =>
                            fetch<Methods44['delete']['resBody'], BasicHeaders, Methods44['delete']['status']>(prefix, prefix7, DELETE, option).json().then(r => r.body),
                          $path: () => `${prefix}${prefix7}`
                        }
                      },
                      get: (option?: { config?: T }) =>
                        fetch<Methods43['get']['resBody'], BasicHeaders, Methods43['get']['status']>(prefix, `${prefix5}${PATH32}`, GET, option).json(),
                      $get: (option?: { config?: T }) =>
                        fetch<Methods43['get']['resBody'], BasicHeaders, Methods43['get']['status']>(prefix, `${prefix5}${PATH32}`, GET, option).json().then(r => r.body),
                      put: (option: { body: Methods43['put']['reqBody'], config?: T }) =>
                        fetch<Methods43['put']['resBody'], BasicHeaders, Methods43['put']['status']>(prefix, `${prefix5}${PATH32}`, PUT, option).json(),
                      $put: (option: { body: Methods43['put']['reqBody'], config?: T }) =>
                        fetch<Methods43['put']['resBody'], BasicHeaders, Methods43['put']['status']>(prefix, `${prefix5}${PATH32}`, PUT, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH32}`
                    },
                    detail: {
                      put: (option: { body: Methods45['put']['reqBody'], config?: T }) =>
                        fetch<Methods45['put']['resBody'], BasicHeaders, Methods45['put']['status']>(prefix, `${prefix5}${PATH33}`, PUT, option).json(),
                      $put: (option: { body: Methods45['put']['reqBody'], config?: T }) =>
                        fetch<Methods45['put']['resBody'], BasicHeaders, Methods45['put']['status']>(prefix, `${prefix5}${PATH33}`, PUT, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH33}`
                    },
                    documents: {
                      _document_id: (val7: number) => {
                        const prefix7 = `${prefix5}${PATH34}/${val7}`

                        return {
                          delete: (option?: { config?: T }) =>
                            fetch<Methods47['delete']['resBody'], BasicHeaders, Methods47['delete']['status']>(prefix, prefix7, DELETE, option).json(),
                          $delete: (option?: { config?: T }) =>
                            fetch<Methods47['delete']['resBody'], BasicHeaders, Methods47['delete']['status']>(prefix, prefix7, DELETE, option).json().then(r => r.body),
                          $path: () => `${prefix}${prefix7}`
                        }
                      },
                      createSnapshot: {
                        post: (option: { body: Methods48['post']['reqBody'], config?: T }) =>
                          fetch<Methods48['post']['resBody'], BasicHeaders, Methods48['post']['status']>(prefix, `${prefix5}${PATH35}`, POST, option).json(),
                        $post: (option: { body: Methods48['post']['reqBody'], config?: T }) =>
                          fetch<Methods48['post']['resBody'], BasicHeaders, Methods48['post']['status']>(prefix, `${prefix5}${PATH35}`, POST, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix5}${PATH35}`
                      },
                      get: (option?: { config?: T }) =>
                        fetch<Methods46['get']['resBody'], BasicHeaders, Methods46['get']['status']>(prefix, `${prefix5}${PATH34}`, GET, option).json(),
                      $get: (option?: { config?: T }) =>
                        fetch<Methods46['get']['resBody'], BasicHeaders, Methods46['get']['status']>(prefix, `${prefix5}${PATH34}`, GET, option).json().then(r => r.body),
                      post: (option: { body: Methods46['post']['reqBody'], config?: T }) =>
                        fetch<Methods46['post']['resBody'], BasicHeaders, Methods46['post']['status']>(prefix, `${prefix5}${PATH34}`, POST, option).json(),
                      $post: (option: { body: Methods46['post']['reqBody'], config?: T }) =>
                        fetch<Methods46['post']['resBody'], BasicHeaders, Methods46['post']['status']>(prefix, `${prefix5}${PATH34}`, POST, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH34}`
                    },
                    ekartes: {
                      _ekarte_id: (val7: number) => {
                        const prefix7 = `${prefix5}${PATH36}/${val7}`

                        return {
                          assets: {
                            put: (option: { body: Methods51['put']['reqBody'], config?: T }) =>
                              fetch<Methods51['put']['resBody'], BasicHeaders, Methods51['put']['status']>(prefix, `${prefix7}${PATH37}`, PUT, option).json(),
                            $put: (option: { body: Methods51['put']['reqBody'], config?: T }) =>
                              fetch<Methods51['put']['resBody'], BasicHeaders, Methods51['put']['status']>(prefix, `${prefix7}${PATH37}`, PUT, option).json().then(r => r.body),
                            $path: () => `${prefix}${prefix7}${PATH37}`
                          },
                          delete: (option: { query: Methods50['delete']['query'], config?: T }) =>
                            fetch<Methods50['delete']['resBody'], BasicHeaders, Methods50['delete']['status']>(prefix, prefix7, DELETE, option).json(),
                          $delete: (option: { query: Methods50['delete']['query'], config?: T }) =>
                            fetch<Methods50['delete']['resBody'], BasicHeaders, Methods50['delete']['status']>(prefix, prefix7, DELETE, option).json().then(r => r.body),
                          $path: (option?: { method: 'delete'; query: Methods50['delete']['query'] }) =>
                            `${prefix}${prefix7}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                        }
                      },
                      asset_labels: {
                        get: (option?: { config?: T }) =>
                          fetch<Methods52['get']['resBody'], BasicHeaders, Methods52['get']['status']>(prefix, `${prefix5}${PATH38}`, GET, option).json(),
                        $get: (option?: { config?: T }) =>
                          fetch<Methods52['get']['resBody'], BasicHeaders, Methods52['get']['status']>(prefix, `${prefix5}${PATH38}`, GET, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix5}${PATH38}`
                      },
                      assets: {
                        _ekarte_asset_id: (val8: number) => {
                          const prefix8 = `${prefix5}${PATH39}/${val8}`

                          return {
                            patch: (option: { body: Methods54['patch']['reqBody'], config?: T }) =>
                              fetch<Methods54['patch']['resBody'], BasicHeaders, Methods54['patch']['status']>(prefix, prefix8, PATCH, option).json(),
                            $patch: (option: { body: Methods54['patch']['reqBody'], config?: T }) =>
                              fetch<Methods54['patch']['resBody'], BasicHeaders, Methods54['patch']['status']>(prefix, prefix8, PATCH, option).json().then(r => r.body),
                            $path: () => `${prefix}${prefix8}`
                          }
                        },
                        get: (option?: { query?: Methods53['get']['query'], config?: T }) =>
                          fetch<Methods53['get']['resBody'], BasicHeaders, Methods53['get']['status']>(prefix, `${prefix5}${PATH39}`, GET, option).json(),
                        $get: (option?: { query?: Methods53['get']['query'], config?: T }) =>
                          fetch<Methods53['get']['resBody'], BasicHeaders, Methods53['get']['status']>(prefix, `${prefix5}${PATH39}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods53['get']['query'] }) =>
                          `${prefix}${prefix5}${PATH39}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      },
                      createSnapshot: {
                        post: (option: { body: Methods55['post']['reqBody'], config?: T }) =>
                          fetch<Methods55['post']['resBody'], BasicHeaders, Methods55['post']['status']>(prefix, `${prefix5}${PATH40}`, POST, option).json(),
                        $post: (option: { body: Methods55['post']['reqBody'], config?: T }) =>
                          fetch<Methods55['post']['resBody'], BasicHeaders, Methods55['post']['status']>(prefix, `${prefix5}${PATH40}`, POST, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix5}${PATH40}`
                      },
                      get: (option: { query: Methods49['get']['query'], config?: T }) =>
                        fetch<Methods49['get']['resBody'], BasicHeaders, Methods49['get']['status']>(prefix, `${prefix5}${PATH36}`, GET, option).json(),
                      $get: (option: { query: Methods49['get']['query'], config?: T }) =>
                        fetch<Methods49['get']['resBody'], BasicHeaders, Methods49['get']['status']>(prefix, `${prefix5}${PATH36}`, GET, option).json().then(r => r.body),
                      put: (option: { body: Methods49['put']['reqBody'], config?: T }) =>
                        fetch<Methods49['put']['resBody'], BasicHeaders, Methods49['put']['status']>(prefix, `${prefix5}${PATH36}`, PUT, option).json(),
                      $put: (option: { body: Methods49['put']['reqBody'], config?: T }) =>
                        fetch<Methods49['put']['resBody'], BasicHeaders, Methods49['put']['status']>(prefix, `${prefix5}${PATH36}`, PUT, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods49['get']['query'] }) =>
                        `${prefix}${prefix5}${PATH36}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    inflow: {
                      put: (option: { body: Methods56['put']['reqBody'], config?: T }) =>
                        fetch<Methods56['put']['resBody'], BasicHeaders, Methods56['put']['status']>(prefix, `${prefix5}${PATH41}`, PUT, option).json(),
                      $put: (option: { body: Methods56['put']['reqBody'], config?: T }) =>
                        fetch<Methods56['put']['resBody'], BasicHeaders, Methods56['put']['status']>(prefix, `${prefix5}${PATH41}`, PUT, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH41}`
                    },
                    interview_answers: {
                      _interview_answer_id: (val7: number) => {
                        const prefix7 = `${prefix5}${PATH42}/${val7}`

                        return {
                          get: (option?: { config?: T }) =>
                            fetch<Methods58['get']['resBody'], BasicHeaders, Methods58['get']['status']>(prefix, prefix7, GET, option).json(),
                          $get: (option?: { config?: T }) =>
                            fetch<Methods58['get']['resBody'], BasicHeaders, Methods58['get']['status']>(prefix, prefix7, GET, option).json().then(r => r.body),
                          $path: () => `${prefix}${prefix7}`
                        }
                      },
                      get: (option?: { config?: T }) =>
                        fetch<Methods57['get']['resBody'], BasicHeaders, Methods57['get']['status']>(prefix, `${prefix5}${PATH42}`, GET, option).json(),
                      $get: (option?: { config?: T }) =>
                        fetch<Methods57['get']['resBody'], BasicHeaders, Methods57['get']['status']>(prefix, `${prefix5}${PATH42}`, GET, option).json().then(r => r.body),
                      put: (option: { body: Methods57['put']['reqBody'], config?: T }) =>
                        fetch<Methods57['put']['resBody'], BasicHeaders, Methods57['put']['status']>(prefix, `${prefix5}${PATH42}`, PUT, option).json(),
                      $put: (option: { body: Methods57['put']['reqBody'], config?: T }) =>
                        fetch<Methods57['put']['resBody'], BasicHeaders, Methods57['put']['status']>(prefix, `${prefix5}${PATH42}`, PUT, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH42}`
                    },
                    relevant_documents: {
                      _relevant_document_id: (val7: number) => {
                        const prefix7 = `${prefix5}${PATH43}/${val7}`

                        return {
                          delete: (option?: { config?: T }) =>
                            fetch<Methods60['delete']['resBody'], BasicHeaders, Methods60['delete']['status']>(prefix, prefix7, DELETE, option).json(),
                          $delete: (option?: { config?: T }) =>
                            fetch<Methods60['delete']['resBody'], BasicHeaders, Methods60['delete']['status']>(prefix, prefix7, DELETE, option).json().then(r => r.body),
                          $path: () => `${prefix}${prefix7}`
                        }
                      },
                      get: (option?: { config?: T }) =>
                        fetch<Methods59['get']['resBody'], BasicHeaders, Methods59['get']['status']>(prefix, `${prefix5}${PATH43}`, GET, option).json(),
                      $get: (option?: { config?: T }) =>
                        fetch<Methods59['get']['resBody'], BasicHeaders, Methods59['get']['status']>(prefix, `${prefix5}${PATH43}`, GET, option).json().then(r => r.body),
                      post: (option: { body: Methods59['post']['reqBody'], config?: T }) =>
                        fetch<Methods59['post']['resBody'], BasicHeaders, Methods59['post']['status']>(prefix, `${prefix5}${PATH43}`, POST, option).json(),
                      $post: (option: { body: Methods59['post']['reqBody'], config?: T }) =>
                        fetch<Methods59['post']['resBody'], BasicHeaders, Methods59['post']['status']>(prefix, `${prefix5}${PATH43}`, POST, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH43}`
                    },
                    revertDeletion: {
                      post: (option: { query: Methods61['post']['query'], config?: T }) =>
                        fetch<Methods61['post']['resBody'], BasicHeaders, Methods61['post']['status']>(prefix, `${prefix5}${PATH44}`, POST, option).json(),
                      $post: (option: { query: Methods61['post']['query'], config?: T }) =>
                        fetch<Methods61['post']['resBody'], BasicHeaders, Methods61['post']['status']>(prefix, `${prefix5}${PATH44}`, POST, option).json().then(r => r.body),
                      $path: (option?: { method: 'post'; query: Methods61['post']['query'] }) =>
                        `${prefix}${prefix5}${PATH44}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    sendMessage: {
                      post: (option: { body: Methods62['post']['reqBody'], config?: T }) =>
                        fetch<Methods62['post']['resBody'], BasicHeaders, Methods62['post']['status']>(prefix, `${prefix5}${PATH45}`, POST, option).json(),
                      $post: (option: { body: Methods62['post']['reqBody'], config?: T }) =>
                        fetch<Methods62['post']['resBody'], BasicHeaders, Methods62['post']['status']>(prefix, `${prefix5}${PATH45}`, POST, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH45}`
                    },
                    shop_change_histories: {
                      get: (option?: { config?: T }) =>
                        fetch<Methods63['get']['resBody'], BasicHeaders, Methods63['get']['status']>(prefix, `${prefix5}${PATH46}`, GET, option).json(),
                      $get: (option?: { config?: T }) =>
                        fetch<Methods63['get']['resBody'], BasicHeaders, Methods63['get']['status']>(prefix, `${prefix5}${PATH46}`, GET, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH46}`
                    },
                    shops: {
                      staffs: {
                        get: (option?: { config?: T }) =>
                          fetch<Methods64['get']['resBody'], BasicHeaders, Methods64['get']['status']>(prefix, `${prefix5}${PATH47}`, GET, option).json(),
                        $get: (option?: { config?: T }) =>
                          fetch<Methods64['get']['resBody'], BasicHeaders, Methods64['get']['status']>(prefix, `${prefix5}${PATH47}`, GET, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix5}${PATH47}`
                      }
                    },
                    summary: {
                      get: (option?: { config?: T }) =>
                        fetch<Methods65['get']['resBody'], BasicHeaders, Methods65['get']['status']>(prefix, `${prefix5}${PATH48}`, GET, option).json(),
                      $get: (option?: { config?: T }) =>
                        fetch<Methods65['get']['resBody'], BasicHeaders, Methods65['get']['status']>(prefix, `${prefix5}${PATH48}`, GET, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH48}`
                    },
                    treatment_histories: {
                      _treatment_history_id: (val7: number) => {
                        const prefix7 = `${prefix5}${PATH49}/${val7}`

                        return {
                          get: (option?: { config?: T }) =>
                            fetch<Methods67['get']['resBody'], BasicHeaders, Methods67['get']['status']>(prefix, prefix7, GET, option).json(),
                          $get: (option?: { config?: T }) =>
                            fetch<Methods67['get']['resBody'], BasicHeaders, Methods67['get']['status']>(prefix, prefix7, GET, option).json().then(r => r.body),
                          delete: (option?: { config?: T }) =>
                            fetch<Methods67['delete']['resBody'], BasicHeaders, Methods67['delete']['status']>(prefix, prefix7, DELETE, option).json(),
                          $delete: (option?: { config?: T }) =>
                            fetch<Methods67['delete']['resBody'], BasicHeaders, Methods67['delete']['status']>(prefix, prefix7, DELETE, option).json().then(r => r.body),
                          $path: () => `${prefix}${prefix7}`
                        }
                      },
                      get: (option?: { query?: Methods66['get']['query'], config?: T }) =>
                        fetch<Methods66['get']['resBody'], BasicHeaders, Methods66['get']['status']>(prefix, `${prefix5}${PATH49}`, GET, option).json(),
                      $get: (option?: { query?: Methods66['get']['query'], config?: T }) =>
                        fetch<Methods66['get']['resBody'], BasicHeaders, Methods66['get']['status']>(prefix, `${prefix5}${PATH49}`, GET, option).json().then(r => r.body),
                      put: (option: { body: Methods66['put']['reqBody'], config?: T }) =>
                        fetch<Methods66['put']['resBody'], BasicHeaders, Methods66['put']['status']>(prefix, `${prefix5}${PATH49}`, PUT, option).json(),
                      $put: (option: { body: Methods66['put']['reqBody'], config?: T }) =>
                        fetch<Methods66['put']['resBody'], BasicHeaders, Methods66['put']['status']>(prefix, `${prefix5}${PATH49}`, PUT, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods66['get']['query'] }) =>
                        `${prefix}${prefix5}${PATH49}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { config?: T }) =>
                      fetch<Methods25['get']['resBody'], BasicHeaders, Methods25['get']['status']>(prefix, prefix5, GET, option).json(),
                    $get: (option?: { config?: T }) =>
                      fetch<Methods25['get']['resBody'], BasicHeaders, Methods25['get']['status']>(prefix, prefix5, GET, option).json().then(r => r.body),
                    delete: (option: { query: Methods25['delete']['query'], config?: T }) =>
                      fetch<Methods25['delete']['resBody'], BasicHeaders, Methods25['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods25['delete']['query'], config?: T }) =>
                      fetch<Methods25['delete']['resBody'], BasicHeaders, Methods25['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods25['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                reduced: {
                  get: (option?: { query?: Methods68['get']['query'], config?: T }) =>
                    fetch<Methods68['get']['resBody'], BasicHeaders, Methods68['get']['status']>(prefix, `${prefix3}${PATH50}`, GET, option).json(),
                  $get: (option?: { query?: Methods68['get']['query'], config?: T }) =>
                    fetch<Methods68['get']['resBody'], BasicHeaders, Methods68['get']['status']>(prefix, `${prefix3}${PATH50}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods68['get']['query'] }) =>
                    `${prefix}${prefix3}${PATH50}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                get: (option?: { query?: Methods24['get']['query'], config?: T }) =>
                  fetch<Methods24['get']['resBody'], BasicHeaders, Methods24['get']['status']>(prefix, `${prefix3}${PATH18}`, GET, option).json(),
                $get: (option?: { query?: Methods24['get']['query'], config?: T }) =>
                  fetch<Methods24['get']['resBody'], BasicHeaders, Methods24['get']['status']>(prefix, `${prefix3}${PATH18}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods24['put']['reqBody'], config?: T }) =>
                  fetch<Methods24['put']['resBody'], BasicHeaders, Methods24['put']['status']>(prefix, `${prefix3}${PATH18}`, PUT, option).json(),
                $put: (option: { body: Methods24['put']['reqBody'], config?: T }) =>
                  fetch<Methods24['put']['resBody'], BasicHeaders, Methods24['put']['status']>(prefix, `${prefix3}${PATH18}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods24['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH18}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              digital_ticket_menus: {
                get: (option?: { config?: T }) =>
                  fetch<Methods69['get']['resBody'], BasicHeaders, Methods69['get']['status']>(prefix, `${prefix3}${PATH51}`, GET, option).json(),
                $get: (option?: { config?: T }) =>
                  fetch<Methods69['get']['resBody'], BasicHeaders, Methods69['get']['status']>(prefix, `${prefix3}${PATH51}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods69['put']['reqBody'], config?: T }) =>
                  fetch<Methods69['put']['resBody'], BasicHeaders, Methods69['put']['status']>(prefix, `${prefix3}${PATH51}`, PUT, option).json(),
                $put: (option: { body: Methods69['put']['reqBody'], config?: T }) =>
                  fetch<Methods69['put']['resBody'], BasicHeaders, Methods69['put']['status']>(prefix, `${prefix3}${PATH51}`, PUT, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH51}`
              },
              digital_ticket_settings: {
                get: (option?: { config?: T }) =>
                  fetch<Methods70['get']['resBody'], BasicHeaders, Methods70['get']['status']>(prefix, `${prefix3}${PATH52}`, GET, option).json(),
                $get: (option?: { config?: T }) =>
                  fetch<Methods70['get']['resBody'], BasicHeaders, Methods70['get']['status']>(prefix, `${prefix3}${PATH52}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods70['put']['reqBody'], config?: T }) =>
                  fetch<Methods70['put']['resBody'], BasicHeaders, Methods70['put']['status']>(prefix, `${prefix3}${PATH52}`, PUT, option).json(),
                $put: (option: { body: Methods70['put']['reqBody'], config?: T }) =>
                  fetch<Methods70['put']['resBody'], BasicHeaders, Methods70['put']['status']>(prefix, `${prefix3}${PATH52}`, PUT, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH52}`
              },
              document_templates: {
                _document_template_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH53}/${val5}`

                  return {
                    delete: (option: { query: Methods72['delete']['query'], config?: T }) =>
                      fetch<Methods72['delete']['resBody'], BasicHeaders, Methods72['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods72['delete']['query'], config?: T }) =>
                      fetch<Methods72['delete']['resBody'], BasicHeaders, Methods72['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods72['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                batchUpdate: {
                  post: (option: { body: Methods73['post']['reqBody'], config?: T }) =>
                    fetch<Methods73['post']['resBody'], BasicHeaders, Methods73['post']['status']>(prefix, `${prefix3}${PATH54}`, POST, option).json(),
                  $post: (option: { body: Methods73['post']['reqBody'], config?: T }) =>
                    fetch<Methods73['post']['resBody'], BasicHeaders, Methods73['post']['status']>(prefix, `${prefix3}${PATH54}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH54}`
                },
                get: (option?: { query?: Methods71['get']['query'], config?: T }) =>
                  fetch<Methods71['get']['resBody'], BasicHeaders, Methods71['get']['status']>(prefix, `${prefix3}${PATH53}`, GET, option).json(),
                $get: (option?: { query?: Methods71['get']['query'], config?: T }) =>
                  fetch<Methods71['get']['resBody'], BasicHeaders, Methods71['get']['status']>(prefix, `${prefix3}${PATH53}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods71['put']['reqBody'], config?: T }) =>
                  fetch<Methods71['put']['resBody'], BasicHeaders, Methods71['put']['status']>(prefix, `${prefix3}${PATH53}`, PUT, option).json(),
                $put: (option: { body: Methods71['put']['reqBody'], config?: T }) =>
                  fetch<Methods71['put']['resBody'], BasicHeaders, Methods71['put']['status']>(prefix, `${prefix3}${PATH53}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods71['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH53}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              ekarte_templates: {
                _ekarte_template_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH55}/${val5}`

                  return {
                    delete: (option: { query: Methods75['delete']['query'], config?: T }) =>
                      fetch<Methods75['delete']['resBody'], BasicHeaders, Methods75['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods75['delete']['query'], config?: T }) =>
                      fetch<Methods75['delete']['resBody'], BasicHeaders, Methods75['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    patch: (option: { body: Methods75['patch']['reqBody'], config?: T }) =>
                      fetch<Methods75['patch']['resBody'], BasicHeaders, Methods75['patch']['status']>(prefix, prefix5, PATCH, option).json(),
                    $patch: (option: { body: Methods75['patch']['reqBody'], config?: T }) =>
                      fetch<Methods75['patch']['resBody'], BasicHeaders, Methods75['patch']['status']>(prefix, prefix5, PATCH, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods75['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                batchUpdate: {
                  post: (option: { body: Methods76['post']['reqBody'], config?: T }) =>
                    fetch<Methods76['post']['resBody'], BasicHeaders, Methods76['post']['status']>(prefix, `${prefix3}${PATH56}`, POST, option).json(),
                  $post: (option: { body: Methods76['post']['reqBody'], config?: T }) =>
                    fetch<Methods76['post']['resBody'], BasicHeaders, Methods76['post']['status']>(prefix, `${prefix3}${PATH56}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH56}`
                },
                get: (option: { query: Methods74['get']['query'], config?: T }) =>
                  fetch<Methods74['get']['resBody'], BasicHeaders, Methods74['get']['status']>(prefix, `${prefix3}${PATH55}`, GET, option).json(),
                $get: (option: { query: Methods74['get']['query'], config?: T }) =>
                  fetch<Methods74['get']['resBody'], BasicHeaders, Methods74['get']['status']>(prefix, `${prefix3}${PATH55}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods74['put']['reqBody'], config?: T }) =>
                  fetch<Methods74['put']['resBody'], BasicHeaders, Methods74['put']['status']>(prefix, `${prefix3}${PATH55}`, PUT, option).json(),
                $put: (option: { body: Methods74['put']['reqBody'], config?: T }) =>
                  fetch<Methods74['put']['resBody'], BasicHeaders, Methods74['put']['status']>(prefix, `${prefix3}${PATH55}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods74['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH55}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              enquetes: {
                _enquete_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH57}/${val5}`

                  return {
                    get: (option?: { config?: T }) =>
                      fetch<Methods78['get']['resBody'], BasicHeaders, Methods78['get']['status']>(prefix, prefix5, GET, option).json(),
                    $get: (option?: { config?: T }) =>
                      fetch<Methods78['get']['resBody'], BasicHeaders, Methods78['get']['status']>(prefix, prefix5, GET, option).json().then(r => r.body),
                    delete: (option: { query: Methods78['delete']['query'], config?: T }) =>
                      fetch<Methods78['delete']['resBody'], BasicHeaders, Methods78['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods78['delete']['query'], config?: T }) =>
                      fetch<Methods78['delete']['resBody'], BasicHeaders, Methods78['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods78['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                all: {
                  get: (option?: { query?: Methods79['get']['query'], config?: T }) =>
                    fetch<Methods79['get']['resBody'], BasicHeaders, Methods79['get']['status']>(prefix, `${prefix3}${PATH58}`, GET, option).json(),
                  $get: (option?: { query?: Methods79['get']['query'], config?: T }) =>
                    fetch<Methods79['get']['resBody'], BasicHeaders, Methods79['get']['status']>(prefix, `${prefix3}${PATH58}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods79['get']['query'] }) =>
                    `${prefix}${prefix3}${PATH58}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                get: (option?: { query?: Methods77['get']['query'], config?: T }) =>
                  fetch<Methods77['get']['resBody'], BasicHeaders, Methods77['get']['status']>(prefix, `${prefix3}${PATH57}`, GET, option).json(),
                $get: (option?: { query?: Methods77['get']['query'], config?: T }) =>
                  fetch<Methods77['get']['resBody'], BasicHeaders, Methods77['get']['status']>(prefix, `${prefix3}${PATH57}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods77['put']['reqBody'], config?: T }) =>
                  fetch<Methods77['put']['resBody'], BasicHeaders, Methods77['put']['status']>(prefix, `${prefix3}${PATH57}`, PUT, option).json(),
                $put: (option: { body: Methods77['put']['reqBody'], config?: T }) =>
                  fetch<Methods77['put']['resBody'], BasicHeaders, Methods77['put']['status']>(prefix, `${prefix3}${PATH57}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods77['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH57}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              ex: {
                checkIn: {
                  post: (option: { body: Methods80['post']['reqBody'], config?: T }) =>
                    fetch<Methods80['post']['resBody'], BasicHeaders, Methods80['post']['status']>(prefix, `${prefix3}${PATH59}`, POST, option).json(),
                  $post: (option: { body: Methods80['post']['reqBody'], config?: T }) =>
                    fetch<Methods80['post']['resBody'], BasicHeaders, Methods80['post']['status']>(prefix, `${prefix3}${PATH59}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH59}`
                },
                confirmChangeEmail: {
                  get: (option: { query: Methods81['get']['query'], config?: T }) =>
                    fetch<Methods81['get']['resBody'], BasicHeaders, Methods81['get']['status']>(prefix, `${prefix3}${PATH60}`, GET, option).json(),
                  $get: (option: { query: Methods81['get']['query'], config?: T }) =>
                    fetch<Methods81['get']['resBody'], BasicHeaders, Methods81['get']['status']>(prefix, `${prefix3}${PATH60}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods81['get']['query'] }) =>
                    `${prefix}${prefix3}${PATH60}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                customers: {
                  _customer_id_number: (val6: number) => {
                    const prefix6 = `${prefix3}${PATH61}/${val6}`

                    return {
                      contracts: {
                        get: (option?: { query?: Methods83['get']['query'], config?: T }) =>
                          fetch<Methods83['get']['resBody'], BasicHeaders, Methods83['get']['status']>(prefix, `${prefix6}${PATH24}`, GET, option).json(),
                        $get: (option?: { query?: Methods83['get']['query'], config?: T }) =>
                          fetch<Methods83['get']['resBody'], BasicHeaders, Methods83['get']['status']>(prefix, `${prefix6}${PATH24}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods83['get']['query'] }) =>
                          `${prefix}${prefix6}${PATH24}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      },
                      documents: {
                        get: (option?: { query?: Methods84['get']['query'], config?: T }) =>
                          fetch<Methods84['get']['resBody'], BasicHeaders, Methods84['get']['status']>(prefix, `${prefix6}${PATH34}`, GET, option).json(),
                        $get: (option?: { query?: Methods84['get']['query'], config?: T }) =>
                          fetch<Methods84['get']['resBody'], BasicHeaders, Methods84['get']['status']>(prefix, `${prefix6}${PATH34}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods84['get']['query'] }) =>
                          `${prefix}${prefix6}${PATH34}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      },
                      online_payment_user: {
                        patch: (option: { body: Methods85['patch']['reqBody'], config?: T }) =>
                          fetch<Methods85['patch']['resBody'], BasicHeaders, Methods85['patch']['status']>(prefix, `${prefix6}${PATH62}`, PATCH, option).json(),
                        $patch: (option: { body: Methods85['patch']['reqBody'], config?: T }) =>
                          fetch<Methods85['patch']['resBody'], BasicHeaders, Methods85['patch']['status']>(prefix, `${prefix6}${PATH62}`, PATCH, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix6}${PATH62}`
                      },
                      reservation_groups: {
                        get: (option?: { config?: T }) =>
                          fetch<Methods86['get']['resBody'], BasicHeaders, Methods86['get']['status']>(prefix, `${prefix6}${PATH63}`, GET, option).json(),
                        $get: (option?: { config?: T }) =>
                          fetch<Methods86['get']['resBody'], BasicHeaders, Methods86['get']['status']>(prefix, `${prefix6}${PATH63}`, GET, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix6}${PATH63}`
                      },
                      patch: (option: { body: Methods82['patch']['reqBody'], config?: T }) =>
                        fetch<Methods82['patch']['resBody'], BasicHeaders, Methods82['patch']['status']>(prefix, prefix6, PATCH, option).json(),
                      $patch: (option: { body: Methods82['patch']['reqBody'], config?: T }) =>
                        fetch<Methods82['patch']['resBody'], BasicHeaders, Methods82['patch']['status']>(prefix, prefix6, PATCH, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix6}`
                    }
                  },
                  _customer_id_string: (val6: string) => {
                    const prefix6 = `${prefix3}${PATH61}/${val6}`

                    return {
                      contracts: {
                        _contract_associate_id: (val8: string) => {
                          const prefix8 = `${prefix6}${PATH24}/${val8}`

                          return {
                            get: (option?: { config?: T }) =>
                              fetch<Methods87['get']['resBody'], BasicHeaders, Methods87['get']['status']>(prefix, prefix8, GET, option).json(),
                            $get: (option?: { config?: T }) =>
                              fetch<Methods87['get']['resBody'], BasicHeaders, Methods87['get']['status']>(prefix, prefix8, GET, option).json().then(r => r.body),
                            $path: () => `${prefix}${prefix8}`
                          }
                        }
                      },
                      disconnectLineId: {
                        post: (option?: { config?: T }) =>
                          fetch<Methods88['post']['resBody'], BasicHeaders, Methods88['post']['status']>(prefix, `${prefix6}${PATH64}`, POST, option).json(),
                        $post: (option?: { config?: T }) =>
                          fetch<Methods88['post']['resBody'], BasicHeaders, Methods88['post']['status']>(prefix, `${prefix6}${PATH64}`, POST, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix6}${PATH64}`
                      }
                    }
                  },
                  confirmConnectLineId: {
                    post: (option: { body: Methods89['post']['reqBody'], config?: T }) =>
                      fetch<Methods89['post']['resBody'], BasicHeaders, Methods89['post']['status']>(prefix, `${prefix3}${PATH65}`, POST, option).json(),
                    $post: (option: { body: Methods89['post']['reqBody'], config?: T }) =>
                      fetch<Methods89['post']['resBody'], BasicHeaders, Methods89['post']['status']>(prefix, `${prefix3}${PATH65}`, POST, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix3}${PATH65}`
                  },
                  connectLineId: {
                    post: (option: { body: Methods90['post']['reqBody'], config?: T }) =>
                      fetch<Methods90['post']['resBody'], BasicHeaders, Methods90['post']['status']>(prefix, `${prefix3}${PATH66}`, POST, option).json(),
                    $post: (option: { body: Methods90['post']['reqBody'], config?: T }) =>
                      fetch<Methods90['post']['resBody'], BasicHeaders, Methods90['post']['status']>(prefix, `${prefix3}${PATH66}`, POST, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix3}${PATH66}`
                  },
                  verifyLineIdToken: {
                    post: (option: { body: Methods91['post']['reqBody'], config?: T }) =>
                      fetch<Methods91['post']['resBody'], BasicHeaders, Methods91['post']['status']>(prefix, `${prefix3}${PATH67}`, POST, option).json(),
                    $post: (option: { body: Methods91['post']['reqBody'], config?: T }) =>
                      fetch<Methods91['post']['resBody'], BasicHeaders, Methods91['post']['status']>(prefix, `${prefix3}${PATH67}`, POST, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix3}${PATH67}`
                  }
                },
                enquetes: {
                  _enquete_id: (val6: number) => {
                    const prefix6 = `${prefix3}${PATH68}/${val6}`

                    return {
                      get: (option: { query: Methods92['get']['query'], config?: T }) =>
                        fetch<Methods92['get']['resBody'], BasicHeaders, Methods92['get']['status']>(prefix, prefix6, GET, option).json(),
                      $get: (option: { query: Methods92['get']['query'], config?: T }) =>
                        fetch<Methods92['get']['resBody'], BasicHeaders, Methods92['get']['status']>(prefix, prefix6, GET, option).json().then(r => r.body),
                      post: (option: { body: Methods92['post']['reqBody'], config?: T }) =>
                        fetch<Methods92['post']['resBody'], BasicHeaders, Methods92['post']['status']>(prefix, prefix6, POST, option).json(),
                      $post: (option: { body: Methods92['post']['reqBody'], config?: T }) =>
                        fetch<Methods92['post']['resBody'], BasicHeaders, Methods92['post']['status']>(prefix, prefix6, POST, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods92['get']['query'] }) =>
                        `${prefix}${prefix6}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    }
                  }
                },
                interview_answers: {
                  post: (option: { body: Methods93['post']['reqBody'], config?: T }) =>
                    fetch<Methods93['post']['resBody'], BasicHeaders, Methods93['post']['status']>(prefix, `${prefix3}${PATH69}`, POST, option).json(),
                  $post: (option: { body: Methods93['post']['reqBody'], config?: T }) =>
                    fetch<Methods93['post']['resBody'], BasicHeaders, Methods93['post']['status']>(prefix, `${prefix3}${PATH69}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH69}`
                },
                interviews: {
                  get: (option: { query: Methods94['get']['query'], config?: T }) =>
                    fetch<Methods94['get']['resBody'], BasicHeaders, Methods94['get']['status']>(prefix, `${prefix3}${PATH70}`, GET, option).json(),
                  $get: (option: { query: Methods94['get']['query'], config?: T }) =>
                    fetch<Methods94['get']['resBody'], BasicHeaders, Methods94['get']['status']>(prefix, `${prefix3}${PATH70}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods94['get']['query'] }) =>
                    `${prefix}${prefix3}${PATH70}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                online_payment: {
                  get: (option?: { query?: Methods95['get']['query'], config?: T }) =>
                    fetch<Methods95['get']['resBody'], BasicHeaders, Methods95['get']['status']>(prefix, `${prefix3}${PATH71}`, GET, option).json(),
                  $get: (option?: { query?: Methods95['get']['query'], config?: T }) =>
                    fetch<Methods95['get']['resBody'], BasicHeaders, Methods95['get']['status']>(prefix, `${prefix3}${PATH71}`, GET, option).json().then(r => r.body),
                  post: (option: { body: Methods95['post']['reqBody'], config?: T }) =>
                    fetch<Methods95['post']['resBody'], BasicHeaders, Methods95['post']['status']>(prefix, `${prefix3}${PATH71}`, POST, option).json(),
                  $post: (option: { body: Methods95['post']['reqBody'], config?: T }) =>
                    fetch<Methods95['post']['resBody'], BasicHeaders, Methods95['post']['status']>(prefix, `${prefix3}${PATH71}`, POST, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods95['get']['query'] }) =>
                    `${prefix}${prefix3}${PATH71}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                web_reservation_settings: {
                  get: (option?: { query?: Methods96['get']['query'], config?: T }) =>
                    fetch<Methods96['get']['resBody'], BasicHeaders, Methods96['get']['status']>(prefix, `${prefix3}${PATH72}`, GET, option).json(),
                  $get: (option?: { query?: Methods96['get']['query'], config?: T }) =>
                    fetch<Methods96['get']['resBody'], BasicHeaders, Methods96['get']['status']>(prefix, `${prefix3}${PATH72}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods96['get']['query'] }) =>
                    `${prefix}${prefix3}${PATH72}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                }
              },
              getRelevantDocumentNames: {
                get: (option?: { config?: T }) =>
                  fetch<Methods97['get']['resBody'], BasicHeaders, Methods97['get']['status']>(prefix, `${prefix3}${PATH73}`, GET, option).json(),
                $get: (option?: { config?: T }) =>
                  fetch<Methods97['get']['resBody'], BasicHeaders, Methods97['get']['status']>(prefix, `${prefix3}${PATH73}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH73}`
              },
              getSigninInfo: {
                get: (option?: { config?: T }) =>
                  fetch<Methods98['get']['resBody'], BasicHeaders, Methods98['get']['status']>(prefix, `${prefix3}${PATH74}`, GET, option).json(),
                $get: (option?: { config?: T }) =>
                  fetch<Methods98['get']['resBody'], BasicHeaders, Methods98['get']['status']>(prefix, `${prefix3}${PATH74}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH74}`
              },
              inflow_routes: {
                _inflow_route_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH75}/${val5}`

                  return {
                    delete: (option: { query: Methods100['delete']['query'], config?: T }) =>
                      fetch<Methods100['delete']['resBody'], BasicHeaders, Methods100['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods100['delete']['query'], config?: T }) =>
                      fetch<Methods100['delete']['resBody'], BasicHeaders, Methods100['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods100['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                batchUpdate: {
                  post: (option: { body: Methods101['post']['reqBody'], config?: T }) =>
                    fetch<Methods101['post']['resBody'], BasicHeaders, Methods101['post']['status']>(prefix, `${prefix3}${PATH76}`, POST, option).json(),
                  $post: (option: { body: Methods101['post']['reqBody'], config?: T }) =>
                    fetch<Methods101['post']['resBody'], BasicHeaders, Methods101['post']['status']>(prefix, `${prefix3}${PATH76}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH76}`
                },
                get: (option: { query: Methods99['get']['query'], config?: T }) =>
                  fetch<Methods99['get']['resBody'], BasicHeaders, Methods99['get']['status']>(prefix, `${prefix3}${PATH75}`, GET, option).json(),
                $get: (option: { query: Methods99['get']['query'], config?: T }) =>
                  fetch<Methods99['get']['resBody'], BasicHeaders, Methods99['get']['status']>(prefix, `${prefix3}${PATH75}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods99['put']['reqBody'], config?: T }) =>
                  fetch<Methods99['put']['resBody'], BasicHeaders, Methods99['put']['status']>(prefix, `${prefix3}${PATH75}`, PUT, option).json(),
                $put: (option: { body: Methods99['put']['reqBody'], config?: T }) =>
                  fetch<Methods99['put']['resBody'], BasicHeaders, Methods99['put']['status']>(prefix, `${prefix3}${PATH75}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods99['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH75}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              interviews: {
                get: (option?: { config?: T }) =>
                  fetch<Methods102['get']['resBody'], BasicHeaders, Methods102['get']['status']>(prefix, `${prefix3}${PATH77}`, GET, option).json(),
                $get: (option?: { config?: T }) =>
                  fetch<Methods102['get']['resBody'], BasicHeaders, Methods102['get']['status']>(prefix, `${prefix3}${PATH77}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods102['put']['reqBody'], config?: T }) =>
                  fetch<Methods102['put']['resBody'], BasicHeaders, Methods102['put']['status']>(prefix, `${prefix3}${PATH77}`, PUT, option).json(),
                $put: (option: { body: Methods102['put']['reqBody'], config?: T }) =>
                  fetch<Methods102['put']['resBody'], BasicHeaders, Methods102['put']['status']>(prefix, `${prefix3}${PATH77}`, PUT, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH77}`
              },
              logo_images: {
                get: (option?: { config?: T }) =>
                  fetch<Methods103['get']['resBody'], BasicHeaders, Methods103['get']['status']>(prefix, `${prefix3}${PATH78}`, GET, option).json(),
                $get: (option?: { config?: T }) =>
                  fetch<Methods103['get']['resBody'], BasicHeaders, Methods103['get']['status']>(prefix, `${prefix3}${PATH78}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods103['put']['reqBody'], config?: T }) =>
                  fetch<Methods103['put']['resBody'], BasicHeaders, Methods103['put']['status']>(prefix, `${prefix3}${PATH78}`, PUT, option).json(),
                $put: (option: { body: Methods103['put']['reqBody'], config?: T }) =>
                  fetch<Methods103['put']['resBody'], BasicHeaders, Methods103['put']['status']>(prefix, `${prefix3}${PATH78}`, PUT, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH78}`
              },
              machines: {
                _machine_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH79}/${val5}`

                  return {
                    delete: (option: { query: Methods105['delete']['query'], config?: T }) =>
                      fetch<Methods105['delete']['resBody'], BasicHeaders, Methods105['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods105['delete']['query'], config?: T }) =>
                      fetch<Methods105['delete']['resBody'], BasicHeaders, Methods105['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods105['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                batchUpdate: {
                  post: (option: { body: Methods106['post']['reqBody'], config?: T }) =>
                    fetch<Methods106['post']['resBody'], BasicHeaders, Methods106['post']['status']>(prefix, `${prefix3}${PATH80}`, POST, option).json(),
                  $post: (option: { body: Methods106['post']['reqBody'], config?: T }) =>
                    fetch<Methods106['post']['resBody'], BasicHeaders, Methods106['post']['status']>(prefix, `${prefix3}${PATH80}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH80}`
                },
                get: (option: { query: Methods104['get']['query'], config?: T }) =>
                  fetch<Methods104['get']['resBody'], BasicHeaders, Methods104['get']['status']>(prefix, `${prefix3}${PATH79}`, GET, option).json(),
                $get: (option: { query: Methods104['get']['query'], config?: T }) =>
                  fetch<Methods104['get']['resBody'], BasicHeaders, Methods104['get']['status']>(prefix, `${prefix3}${PATH79}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods104['put']['reqBody'], config?: T }) =>
                  fetch<Methods104['put']['resBody'], BasicHeaders, Methods104['put']['status']>(prefix, `${prefix3}${PATH79}`, PUT, option).json(),
                $put: (option: { body: Methods104['put']['reqBody'], config?: T }) =>
                  fetch<Methods104['put']['resBody'], BasicHeaders, Methods104['put']['status']>(prefix, `${prefix3}${PATH79}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods104['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH79}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              message_settings: {
                _message_setting_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH81}/${val5}`

                  return {
                    getTargetCustomers: {
                      get: (option?: { query?: Methods109['get']['query'], config?: T }) =>
                        fetch<Methods109['get']['resBody'], BasicHeaders, Methods109['get']['status']>(prefix, `${prefix5}${PATH82}`, GET, option).json(),
                      $get: (option?: { query?: Methods109['get']['query'], config?: T }) =>
                        fetch<Methods109['get']['resBody'], BasicHeaders, Methods109['get']['status']>(prefix, `${prefix5}${PATH82}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods109['get']['query'] }) =>
                        `${prefix}${prefix5}${PATH82}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { config?: T }) =>
                      fetch<Methods108['get']['resBody'], BasicHeaders, Methods108['get']['status']>(prefix, prefix5, GET, option).json(),
                    $get: (option?: { config?: T }) =>
                      fetch<Methods108['get']['resBody'], BasicHeaders, Methods108['get']['status']>(prefix, prefix5, GET, option).json().then(r => r.body),
                    delete: (option: { query: Methods108['delete']['query'], config?: T }) =>
                      fetch<Methods108['delete']['resBody'], BasicHeaders, Methods108['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods108['delete']['query'], config?: T }) =>
                      fetch<Methods108['delete']['resBody'], BasicHeaders, Methods108['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods108['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                sendMessages: {
                  post: (option: { body: Methods110['post']['reqBody'], config?: T }) =>
                    fetch<Methods110['post']['resBody'], BasicHeaders, Methods110['post']['status']>(prefix, `${prefix3}${PATH83}`, POST, option).json(),
                  $post: (option: { body: Methods110['post']['reqBody'], config?: T }) =>
                    fetch<Methods110['post']['resBody'], BasicHeaders, Methods110['post']['status']>(prefix, `${prefix3}${PATH83}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH83}`
                },
                get: (option?: { query?: Methods107['get']['query'], config?: T }) =>
                  fetch<Methods107['get']['resBody'], BasicHeaders, Methods107['get']['status']>(prefix, `${prefix3}${PATH81}`, GET, option).json(),
                $get: (option?: { query?: Methods107['get']['query'], config?: T }) =>
                  fetch<Methods107['get']['resBody'], BasicHeaders, Methods107['get']['status']>(prefix, `${prefix3}${PATH81}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods107['put']['reqBody'], config?: T }) =>
                  fetch<Methods107['put']['resBody'], BasicHeaders, Methods107['put']['status']>(prefix, `${prefix3}${PATH81}`, PUT, option).json(),
                $put: (option: { body: Methods107['put']['reqBody'], config?: T }) =>
                  fetch<Methods107['put']['resBody'], BasicHeaders, Methods107['put']['status']>(prefix, `${prefix3}${PATH81}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods107['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH81}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              message_templates: {
                _message_template_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH84}/${val5}`

                  return {
                    sendTestingMessage: {
                      post: (option: { body: Methods113['post']['reqBody'], config?: T }) =>
                        fetch<Methods113['post']['resBody'], BasicHeaders, Methods113['post']['status']>(prefix, `${prefix5}${PATH85}`, POST, option).json(),
                      $post: (option: { body: Methods113['post']['reqBody'], config?: T }) =>
                        fetch<Methods113['post']['resBody'], BasicHeaders, Methods113['post']['status']>(prefix, `${prefix5}${PATH85}`, POST, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH85}`
                    },
                    get: (option?: { config?: T }) =>
                      fetch<Methods112['get']['resBody'], BasicHeaders, Methods112['get']['status']>(prefix, prefix5, GET, option).json(),
                    $get: (option?: { config?: T }) =>
                      fetch<Methods112['get']['resBody'], BasicHeaders, Methods112['get']['status']>(prefix, prefix5, GET, option).json().then(r => r.body),
                    delete: (option: { query: Methods112['delete']['query'], config?: T }) =>
                      fetch<Methods112['delete']['resBody'], BasicHeaders, Methods112['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods112['delete']['query'], config?: T }) =>
                      fetch<Methods112['delete']['resBody'], BasicHeaders, Methods112['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods112['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                template_kind: {
                  _template_kind: (val6: number | string) => {
                    const prefix6 = `${prefix3}${PATH86}/${val6}`

                    return {
                      batchUpdate: {
                        post: (option: { body: Methods114['post']['reqBody'], config?: T }) =>
                          fetch<Methods114['post']['resBody'], BasicHeaders, Methods114['post']['status']>(prefix, `${prefix6}${PATH87}`, POST, option).json(),
                        $post: (option: { body: Methods114['post']['reqBody'], config?: T }) =>
                          fetch<Methods114['post']['resBody'], BasicHeaders, Methods114['post']['status']>(prefix, `${prefix6}${PATH87}`, POST, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix6}${PATH87}`
                      }
                    }
                  }
                },
                get: (option?: { query?: Methods111['get']['query'], config?: T }) =>
                  fetch<Methods111['get']['resBody'], BasicHeaders, Methods111['get']['status']>(prefix, `${prefix3}${PATH84}`, GET, option).json(),
                $get: (option?: { query?: Methods111['get']['query'], config?: T }) =>
                  fetch<Methods111['get']['resBody'], BasicHeaders, Methods111['get']['status']>(prefix, `${prefix3}${PATH84}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods111['put']['reqBody'], config?: T }) =>
                  fetch<Methods111['put']['resBody'], BasicHeaders, Methods111['put']['status']>(prefix, `${prefix3}${PATH84}`, PUT, option).json(),
                $put: (option: { body: Methods111['put']['reqBody'], config?: T }) =>
                  fetch<Methods111['put']['resBody'], BasicHeaders, Methods111['put']['status']>(prefix, `${prefix3}${PATH84}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods111['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH84}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              payment_settings: {
                get: (option?: { config?: T }) =>
                  fetch<Methods115['get']['resBody'], BasicHeaders, Methods115['get']['status']>(prefix, `${prefix3}${PATH88}`, GET, option).json(),
                $get: (option?: { config?: T }) =>
                  fetch<Methods115['get']['resBody'], BasicHeaders, Methods115['get']['status']>(prefix, `${prefix3}${PATH88}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods115['put']['reqBody'], config?: T }) =>
                  fetch<Methods115['put']['resBody'], BasicHeaders, Methods115['put']['status']>(prefix, `${prefix3}${PATH88}`, PUT, option).json(),
                $put: (option: { body: Methods115['put']['reqBody'], config?: T }) =>
                  fetch<Methods115['put']['resBody'], BasicHeaders, Methods115['put']['status']>(prefix, `${prefix3}${PATH88}`, PUT, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH88}`
              },
              product_categories: {
                _product_category_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH89}/${val5}`

                  return {
                    delete: (option: { query: Methods117['delete']['query'], config?: T }) =>
                      fetch<Methods117['delete']['resBody'], BasicHeaders, Methods117['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods117['delete']['query'], config?: T }) =>
                      fetch<Methods117['delete']['resBody'], BasicHeaders, Methods117['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods117['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                batchUpdate: {
                  post: (option: { body: Methods118['post']['reqBody'], config?: T }) =>
                    fetch<Methods118['post']['resBody'], BasicHeaders, Methods118['post']['status']>(prefix, `${prefix3}${PATH90}`, POST, option).json(),
                  $post: (option: { body: Methods118['post']['reqBody'], config?: T }) =>
                    fetch<Methods118['post']['resBody'], BasicHeaders, Methods118['post']['status']>(prefix, `${prefix3}${PATH90}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH90}`
                },
                get: (option: { query: Methods116['get']['query'], config?: T }) =>
                  fetch<Methods116['get']['resBody'], BasicHeaders, Methods116['get']['status']>(prefix, `${prefix3}${PATH89}`, GET, option).json(),
                $get: (option: { query: Methods116['get']['query'], config?: T }) =>
                  fetch<Methods116['get']['resBody'], BasicHeaders, Methods116['get']['status']>(prefix, `${prefix3}${PATH89}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods116['put']['reqBody'], config?: T }) =>
                  fetch<Methods116['put']['resBody'], BasicHeaders, Methods116['put']['status']>(prefix, `${prefix3}${PATH89}`, PUT, option).json(),
                $put: (option: { body: Methods116['put']['reqBody'], config?: T }) =>
                  fetch<Methods116['put']['resBody'], BasicHeaders, Methods116['put']['status']>(prefix, `${prefix3}${PATH89}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods116['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH89}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              products: {
                _product_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH91}/${val5}`

                  return {
                    get: (option?: { config?: T }) =>
                      fetch<Methods120['get']['resBody'], BasicHeaders, Methods120['get']['status']>(prefix, prefix5, GET, option).json(),
                    $get: (option?: { config?: T }) =>
                      fetch<Methods120['get']['resBody'], BasicHeaders, Methods120['get']['status']>(prefix, prefix5, GET, option).json().then(r => r.body),
                    delete: (option: { query: Methods120['delete']['query'], config?: T }) =>
                      fetch<Methods120['delete']['resBody'], BasicHeaders, Methods120['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods120['delete']['query'], config?: T }) =>
                      fetch<Methods120['delete']['resBody'], BasicHeaders, Methods120['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods120['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                batchUpdate: {
                  post: (option: { body: Methods121['post']['reqBody'], config?: T }) =>
                    fetch<Methods121['post']['resBody'], BasicHeaders, Methods121['post']['status']>(prefix, `${prefix3}${PATH92}`, POST, option).json(),
                  $post: (option: { body: Methods121['post']['reqBody'], config?: T }) =>
                    fetch<Methods121['post']['resBody'], BasicHeaders, Methods121['post']['status']>(prefix, `${prefix3}${PATH92}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH92}`
                },
                get: (option?: { query?: Methods119['get']['query'], config?: T }) =>
                  fetch<Methods119['get']['resBody'], BasicHeaders, Methods119['get']['status']>(prefix, `${prefix3}${PATH91}`, GET, option).json(),
                $get: (option?: { query?: Methods119['get']['query'], config?: T }) =>
                  fetch<Methods119['get']['resBody'], BasicHeaders, Methods119['get']['status']>(prefix, `${prefix3}${PATH91}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods119['put']['reqBody'], config?: T }) =>
                  fetch<Methods119['put']['resBody'], BasicHeaders, Methods119['put']['status']>(prefix, `${prefix3}${PATH91}`, PUT, option).json(),
                $put: (option: { body: Methods119['put']['reqBody'], config?: T }) =>
                  fetch<Methods119['put']['resBody'], BasicHeaders, Methods119['put']['status']>(prefix, `${prefix3}${PATH91}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods119['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH91}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              report: {
                business: {
                  daily: {
                    export: {
                      get: (option?: { query?: Methods123['get']['query'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods123['get']['status']>(prefix, `${prefix3}${PATH94}`, GET, option).send(),
                      $get: (option?: { query?: Methods123['get']['query'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods123['get']['status']>(prefix, `${prefix3}${PATH94}`, GET, option).send().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods123['get']['query'] }) =>
                        `${prefix}${prefix3}${PATH94}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { query?: Methods122['get']['query'], config?: T }) =>
                      fetch<Methods122['get']['resBody'], BasicHeaders, Methods122['get']['status']>(prefix, `${prefix3}${PATH93}`, GET, option).json(),
                    $get: (option?: { query?: Methods122['get']['query'], config?: T }) =>
                      fetch<Methods122['get']['resBody'], BasicHeaders, Methods122['get']['status']>(prefix, `${prefix3}${PATH93}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods122['get']['query'] }) =>
                      `${prefix}${prefix3}${PATH93}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  },
                  monthly: {
                    export: {
                      get: (option?: { query?: Methods125['get']['query'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods125['get']['status']>(prefix, `${prefix3}${PATH96}`, GET, option).send(),
                      $get: (option?: { query?: Methods125['get']['query'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods125['get']['status']>(prefix, `${prefix3}${PATH96}`, GET, option).send().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods125['get']['query'] }) =>
                        `${prefix}${prefix3}${PATH96}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { query?: Methods124['get']['query'], config?: T }) =>
                      fetch<Methods124['get']['resBody'], BasicHeaders, Methods124['get']['status']>(prefix, `${prefix3}${PATH95}`, GET, option).json(),
                    $get: (option?: { query?: Methods124['get']['query'], config?: T }) =>
                      fetch<Methods124['get']['resBody'], BasicHeaders, Methods124['get']['status']>(prefix, `${prefix3}${PATH95}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods124['get']['query'] }) =>
                      `${prefix}${prefix3}${PATH95}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  },
                  shop: {
                    export: {
                      get: (option?: { query?: Methods127['get']['query'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods127['get']['status']>(prefix, `${prefix3}${PATH98}`, GET, option).send(),
                      $get: (option?: { query?: Methods127['get']['query'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods127['get']['status']>(prefix, `${prefix3}${PATH98}`, GET, option).send().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods127['get']['query'] }) =>
                        `${prefix}${prefix3}${PATH98}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { query?: Methods126['get']['query'], config?: T }) =>
                      fetch<Methods126['get']['resBody'], BasicHeaders, Methods126['get']['status']>(prefix, `${prefix3}${PATH97}`, GET, option).json(),
                    $get: (option?: { query?: Methods126['get']['query'], config?: T }) =>
                      fetch<Methods126['get']['resBody'], BasicHeaders, Methods126['get']['status']>(prefix, `${prefix3}${PATH97}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods126['get']['query'] }) =>
                      `${prefix}${prefix3}${PATH97}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                contract: {
                  contract_penalties: {
                    export: {
                      get: (option?: { query?: Methods129['get']['query'], config?: T }) =>
                        fetch<Methods129['get']['resBody'], BasicHeaders, Methods129['get']['status']>(prefix, `${prefix3}${PATH100}`, GET, option).json(),
                      $get: (option?: { query?: Methods129['get']['query'], config?: T }) =>
                        fetch<Methods129['get']['resBody'], BasicHeaders, Methods129['get']['status']>(prefix, `${prefix3}${PATH100}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods129['get']['query'] }) =>
                        `${prefix}${prefix3}${PATH100}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { query?: Methods128['get']['query'], config?: T }) =>
                      fetch<Methods128['get']['resBody'], BasicHeaders, Methods128['get']['status']>(prefix, `${prefix3}${PATH99}`, GET, option).json(),
                    $get: (option?: { query?: Methods128['get']['query'], config?: T }) =>
                      fetch<Methods128['get']['resBody'], BasicHeaders, Methods128['get']['status']>(prefix, `${prefix3}${PATH99}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods128['get']['query'] }) =>
                      `${prefix}${prefix3}${PATH99}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  },
                  payment_amount_by_payment_method: {
                    export: {
                      get: (option?: { query?: Methods131['get']['query'], config?: T }) =>
                        fetch<Methods131['get']['resBody'], BasicHeaders, Methods131['get']['status']>(prefix, `${prefix3}${PATH102}`, GET, option).json(),
                      $get: (option?: { query?: Methods131['get']['query'], config?: T }) =>
                        fetch<Methods131['get']['resBody'], BasicHeaders, Methods131['get']['status']>(prefix, `${prefix3}${PATH102}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods131['get']['query'] }) =>
                        `${prefix}${prefix3}${PATH102}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { query?: Methods130['get']['query'], config?: T }) =>
                      fetch<Methods130['get']['resBody'], BasicHeaders, Methods130['get']['status']>(prefix, `${prefix3}${PATH101}`, GET, option).json(),
                    $get: (option?: { query?: Methods130['get']['query'], config?: T }) =>
                      fetch<Methods130['get']['resBody'], BasicHeaders, Methods130['get']['status']>(prefix, `${prefix3}${PATH101}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods130['get']['query'] }) =>
                      `${prefix}${prefix3}${PATH101}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  },
                  payment_items_by_payment_method: {
                    export: {
                      get: (option?: { query?: Methods133['get']['query'], config?: T }) =>
                        fetch<Methods133['get']['resBody'], BasicHeaders, Methods133['get']['status']>(prefix, `${prefix3}${PATH104}`, GET, option).json(),
                      $get: (option?: { query?: Methods133['get']['query'], config?: T }) =>
                        fetch<Methods133['get']['resBody'], BasicHeaders, Methods133['get']['status']>(prefix, `${prefix3}${PATH104}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods133['get']['query'] }) =>
                        `${prefix}${prefix3}${PATH104}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { query?: Methods132['get']['query'], config?: T }) =>
                      fetch<Methods132['get']['resBody'], BasicHeaders, Methods132['get']['status']>(prefix, `${prefix3}${PATH103}`, GET, option).json(),
                    $get: (option?: { query?: Methods132['get']['query'], config?: T }) =>
                      fetch<Methods132['get']['resBody'], BasicHeaders, Methods132['get']['status']>(prefix, `${prefix3}${PATH103}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods132['get']['query'] }) =>
                      `${prefix}${prefix3}${PATH103}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  },
                  treatment_histories: {
                    export: {
                      get: (option?: { query?: Methods135['get']['query'], config?: T }) =>
                        fetch<Methods135['get']['resBody'], BasicHeaders, Methods135['get']['status']>(prefix, `${prefix3}${PATH106}`, GET, option).json(),
                      $get: (option?: { query?: Methods135['get']['query'], config?: T }) =>
                        fetch<Methods135['get']['resBody'], BasicHeaders, Methods135['get']['status']>(prefix, `${prefix3}${PATH106}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods135['get']['query'] }) =>
                        `${prefix}${prefix3}${PATH106}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { query?: Methods134['get']['query'], config?: T }) =>
                      fetch<Methods134['get']['resBody'], BasicHeaders, Methods134['get']['status']>(prefix, `${prefix3}${PATH105}`, GET, option).json(),
                    $get: (option?: { query?: Methods134['get']['query'], config?: T }) =>
                      fetch<Methods134['get']['resBody'], BasicHeaders, Methods134['get']['status']>(prefix, `${prefix3}${PATH105}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods134['get']['query'] }) =>
                      `${prefix}${prefix3}${PATH105}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  },
                  uncontracted_counselings: {
                    export: {
                      get: (option?: { query?: Methods137['get']['query'], config?: T }) =>
                        fetch<Methods137['get']['resBody'], BasicHeaders, Methods137['get']['status']>(prefix, `${prefix3}${PATH108}`, GET, option).json(),
                      $get: (option?: { query?: Methods137['get']['query'], config?: T }) =>
                        fetch<Methods137['get']['resBody'], BasicHeaders, Methods137['get']['status']>(prefix, `${prefix3}${PATH108}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods137['get']['query'] }) =>
                        `${prefix}${prefix3}${PATH108}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { query?: Methods136['get']['query'], config?: T }) =>
                      fetch<Methods136['get']['resBody'], BasicHeaders, Methods136['get']['status']>(prefix, `${prefix3}${PATH107}`, GET, option).json(),
                    $get: (option?: { query?: Methods136['get']['query'], config?: T }) =>
                      fetch<Methods136['get']['resBody'], BasicHeaders, Methods136['get']['status']>(prefix, `${prefix3}${PATH107}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods136['get']['query'] }) =>
                      `${prefix}${prefix3}${PATH107}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                enquete: {
                  get: (option?: { query?: Methods138['get']['query'], config?: T }) =>
                    fetch<Methods138['get']['resBody'], BasicHeaders, Methods138['get']['status']>(prefix, `${prefix3}${PATH109}`, GET, option).json(),
                  $get: (option?: { query?: Methods138['get']['query'], config?: T }) =>
                    fetch<Methods138['get']['resBody'], BasicHeaders, Methods138['get']['status']>(prefix, `${prefix3}${PATH109}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods138['get']['query'] }) =>
                    `${prefix}${prefix3}${PATH109}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                enquetes: {
                  _enquete_id: (val6: number) => {
                    const prefix6 = `${prefix3}${PATH110}/${val6}`

                    return {
                      answers: {
                        get: (option?: { query?: Methods140['get']['query'], config?: T }) =>
                          fetch<Methods140['get']['resBody'], BasicHeaders, Methods140['get']['status']>(prefix, `${prefix6}${PATH111}`, GET, option).json(),
                        $get: (option?: { query?: Methods140['get']['query'], config?: T }) =>
                          fetch<Methods140['get']['resBody'], BasicHeaders, Methods140['get']['status']>(prefix, `${prefix6}${PATH111}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods140['get']['query'] }) =>
                          `${prefix}${prefix6}${PATH111}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      },
                      export: {
                        get: (option?: { query?: Methods141['get']['query'], config?: T }) =>
                          fetch<Methods141['get']['resBody'], BasicHeaders, Methods141['get']['status']>(prefix, `${prefix6}${PATH112}`, GET, option).json(),
                        $get: (option?: { query?: Methods141['get']['query'], config?: T }) =>
                          fetch<Methods141['get']['resBody'], BasicHeaders, Methods141['get']['status']>(prefix, `${prefix6}${PATH112}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods141['get']['query'] }) =>
                          `${prefix}${prefix6}${PATH112}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      },
                      memos: {
                        get: (option?: { query?: Methods142['get']['query'], config?: T }) =>
                          fetch<Methods142['get']['resBody'], BasicHeaders, Methods142['get']['status']>(prefix, `${prefix6}${PATH113}`, GET, option).json(),
                        $get: (option?: { query?: Methods142['get']['query'], config?: T }) =>
                          fetch<Methods142['get']['resBody'], BasicHeaders, Methods142['get']['status']>(prefix, `${prefix6}${PATH113}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods142['get']['query'] }) =>
                          `${prefix}${prefix6}${PATH113}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      },
                      summary: {
                        get: (option?: { query?: Methods143['get']['query'], config?: T }) =>
                          fetch<Methods143['get']['resBody'], BasicHeaders, Methods143['get']['status']>(prefix, `${prefix6}${PATH48}`, GET, option).json(),
                        $get: (option?: { query?: Methods143['get']['query'], config?: T }) =>
                          fetch<Methods143['get']['resBody'], BasicHeaders, Methods143['get']['status']>(prefix, `${prefix6}${PATH48}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods143['get']['query'] }) =>
                          `${prefix}${prefix6}${PATH48}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      },
                      transitions: {
                        get: (option?: { query?: Methods144['get']['query'], config?: T }) =>
                          fetch<Methods144['get']['resBody'], BasicHeaders, Methods144['get']['status']>(prefix, `${prefix6}${PATH114}`, GET, option).json(),
                        $get: (option?: { query?: Methods144['get']['query'], config?: T }) =>
                          fetch<Methods144['get']['resBody'], BasicHeaders, Methods144['get']['status']>(prefix, `${prefix6}${PATH114}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods144['get']['query'] }) =>
                          `${prefix}${prefix6}${PATH114}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      }
                    }
                  },
                  get: (option?: { query?: Methods139['get']['query'], config?: T }) =>
                    fetch<Methods139['get']['resBody'], BasicHeaders, Methods139['get']['status']>(prefix, `${prefix3}${PATH110}`, GET, option).json(),
                  $get: (option?: { query?: Methods139['get']['query'], config?: T }) =>
                    fetch<Methods139['get']['resBody'], BasicHeaders, Methods139['get']['status']>(prefix, `${prefix3}${PATH110}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods139['get']['query'] }) =>
                    `${prefix}${prefix3}${PATH110}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                reservations: {
                  daily: {
                    export: {
                      get: (option?: { query?: Methods146['get']['query'], config?: T }) =>
                        fetch<Methods146['get']['resBody'], BasicHeaders, Methods146['get']['status']>(prefix, `${prefix3}${PATH116}`, GET, option).json(),
                      $get: (option?: { query?: Methods146['get']['query'], config?: T }) =>
                        fetch<Methods146['get']['resBody'], BasicHeaders, Methods146['get']['status']>(prefix, `${prefix3}${PATH116}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods146['get']['query'] }) =>
                        `${prefix}${prefix3}${PATH116}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { query?: Methods145['get']['query'], config?: T }) =>
                      fetch<Methods145['get']['resBody'], BasicHeaders, Methods145['get']['status']>(prefix, `${prefix3}${PATH115}`, GET, option).json(),
                    $get: (option?: { query?: Methods145['get']['query'], config?: T }) =>
                      fetch<Methods145['get']['resBody'], BasicHeaders, Methods145['get']['status']>(prefix, `${prefix3}${PATH115}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods145['get']['query'] }) =>
                      `${prefix}${prefix3}${PATH115}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  },
                  monthly: {
                    export: {
                      get: (option?: { query?: Methods148['get']['query'], config?: T }) =>
                        fetch<Methods148['get']['resBody'], BasicHeaders, Methods148['get']['status']>(prefix, `${prefix3}${PATH118}`, GET, option).json(),
                      $get: (option?: { query?: Methods148['get']['query'], config?: T }) =>
                        fetch<Methods148['get']['resBody'], BasicHeaders, Methods148['get']['status']>(prefix, `${prefix3}${PATH118}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods148['get']['query'] }) =>
                        `${prefix}${prefix3}${PATH118}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { query?: Methods147['get']['query'], config?: T }) =>
                      fetch<Methods147['get']['resBody'], BasicHeaders, Methods147['get']['status']>(prefix, `${prefix3}${PATH117}`, GET, option).json(),
                    $get: (option?: { query?: Methods147['get']['query'], config?: T }) =>
                      fetch<Methods147['get']['resBody'], BasicHeaders, Methods147['get']['status']>(prefix, `${prefix3}${PATH117}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods147['get']['query'] }) =>
                      `${prefix}${prefix3}${PATH117}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  },
                  shop: {
                    export: {
                      get: (option?: { query?: Methods150['get']['query'], config?: T }) =>
                        fetch<Methods150['get']['resBody'], BasicHeaders, Methods150['get']['status']>(prefix, `${prefix3}${PATH120}`, GET, option).json(),
                      $get: (option?: { query?: Methods150['get']['query'], config?: T }) =>
                        fetch<Methods150['get']['resBody'], BasicHeaders, Methods150['get']['status']>(prefix, `${prefix3}${PATH120}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods150['get']['query'] }) =>
                        `${prefix}${prefix3}${PATH120}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { query?: Methods149['get']['query'], config?: T }) =>
                      fetch<Methods149['get']['resBody'], BasicHeaders, Methods149['get']['status']>(prefix, `${prefix3}${PATH119}`, GET, option).json(),
                    $get: (option?: { query?: Methods149['get']['query'], config?: T }) =>
                      fetch<Methods149['get']['resBody'], BasicHeaders, Methods149['get']['status']>(prefix, `${prefix3}${PATH119}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods149['get']['query'] }) =>
                      `${prefix}${prefix3}${PATH119}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                sales: {
                  contract_detail: {
                    export: {
                      get: (option?: { query?: Methods152['get']['query'], config?: T }) =>
                        fetch<Methods152['get']['resBody'], BasicHeaders, Methods152['get']['status']>(prefix, `${prefix3}${PATH122}`, GET, option).json(),
                      $get: (option?: { query?: Methods152['get']['query'], config?: T }) =>
                        fetch<Methods152['get']['resBody'], BasicHeaders, Methods152['get']['status']>(prefix, `${prefix3}${PATH122}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods152['get']['query'] }) =>
                        `${prefix}${prefix3}${PATH122}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { query?: Methods151['get']['query'], config?: T }) =>
                      fetch<Methods151['get']['resBody'], BasicHeaders, Methods151['get']['status']>(prefix, `${prefix3}${PATH121}`, GET, option).json(),
                    $get: (option?: { query?: Methods151['get']['query'], config?: T }) =>
                      fetch<Methods151['get']['resBody'], BasicHeaders, Methods151['get']['status']>(prefix, `${prefix3}${PATH121}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods151['get']['query'] }) =>
                      `${prefix}${prefix3}${PATH121}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  },
                  private_expenses: {
                    export: {
                      get: (option?: { query?: Methods154['get']['query'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods154['get']['status']>(prefix, `${prefix3}${PATH124}`, GET, option).send(),
                      $get: (option?: { query?: Methods154['get']['query'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods154['get']['status']>(prefix, `${prefix3}${PATH124}`, GET, option).send().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods154['get']['query'] }) =>
                        `${prefix}${prefix3}${PATH124}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { query?: Methods153['get']['query'], config?: T }) =>
                      fetch<Methods153['get']['resBody'], BasicHeaders, Methods153['get']['status']>(prefix, `${prefix3}${PATH123}`, GET, option).json(),
                    $get: (option?: { query?: Methods153['get']['query'], config?: T }) =>
                      fetch<Methods153['get']['resBody'], BasicHeaders, Methods153['get']['status']>(prefix, `${prefix3}${PATH123}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods153['get']['query'] }) =>
                      `${prefix}${prefix3}${PATH123}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  },
                  product: {
                    export: {
                      get: (option?: { query?: Methods156['get']['query'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods156['get']['status']>(prefix, `${prefix3}${PATH126}`, GET, option).send(),
                      $get: (option?: { query?: Methods156['get']['query'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods156['get']['status']>(prefix, `${prefix3}${PATH126}`, GET, option).send().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods156['get']['query'] }) =>
                        `${prefix}${prefix3}${PATH126}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { query?: Methods155['get']['query'], config?: T }) =>
                      fetch<Methods155['get']['resBody'], BasicHeaders, Methods155['get']['status']>(prefix, `${prefix3}${PATH125}`, GET, option).json(),
                    $get: (option?: { query?: Methods155['get']['query'], config?: T }) =>
                      fetch<Methods155['get']['resBody'], BasicHeaders, Methods155['get']['status']>(prefix, `${prefix3}${PATH125}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods155['get']['query'] }) =>
                      `${prefix}${prefix3}${PATH125}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                staff: {
                  _staff_id: (val6: number) => {
                    const prefix6 = `${prefix3}${PATH127}/${val6}`

                    return {
                      counseling: {
                        contract_penalties_reason_descriptions: {
                          get: (option?: { query?: Methods158['get']['query'], config?: T }) =>
                            fetch<Methods158['get']['resBody'], BasicHeaders, Methods158['get']['status']>(prefix, `${prefix6}${PATH129}`, GET, option).json(),
                          $get: (option?: { query?: Methods158['get']['query'], config?: T }) =>
                            fetch<Methods158['get']['resBody'], BasicHeaders, Methods158['get']['status']>(prefix, `${prefix6}${PATH129}`, GET, option).json().then(r => r.body),
                          $path: (option?: { method?: 'get'; query: Methods158['get']['query'] }) =>
                            `${prefix}${prefix6}${PATH129}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                        },
                        uncontracted_reason_details: {
                          get: (option?: { query?: Methods159['get']['query'], config?: T }) =>
                            fetch<Methods159['get']['resBody'], BasicHeaders, Methods159['get']['status']>(prefix, `${prefix6}${PATH130}`, GET, option).json(),
                          $get: (option?: { query?: Methods159['get']['query'], config?: T }) =>
                            fetch<Methods159['get']['resBody'], BasicHeaders, Methods159['get']['status']>(prefix, `${prefix6}${PATH130}`, GET, option).json().then(r => r.body),
                          $path: (option?: { method?: 'get'; query: Methods159['get']['query'] }) =>
                            `${prefix}${prefix6}${PATH130}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                        },
                        get: (option?: { query?: Methods157['get']['query'], config?: T }) =>
                          fetch<Methods157['get']['resBody'], BasicHeaders, Methods157['get']['status']>(prefix, `${prefix6}${PATH128}`, GET, option).json(),
                        $get: (option?: { query?: Methods157['get']['query'], config?: T }) =>
                          fetch<Methods157['get']['resBody'], BasicHeaders, Methods157['get']['status']>(prefix, `${prefix6}${PATH128}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods157['get']['query'] }) =>
                          `${prefix}${prefix6}${PATH128}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      },
                      sales: {
                        get: (option?: { query?: Methods160['get']['query'], config?: T }) =>
                          fetch<Methods160['get']['resBody'], BasicHeaders, Methods160['get']['status']>(prefix, `${prefix6}${PATH131}`, GET, option).json(),
                        $get: (option?: { query?: Methods160['get']['query'], config?: T }) =>
                          fetch<Methods160['get']['resBody'], BasicHeaders, Methods160['get']['status']>(prefix, `${prefix6}${PATH131}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods160['get']['query'] }) =>
                          `${prefix}${prefix6}${PATH131}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      },
                      treatments: {
                        get: (option?: { query?: Methods161['get']['query'], config?: T }) =>
                          fetch<Methods161['get']['resBody'], BasicHeaders, Methods161['get']['status']>(prefix, `${prefix6}${PATH132}`, GET, option).json(),
                        $get: (option?: { query?: Methods161['get']['query'], config?: T }) =>
                          fetch<Methods161['get']['resBody'], BasicHeaders, Methods161['get']['status']>(prefix, `${prefix6}${PATH132}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods161['get']['query'] }) =>
                          `${prefix}${prefix6}${PATH132}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      }
                    }
                  },
                  sales: {
                    export: {
                      get: (option?: { query?: Methods163['get']['query'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods163['get']['status']>(prefix, `${prefix3}${PATH134}`, GET, option).send(),
                      $get: (option?: { query?: Methods163['get']['query'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods163['get']['status']>(prefix, `${prefix3}${PATH134}`, GET, option).send().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods163['get']['query'] }) =>
                        `${prefix}${prefix3}${PATH134}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { query?: Methods162['get']['query'], config?: T }) =>
                      fetch<Methods162['get']['resBody'], BasicHeaders, Methods162['get']['status']>(prefix, `${prefix3}${PATH133}`, GET, option).json(),
                    $get: (option?: { query?: Methods162['get']['query'], config?: T }) =>
                      fetch<Methods162['get']['resBody'], BasicHeaders, Methods162['get']['status']>(prefix, `${prefix3}${PATH133}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods162['get']['query'] }) =>
                      `${prefix}${prefix3}${PATH133}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  },
                  treatment: {
                    get: (option?: { query?: Methods164['get']['query'], config?: T }) =>
                      fetch<Methods164['get']['resBody'], BasicHeaders, Methods164['get']['status']>(prefix, `${prefix3}${PATH135}`, GET, option).json(),
                    $get: (option?: { query?: Methods164['get']['query'], config?: T }) =>
                      fetch<Methods164['get']['resBody'], BasicHeaders, Methods164['get']['status']>(prefix, `${prefix3}${PATH135}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods164['get']['query'] }) =>
                      `${prefix}${prefix3}${PATH135}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                }
              },
              reservation_categories: {
                _reservation_category_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH136}/${val5}`

                  return {
                    delete: (option: { query: Methods165['delete']['query'], config?: T }) =>
                      fetch<Methods165['delete']['resBody'], BasicHeaders, Methods165['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods165['delete']['query'], config?: T }) =>
                      fetch<Methods165['delete']['resBody'], BasicHeaders, Methods165['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods165['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                batchUpdate: {
                  post: (option: { body: Methods166['post']['reqBody'], config?: T }) =>
                    fetch<Methods166['post']['resBody'], BasicHeaders, Methods166['post']['status']>(prefix, `${prefix3}${PATH137}`, POST, option).json(),
                  $post: (option: { body: Methods166['post']['reqBody'], config?: T }) =>
                    fetch<Methods166['post']['resBody'], BasicHeaders, Methods166['post']['status']>(prefix, `${prefix3}${PATH137}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH137}`
                }
              },
              reservation_settings: {
                get: (option?: { config?: T }) =>
                  fetch<Methods167['get']['resBody'], BasicHeaders, Methods167['get']['status']>(prefix, `${prefix3}${PATH138}`, GET, option).json(),
                $get: (option?: { config?: T }) =>
                  fetch<Methods167['get']['resBody'], BasicHeaders, Methods167['get']['status']>(prefix, `${prefix3}${PATH138}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods167['put']['reqBody'], config?: T }) =>
                  fetch<Methods167['put']['resBody'], BasicHeaders, Methods167['put']['status']>(prefix, `${prefix3}${PATH138}`, PUT, option).json(),
                $put: (option: { body: Methods167['put']['reqBody'], config?: T }) =>
                  fetch<Methods167['put']['resBody'], BasicHeaders, Methods167['put']['status']>(prefix, `${prefix3}${PATH138}`, PUT, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH138}`
              },
              sales_promotions: {
                _sales_promotion_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH139}/${val5}`

                  return {
                    delete: (option: { query: Methods169['delete']['query'], config?: T }) =>
                      fetch<Methods169['delete']['resBody'], BasicHeaders, Methods169['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods169['delete']['query'], config?: T }) =>
                      fetch<Methods169['delete']['resBody'], BasicHeaders, Methods169['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods169['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                batchUpdate: {
                  post: (option: { body: Methods170['post']['reqBody'], config?: T }) =>
                    fetch<Methods170['post']['resBody'], BasicHeaders, Methods170['post']['status']>(prefix, `${prefix3}${PATH140}`, POST, option).json(),
                  $post: (option: { body: Methods170['post']['reqBody'], config?: T }) =>
                    fetch<Methods170['post']['resBody'], BasicHeaders, Methods170['post']['status']>(prefix, `${prefix3}${PATH140}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH140}`
                },
                get: (option: { query: Methods168['get']['query'], config?: T }) =>
                  fetch<Methods168['get']['resBody'], BasicHeaders, Methods168['get']['status']>(prefix, `${prefix3}${PATH139}`, GET, option).json(),
                $get: (option: { query: Methods168['get']['query'], config?: T }) =>
                  fetch<Methods168['get']['resBody'], BasicHeaders, Methods168['get']['status']>(prefix, `${prefix3}${PATH139}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods168['put']['reqBody'], config?: T }) =>
                  fetch<Methods168['put']['resBody'], BasicHeaders, Methods168['put']['status']>(prefix, `${prefix3}${PATH139}`, PUT, option).json(),
                $put: (option: { body: Methods168['put']['reqBody'], config?: T }) =>
                  fetch<Methods168['put']['resBody'], BasicHeaders, Methods168['put']['status']>(prefix, `${prefix3}${PATH139}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods168['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH139}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              shops: {
                _shop_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH141}/${val5}`

                  return {
                    accounts_receivables: {
                      get: (option?: { query?: Methods173['get']['query'], config?: T }) =>
                        fetch<Methods173['get']['resBody'], BasicHeaders, Methods173['get']['status']>(prefix, `${prefix5}${PATH142}`, GET, option).json(),
                      $get: (option?: { query?: Methods173['get']['query'], config?: T }) =>
                        fetch<Methods173['get']['resBody'], BasicHeaders, Methods173['get']['status']>(prefix, `${prefix5}${PATH142}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods173['get']['query'] }) =>
                        `${prefix}${prefix5}${PATH142}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    claims: {
                      _claim_id: (val7: number) => {
                        const prefix7 = `${prefix5}${PATH143}/${val7}`

                        return {
                          patch: (option: { body: Methods175['patch']['reqBody'], config?: T }) =>
                            fetch<Methods175['patch']['resBody'], BasicHeaders, Methods175['patch']['status']>(prefix, prefix7, PATCH, option).json(),
                          $patch: (option: { body: Methods175['patch']['reqBody'], config?: T }) =>
                            fetch<Methods175['patch']['resBody'], BasicHeaders, Methods175['patch']['status']>(prefix, prefix7, PATCH, option).json().then(r => r.body),
                          $path: () => `${prefix}${prefix7}`
                        }
                      },
                      get: (option?: { query?: Methods174['get']['query'], config?: T }) =>
                        fetch<Methods174['get']['resBody'], BasicHeaders, Methods174['get']['status']>(prefix, `${prefix5}${PATH143}`, GET, option).json(),
                      $get: (option?: { query?: Methods174['get']['query'], config?: T }) =>
                        fetch<Methods174['get']['resBody'], BasicHeaders, Methods174['get']['status']>(prefix, `${prefix5}${PATH143}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods174['get']['query'] }) =>
                        `${prefix}${prefix5}${PATH143}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    extra_working_times: {
                      get: (option?: { config?: T }) =>
                        fetch<Methods176['get']['resBody'], BasicHeaders, Methods176['get']['status']>(prefix, `${prefix5}${PATH144}`, GET, option).json(),
                      $get: (option?: { config?: T }) =>
                        fetch<Methods176['get']['resBody'], BasicHeaders, Methods176['get']['status']>(prefix, `${prefix5}${PATH144}`, GET, option).json().then(r => r.body),
                      put: (option: { body: Methods176['put']['reqBody'], config?: T }) =>
                        fetch<Methods176['put']['resBody'], BasicHeaders, Methods176['put']['status']>(prefix, `${prefix5}${PATH144}`, PUT, option).json(),
                      $put: (option: { body: Methods176['put']['reqBody'], config?: T }) =>
                        fetch<Methods176['put']['resBody'], BasicHeaders, Methods176['put']['status']>(prefix, `${prefix5}${PATH144}`, PUT, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH144}`
                    },
                    getCheckinQrCode: {
                      get: (option?: { config?: T }) =>
                        fetch<Methods177['get']['resBody'], BasicHeaders, Methods177['get']['status']>(prefix, `${prefix5}${PATH145}`, GET, option).json(),
                      $get: (option?: { config?: T }) =>
                        fetch<Methods177['get']['resBody'], BasicHeaders, Methods177['get']['status']>(prefix, `${prefix5}${PATH145}`, GET, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH145}`
                    },
                    holidays: {
                      get: (option?: { config?: T }) =>
                        fetch<Methods178['get']['resBody'], BasicHeaders, Methods178['get']['status']>(prefix, `${prefix5}${PATH146}`, GET, option).json(),
                      $get: (option?: { config?: T }) =>
                        fetch<Methods178['get']['resBody'], BasicHeaders, Methods178['get']['status']>(prefix, `${prefix5}${PATH146}`, GET, option).json().then(r => r.body),
                      put: (option: { body: Methods178['put']['reqBody'], config?: T }) =>
                        fetch<Methods178['put']['resBody'], BasicHeaders, Methods178['put']['status']>(prefix, `${prefix5}${PATH146}`, PUT, option).json(),
                      $put: (option: { body: Methods178['put']['reqBody'], config?: T }) =>
                        fetch<Methods178['put']['resBody'], BasicHeaders, Methods178['put']['status']>(prefix, `${prefix5}${PATH146}`, PUT, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH146}`
                    },
                    reservation_groups: {
                      batchUpdate: {
                        post: (option: { body: Methods179['post']['reqBody'], config?: T }) =>
                          fetch<Methods179['post']['resBody'], BasicHeaders, Methods179['post']['status']>(prefix, `${prefix5}${PATH147}`, POST, option).json(),
                        $post: (option: { body: Methods179['post']['reqBody'], config?: T }) =>
                          fetch<Methods179['post']['resBody'], BasicHeaders, Methods179['post']['status']>(prefix, `${prefix5}${PATH147}`, POST, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix5}${PATH147}`
                      }
                    },
                    resources: {
                      rooms: {
                        batchUpdate: {
                          post: (option: { body: Methods180['post']['reqBody'], config?: T }) =>
                            fetch<Methods180['post']['resBody'], BasicHeaders, Methods180['post']['status']>(prefix, `${prefix5}${PATH148}`, POST, option).json(),
                          $post: (option: { body: Methods180['post']['reqBody'], config?: T }) =>
                            fetch<Methods180['post']['resBody'], BasicHeaders, Methods180['post']['status']>(prefix, `${prefix5}${PATH148}`, POST, option).json().then(r => r.body),
                          $path: () => `${prefix}${prefix5}${PATH148}`
                        }
                      }
                    },
                    shop_resource_blocks: {
                      _shop_resource_block_id: (val7: number) => {
                        const prefix7 = `${prefix5}${PATH149}/${val7}`

                        return {
                          get: (option?: { config?: T }) =>
                            fetch<Methods182['get']['resBody'], BasicHeaders, Methods182['get']['status']>(prefix, prefix7, GET, option).json(),
                          $get: (option?: { config?: T }) =>
                            fetch<Methods182['get']['resBody'], BasicHeaders, Methods182['get']['status']>(prefix, prefix7, GET, option).json().then(r => r.body),
                          delete: (option: { query: Methods182['delete']['query'], config?: T }) =>
                            fetch<Methods182['delete']['resBody'], BasicHeaders, Methods182['delete']['status']>(prefix, prefix7, DELETE, option).json(),
                          $delete: (option: { query: Methods182['delete']['query'], config?: T }) =>
                            fetch<Methods182['delete']['resBody'], BasicHeaders, Methods182['delete']['status']>(prefix, prefix7, DELETE, option).json().then(r => r.body),
                          $path: (option?: { method: 'delete'; query: Methods182['delete']['query'] }) =>
                            `${prefix}${prefix7}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                        }
                      },
                      put: (option: { body: Methods181['put']['reqBody'], config?: T }) =>
                        fetch<Methods181['put']['resBody'], BasicHeaders, Methods181['put']['status']>(prefix, `${prefix5}${PATH149}`, PUT, option).json(),
                      $put: (option: { body: Methods181['put']['reqBody'], config?: T }) =>
                        fetch<Methods181['put']['resBody'], BasicHeaders, Methods181['put']['status']>(prefix, `${prefix5}${PATH149}`, PUT, option).json().then(r => r.body),
                      get: (option?: { query?: Methods181['get']['query'], config?: T }) =>
                        fetch<Methods181['get']['resBody'], BasicHeaders, Methods181['get']['status']>(prefix, `${prefix5}${PATH149}`, GET, option).json(),
                      $get: (option?: { query?: Methods181['get']['query'], config?: T }) =>
                        fetch<Methods181['get']['resBody'], BasicHeaders, Methods181['get']['status']>(prefix, `${prefix5}${PATH149}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods181['get']['query'] }) =>
                        `${prefix}${prefix5}${PATH149}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    staff_shift_schedule_rules: {
                      get: (option?: { query?: Methods183['get']['query'], config?: T }) =>
                        fetch<Methods183['get']['resBody'], BasicHeaders, Methods183['get']['status']>(prefix, `${prefix5}${PATH150}`, GET, option).json(),
                      $get: (option?: { query?: Methods183['get']['query'], config?: T }) =>
                        fetch<Methods183['get']['resBody'], BasicHeaders, Methods183['get']['status']>(prefix, `${prefix5}${PATH150}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods183['get']['query'] }) =>
                        `${prefix}${prefix5}${PATH150}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    staff_shift_schedule_settings: {
                      get: (option?: { config?: T }) =>
                        fetch<Methods184['get']['resBody'], BasicHeaders, Methods184['get']['status']>(prefix, `${prefix5}${PATH151}`, GET, option).json(),
                      $get: (option?: { config?: T }) =>
                        fetch<Methods184['get']['resBody'], BasicHeaders, Methods184['get']['status']>(prefix, `${prefix5}${PATH151}`, GET, option).json().then(r => r.body),
                      put: (option: { body: Methods184['put']['reqBody'], config?: T }) =>
                        fetch<Methods184['put']['resBody'], BasicHeaders, Methods184['put']['status']>(prefix, `${prefix5}${PATH151}`, PUT, option).json(),
                      $put: (option: { body: Methods184['put']['reqBody'], config?: T }) =>
                        fetch<Methods184['put']['resBody'], BasicHeaders, Methods184['put']['status']>(prefix, `${prefix5}${PATH151}`, PUT, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH151}`
                    },
                    staff_shift_schedules: {
                      get: (option?: { query?: Methods185['get']['query'], config?: T }) =>
                        fetch<Methods185['get']['resBody'], BasicHeaders, Methods185['get']['status']>(prefix, `${prefix5}${PATH152}`, GET, option).json(),
                      $get: (option?: { query?: Methods185['get']['query'], config?: T }) =>
                        fetch<Methods185['get']['resBody'], BasicHeaders, Methods185['get']['status']>(prefix, `${prefix5}${PATH152}`, GET, option).json().then(r => r.body),
                      put: (option: { body: Methods185['put']['reqBody'], config?: T }) =>
                        fetch<Methods185['put']['resBody'], BasicHeaders, Methods185['put']['status']>(prefix, `${prefix5}${PATH152}`, PUT, option).json(),
                      $put: (option: { body: Methods185['put']['reqBody'], config?: T }) =>
                        fetch<Methods185['put']['resBody'], BasicHeaders, Methods185['put']['status']>(prefix, `${prefix5}${PATH152}`, PUT, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods185['get']['query'] }) =>
                        `${prefix}${prefix5}${PATH152}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    staffs: {
                      all: {
                        get: (option?: { query?: Methods186['get']['query'], config?: T }) =>
                          fetch<Methods186['get']['resBody'], BasicHeaders, Methods186['get']['status']>(prefix, `${prefix5}${PATH153}`, GET, option).json(),
                        $get: (option?: { query?: Methods186['get']['query'], config?: T }) =>
                          fetch<Methods186['get']['resBody'], BasicHeaders, Methods186['get']['status']>(prefix, `${prefix5}${PATH153}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods186['get']['query'] }) =>
                          `${prefix}${prefix5}${PATH153}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      },
                      batchUpdate: {
                        post: (option: { body: Methods187['post']['reqBody'], config?: T }) =>
                          fetch<Methods187['post']['resBody'], BasicHeaders, Methods187['post']['status']>(prefix, `${prefix5}${PATH154}`, POST, option).json(),
                        $post: (option: { body: Methods187['post']['reqBody'], config?: T }) =>
                          fetch<Methods187['post']['resBody'], BasicHeaders, Methods187['post']['status']>(prefix, `${prefix5}${PATH154}`, POST, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix5}${PATH154}`
                      }
                    },
                    supports: {
                      _support_id: (val7: number) => {
                        const prefix7 = `${prefix5}${PATH155}/${val7}`

                        return {
                          patch: (option: { body: Methods189['patch']['reqBody'], config?: T }) =>
                            fetch<Methods189['patch']['resBody'], BasicHeaders, Methods189['patch']['status']>(prefix, prefix7, PATCH, option).json(),
                          $patch: (option: { body: Methods189['patch']['reqBody'], config?: T }) =>
                            fetch<Methods189['patch']['resBody'], BasicHeaders, Methods189['patch']['status']>(prefix, prefix7, PATCH, option).json().then(r => r.body),
                          $path: () => `${prefix}${prefix7}`
                        }
                      },
                      get: (option?: { query?: Methods188['get']['query'], config?: T }) =>
                        fetch<Methods188['get']['resBody'], BasicHeaders, Methods188['get']['status']>(prefix, `${prefix5}${PATH155}`, GET, option).json(),
                      $get: (option?: { query?: Methods188['get']['query'], config?: T }) =>
                        fetch<Methods188['get']['resBody'], BasicHeaders, Methods188['get']['status']>(prefix, `${prefix5}${PATH155}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods188['get']['query'] }) =>
                        `${prefix}${prefix5}${PATH155}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    working_times: {
                      get: (option?: { config?: T }) =>
                        fetch<Methods190['get']['resBody'], BasicHeaders, Methods190['get']['status']>(prefix, `${prefix5}${PATH156}`, GET, option).json(),
                      $get: (option?: { config?: T }) =>
                        fetch<Methods190['get']['resBody'], BasicHeaders, Methods190['get']['status']>(prefix, `${prefix5}${PATH156}`, GET, option).json().then(r => r.body),
                      put: (option: { body: Methods190['put']['reqBody'], config?: T }) =>
                        fetch<Methods190['put']['resBody'], BasicHeaders, Methods190['put']['status']>(prefix, `${prefix5}${PATH156}`, PUT, option).json(),
                      $put: (option: { body: Methods190['put']['reqBody'], config?: T }) =>
                        fetch<Methods190['put']['resBody'], BasicHeaders, Methods190['put']['status']>(prefix, `${prefix5}${PATH156}`, PUT, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH156}`
                    },
                    delete: (option: { query: Methods172['delete']['query'], config?: T }) =>
                      fetch<Methods172['delete']['resBody'], BasicHeaders, Methods172['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods172['delete']['query'], config?: T }) =>
                      fetch<Methods172['delete']['resBody'], BasicHeaders, Methods172['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods172['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                batchUpdate: {
                  post: (option: { body: Methods191['post']['reqBody'], config?: T }) =>
                    fetch<Methods191['post']['resBody'], BasicHeaders, Methods191['post']['status']>(prefix, `${prefix3}${PATH157}`, POST, option).json(),
                  $post: (option: { body: Methods191['post']['reqBody'], config?: T }) =>
                    fetch<Methods191['post']['resBody'], BasicHeaders, Methods191['post']['status']>(prefix, `${prefix3}${PATH157}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH157}`
                },
                get: (option: { query: Methods171['get']['query'], config?: T }) =>
                  fetch<Methods171['get']['resBody'], BasicHeaders, Methods171['get']['status']>(prefix, `${prefix3}${PATH141}`, GET, option).json(),
                $get: (option: { query: Methods171['get']['query'], config?: T }) =>
                  fetch<Methods171['get']['resBody'], BasicHeaders, Methods171['get']['status']>(prefix, `${prefix3}${PATH141}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods171['put']['reqBody'], config?: T }) =>
                  fetch<Methods171['put']['resBody'], BasicHeaders, Methods171['put']['status']>(prefix, `${prefix3}${PATH141}`, PUT, option).json(),
                $put: (option: { body: Methods171['put']['reqBody'], config?: T }) =>
                  fetch<Methods171['put']['resBody'], BasicHeaders, Methods171['put']['status']>(prefix, `${prefix3}${PATH141}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods171['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH141}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              signin: {
                post: (option: { body: Methods192['post']['reqBody'], config?: T }) =>
                  fetch<Methods192['post']['resBody'], BasicHeaders, Methods192['post']['status']>(prefix, `${prefix3}${PATH158}`, POST, option).json(),
                $post: (option: { body: Methods192['post']['reqBody'], config?: T }) =>
                  fetch<Methods192['post']['resBody'], BasicHeaders, Methods192['post']['status']>(prefix, `${prefix3}${PATH158}`, POST, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH158}`
              },
              staff_roles: {
                _staff_role_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH159}/${val5}`

                  return {
                    delete: (option: { query: Methods194['delete']['query'], config?: T }) =>
                      fetch<Methods194['delete']['resBody'], BasicHeaders, Methods194['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods194['delete']['query'], config?: T }) =>
                      fetch<Methods194['delete']['resBody'], BasicHeaders, Methods194['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods194['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                batchUpdate: {
                  post: (option: { body: Methods195['post']['reqBody'], config?: T }) =>
                    fetch<Methods195['post']['resBody'], BasicHeaders, Methods195['post']['status']>(prefix, `${prefix3}${PATH160}`, POST, option).json(),
                  $post: (option: { body: Methods195['post']['reqBody'], config?: T }) =>
                    fetch<Methods195['post']['resBody'], BasicHeaders, Methods195['post']['status']>(prefix, `${prefix3}${PATH160}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH160}`
                },
                get: (option: { query: Methods193['get']['query'], config?: T }) =>
                  fetch<Methods193['get']['resBody'], BasicHeaders, Methods193['get']['status']>(prefix, `${prefix3}${PATH159}`, GET, option).json(),
                $get: (option: { query: Methods193['get']['query'], config?: T }) =>
                  fetch<Methods193['get']['resBody'], BasicHeaders, Methods193['get']['status']>(prefix, `${prefix3}${PATH159}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods193['put']['reqBody'], config?: T }) =>
                  fetch<Methods193['put']['resBody'], BasicHeaders, Methods193['put']['status']>(prefix, `${prefix3}${PATH159}`, PUT, option).json(),
                $put: (option: { body: Methods193['put']['reqBody'], config?: T }) =>
                  fetch<Methods193['put']['resBody'], BasicHeaders, Methods193['put']['status']>(prefix, `${prefix3}${PATH159}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods193['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH159}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              staffs: {
                _staff_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH161}/${val5}`

                  return {
                    acceptPasswordChange: {
                      post: (option?: { config?: T }) =>
                        fetch<Methods198['post']['resBody'], BasicHeaders, Methods198['post']['status']>(prefix, `${prefix5}${PATH162}`, POST, option).json(),
                      $post: (option?: { config?: T }) =>
                        fetch<Methods198['post']['resBody'], BasicHeaders, Methods198['post']['status']>(prefix, `${prefix5}${PATH162}`, POST, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH162}`
                    },
                    get: (option?: { config?: T }) =>
                      fetch<Methods197['get']['resBody'], BasicHeaders, Methods197['get']['status']>(prefix, prefix5, GET, option).json(),
                    $get: (option?: { config?: T }) =>
                      fetch<Methods197['get']['resBody'], BasicHeaders, Methods197['get']['status']>(prefix, prefix5, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix5}`
                  }
                },
                all: {
                  get: (option: { query: Methods199['get']['query'], config?: T }) =>
                    fetch<Methods199['get']['resBody'], BasicHeaders, Methods199['get']['status']>(prefix, `${prefix3}${PATH153}`, GET, option).json(),
                  $get: (option: { query: Methods199['get']['query'], config?: T }) =>
                    fetch<Methods199['get']['resBody'], BasicHeaders, Methods199['get']['status']>(prefix, `${prefix3}${PATH153}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods199['get']['query'] }) =>
                    `${prefix}${prefix3}${PATH153}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                me: {
                  get: (option?: { config?: T }) =>
                    fetch<Methods200['get']['resBody'], BasicHeaders, Methods200['get']['status']>(prefix, `${prefix3}${PATH163}`, GET, option).json(),
                  $get: (option?: { config?: T }) =>
                    fetch<Methods200['get']['resBody'], BasicHeaders, Methods200['get']['status']>(prefix, `${prefix3}${PATH163}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH163}`
                },
                get: (option?: { query?: Methods196['get']['query'], config?: T }) =>
                  fetch<Methods196['get']['resBody'], BasicHeaders, Methods196['get']['status']>(prefix, `${prefix3}${PATH161}`, GET, option).json(),
                $get: (option?: { query?: Methods196['get']['query'], config?: T }) =>
                  fetch<Methods196['get']['resBody'], BasicHeaders, Methods196['get']['status']>(prefix, `${prefix3}${PATH161}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods196['put']['reqBody'], config?: T }) =>
                  fetch<Methods196['put']['resBody'], BasicHeaders, Methods196['put']['status']>(prefix, `${prefix3}${PATH161}`, PUT, option).json(),
                $put: (option: { body: Methods196['put']['reqBody'], config?: T }) =>
                  fetch<Methods196['put']['resBody'], BasicHeaders, Methods196['put']['status']>(prefix, `${prefix3}${PATH161}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods196['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH161}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              template_files: {
                _template_file_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH164}/${val5}`

                  return {
                    get: (option?: { config?: T }) =>
                      fetch<Methods201['get']['resBody'], BasicHeaders, Methods201['get']['status']>(prefix, prefix5, GET, option).json(),
                    $get: (option?: { config?: T }) =>
                      fetch<Methods201['get']['resBody'], BasicHeaders, Methods201['get']['status']>(prefix, prefix5, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix5}`
                  }
                }
              },
              tenant_assets: {
                _tenant_asset_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH165}/${val5}`

                  return {
                    delete: (option?: { config?: T }) =>
                      fetch<Methods203['delete']['resBody'], BasicHeaders, Methods203['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option?: { config?: T }) =>
                      fetch<Methods203['delete']['resBody'], BasicHeaders, Methods203['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix5}`
                  }
                },
                get: (option?: { query?: Methods202['get']['query'], config?: T }) =>
                  fetch<Methods202['get']['resBody'], BasicHeaders, Methods202['get']['status']>(prefix, `${prefix3}${PATH165}`, GET, option).json(),
                $get: (option?: { query?: Methods202['get']['query'], config?: T }) =>
                  fetch<Methods202['get']['resBody'], BasicHeaders, Methods202['get']['status']>(prefix, `${prefix3}${PATH165}`, GET, option).json().then(r => r.body),
                post: (option: { body: Methods202['post']['reqBody'], config?: T }) =>
                  fetch<Methods202['post']['resBody'], BasicHeaders, Methods202['post']['status']>(prefix, `${prefix3}${PATH165}`, POST, option, 'FormData').json(),
                $post: (option: { body: Methods202['post']['reqBody'], config?: T }) =>
                  fetch<Methods202['post']['resBody'], BasicHeaders, Methods202['post']['status']>(prefix, `${prefix3}${PATH165}`, POST, option, 'FormData').json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods202['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH165}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              visitation_statuses: {
                batchUpdate: {
                  post: (option: { body: Methods205['post']['reqBody'], config?: T }) =>
                    fetch<Methods205['post']['resBody'], BasicHeaders, Methods205['post']['status']>(prefix, `${prefix3}${PATH167}`, POST, option).json(),
                  $post: (option: { body: Methods205['post']['reqBody'], config?: T }) =>
                    fetch<Methods205['post']['resBody'], BasicHeaders, Methods205['post']['status']>(prefix, `${prefix3}${PATH167}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH167}`
                },
                get: (option: { query: Methods204['get']['query'], config?: T }) =>
                  fetch<Methods204['get']['resBody'], BasicHeaders, Methods204['get']['status']>(prefix, `${prefix3}${PATH166}`, GET, option).json(),
                $get: (option: { query: Methods204['get']['query'], config?: T }) =>
                  fetch<Methods204['get']['resBody'], BasicHeaders, Methods204['get']['status']>(prefix, `${prefix3}${PATH166}`, GET, option).json().then(r => r.body),
                patch: (option: { body: Methods204['patch']['reqBody'], config?: T }) =>
                  fetch<Methods204['patch']['resBody'], BasicHeaders, Methods204['patch']['status']>(prefix, `${prefix3}${PATH166}`, PATCH, option).json(),
                $patch: (option: { body: Methods204['patch']['reqBody'], config?: T }) =>
                  fetch<Methods204['patch']['resBody'], BasicHeaders, Methods204['patch']['status']>(prefix, `${prefix3}${PATH166}`, PATCH, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods204['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH166}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              voucher_document_settings: {
                get: (option?: { config?: T }) =>
                  fetch<Methods206['get']['resBody'], BasicHeaders, Methods206['get']['status']>(prefix, `${prefix3}${PATH168}`, GET, option).json(),
                $get: (option?: { config?: T }) =>
                  fetch<Methods206['get']['resBody'], BasicHeaders, Methods206['get']['status']>(prefix, `${prefix3}${PATH168}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods206['put']['reqBody'], config?: T }) =>
                  fetch<Methods206['put']['resBody'], BasicHeaders, Methods206['put']['status']>(prefix, `${prefix3}${PATH168}`, PUT, option).json(),
                $put: (option: { body: Methods206['put']['reqBody'], config?: T }) =>
                  fetch<Methods206['put']['resBody'], BasicHeaders, Methods206['put']['status']>(prefix, `${prefix3}${PATH168}`, PUT, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH168}`
              },
              web_reservation_areas: {
                _web_reservation_area_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH169}/${val5}`

                  return {
                    get: (option?: { config?: T }) =>
                      fetch<Methods208['get']['resBody'], BasicHeaders, Methods208['get']['status']>(prefix, prefix5, GET, option).json(),
                    $get: (option?: { config?: T }) =>
                      fetch<Methods208['get']['resBody'], BasicHeaders, Methods208['get']['status']>(prefix, prefix5, GET, option).json().then(r => r.body),
                    delete: (option: { query: Methods208['delete']['query'], config?: T }) =>
                      fetch<Methods208['delete']['resBody'], BasicHeaders, Methods208['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods208['delete']['query'], config?: T }) =>
                      fetch<Methods208['delete']['resBody'], BasicHeaders, Methods208['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods208['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                batchUpdate: {
                  post: (option: { body: Methods209['post']['reqBody'], config?: T }) =>
                    fetch<Methods209['post']['resBody'], BasicHeaders, Methods209['post']['status']>(prefix, `${prefix3}${PATH170}`, POST, option).json(),
                  $post: (option: { body: Methods209['post']['reqBody'], config?: T }) =>
                    fetch<Methods209['post']['resBody'], BasicHeaders, Methods209['post']['status']>(prefix, `${prefix3}${PATH170}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH170}`
                },
                get: (option: { query: Methods207['get']['query'], config?: T }) =>
                  fetch<Methods207['get']['resBody'], BasicHeaders, Methods207['get']['status']>(prefix, `${prefix3}${PATH169}`, GET, option).json(),
                $get: (option: { query: Methods207['get']['query'], config?: T }) =>
                  fetch<Methods207['get']['resBody'], BasicHeaders, Methods207['get']['status']>(prefix, `${prefix3}${PATH169}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods207['put']['reqBody'], config?: T }) =>
                  fetch<Methods207['put']['resBody'], BasicHeaders, Methods207['put']['status']>(prefix, `${prefix3}${PATH169}`, PUT, option).json(),
                $put: (option: { body: Methods207['put']['reqBody'], config?: T }) =>
                  fetch<Methods207['put']['resBody'], BasicHeaders, Methods207['put']['status']>(prefix, `${prefix3}${PATH169}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods207['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH169}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              web_reservation_enquetes: {
                get: (option?: { config?: T }) =>
                  fetch<Methods210['get']['resBody'], BasicHeaders, Methods210['get']['status']>(prefix, `${prefix3}${PATH171}`, GET, option).json(),
                $get: (option?: { config?: T }) =>
                  fetch<Methods210['get']['resBody'], BasicHeaders, Methods210['get']['status']>(prefix, `${prefix3}${PATH171}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods210['put']['reqBody'], config?: T }) =>
                  fetch<Methods210['put']['resBody'], BasicHeaders, Methods210['put']['status']>(prefix, `${prefix3}${PATH171}`, PUT, option).json(),
                $put: (option: { body: Methods210['put']['reqBody'], config?: T }) =>
                  fetch<Methods210['put']['resBody'], BasicHeaders, Methods210['put']['status']>(prefix, `${prefix3}${PATH171}`, PUT, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH171}`
              },
              web_reservation_menus: {
                batchUpdate: {
                  post: (option: { body: Methods211['post']['reqBody'], config?: T }) =>
                    fetch<Methods211['post']['resBody'], BasicHeaders, Methods211['post']['status']>(prefix, `${prefix3}${PATH172}`, POST, option).json(),
                  $post: (option: { body: Methods211['post']['reqBody'], config?: T }) =>
                    fetch<Methods211['post']['resBody'], BasicHeaders, Methods211['post']['status']>(prefix, `${prefix3}${PATH172}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH172}`
                }
              },
              web_reservation_settings: {
                get: (option?: { config?: T }) =>
                  fetch<Methods212['get']['resBody'], BasicHeaders, Methods212['get']['status']>(prefix, `${prefix3}${PATH173}`, GET, option).json(),
                $get: (option?: { config?: T }) =>
                  fetch<Methods212['get']['resBody'], BasicHeaders, Methods212['get']['status']>(prefix, `${prefix3}${PATH173}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods212['put']['reqBody'], config?: T }) =>
                  fetch<Methods212['put']['resBody'], BasicHeaders, Methods212['put']['status']>(prefix, `${prefix3}${PATH173}`, PUT, option).json(),
                $put: (option: { body: Methods212['put']['reqBody'], config?: T }) =>
                  fetch<Methods212['put']['resBody'], BasicHeaders, Methods212['put']['status']>(prefix, `${prefix3}${PATH173}`, PUT, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH173}`
              },
              web_reservation_texts: {
                get: (option?: { config?: T }) =>
                  fetch<Methods213['get']['resBody'], BasicHeaders, Methods213['get']['status']>(prefix, `${prefix3}${PATH174}`, GET, option).json(),
                $get: (option?: { config?: T }) =>
                  fetch<Methods213['get']['resBody'], BasicHeaders, Methods213['get']['status']>(prefix, `${prefix3}${PATH174}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods213['put']['reqBody'], config?: T }) =>
                  fetch<Methods213['put']['resBody'], BasicHeaders, Methods213['put']['status']>(prefix, `${prefix3}${PATH174}`, PUT, option).json(),
                $put: (option: { body: Methods213['put']['reqBody'], config?: T }) =>
                  fetch<Methods213['put']['resBody'], BasicHeaders, Methods213['put']['status']>(prefix, `${prefix3}${PATH174}`, PUT, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH174}`
              },
              get: (option?: { config?: T }) =>
                fetch<Methods10['get']['resBody'], BasicHeaders, Methods10['get']['status']>(prefix, prefix3, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods10['get']['resBody'], BasicHeaders, Methods10['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods10['patch']['reqBody'], config?: T }) =>
                fetch<Methods10['patch']['resBody'], BasicHeaders, Methods10['patch']['status']>(prefix, prefix3, PATCH, option).json(),
              $patch: (option: { body: Methods10['patch']['reqBody'], config?: T }) =>
                fetch<Methods10['patch']['resBody'], BasicHeaders, Methods10['patch']['status']>(prefix, prefix3, PATCH, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`
            }
          },
          post: (option: { body: Methods9['post']['reqBody'], config?: T }) =>
            fetch<Methods9['post']['resBody'], BasicHeaders, Methods9['post']['status']>(prefix, PATH7, POST, option).json(),
          $post: (option: { body: Methods9['post']['reqBody'], config?: T }) =>
            fetch<Methods9['post']['resBody'], BasicHeaders, Methods9['post']['status']>(prefix, PATH7, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH7}`
        }
      },
      v2: {
        tenants: {
          _tenant_name: (val3: string) => {
            const prefix3 = `${PATH175}/${val3}`

            return {
              customers: {
                _customer_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH18}/${val5}`

                  return {
                    contracts: {
                      _contract_associate_id: (val7: number) => {
                        const prefix7 = `${prefix5}${PATH24}/${val7}`

                        return {
                          createDocument: {
                            post: (option: { body: Methods216['post']['reqBody'], config?: T }) =>
                              fetch<Methods216['post']['resBody'], BasicHeaders, Methods216['post']['status']>(prefix, `${prefix7}${PATH176}`, POST, option).json(),
                            $post: (option: { body: Methods216['post']['reqBody'], config?: T }) =>
                              fetch<Methods216['post']['resBody'], BasicHeaders, Methods216['post']['status']>(prefix, `${prefix7}${PATH176}`, POST, option).json().then(r => r.body),
                            $path: () => `${prefix}${prefix7}${PATH176}`
                          },
                          fixToContract: {
                            post: (option: { body: Methods217['post']['reqBody'], config?: T }) =>
                              fetch<Methods217['post']['resBody'], BasicHeaders, Methods217['post']['status']>(prefix, `${prefix7}${PATH177}`, POST, option).json(),
                            $post: (option: { body: Methods217['post']['reqBody'], config?: T }) =>
                              fetch<Methods217['post']['resBody'], BasicHeaders, Methods217['post']['status']>(prefix, `${prefix7}${PATH177}`, POST, option).json().then(r => r.body),
                            $path: () => `${prefix}${prefix7}${PATH177}`
                          },
                          get: (option?: { config?: T }) =>
                            fetch<Methods215['get']['resBody'], BasicHeaders, Methods215['get']['status']>(prefix, prefix7, GET, option).json(),
                          $get: (option?: { config?: T }) =>
                            fetch<Methods215['get']['resBody'], BasicHeaders, Methods215['get']['status']>(prefix, prefix7, GET, option).json().then(r => r.body),
                          patch: (option: { body: Methods215['patch']['reqBody'], config?: T }) =>
                            fetch<Methods215['patch']['resBody'], BasicHeaders, Methods215['patch']['status']>(prefix, prefix7, PATCH, option).json(),
                          $patch: (option: { body: Methods215['patch']['reqBody'], config?: T }) =>
                            fetch<Methods215['patch']['resBody'], BasicHeaders, Methods215['patch']['status']>(prefix, prefix7, PATCH, option).json().then(r => r.body),
                          delete: (option: { query: Methods215['delete']['query'], config?: T }) =>
                            fetch<Methods215['delete']['resBody'], BasicHeaders, Methods215['delete']['status']>(prefix, prefix7, DELETE, option).json(),
                          $delete: (option: { query: Methods215['delete']['query'], config?: T }) =>
                            fetch<Methods215['delete']['resBody'], BasicHeaders, Methods215['delete']['status']>(prefix, prefix7, DELETE, option).json().then(r => r.body),
                          $path: (option?: { method: 'delete'; query: Methods215['delete']['query'] }) =>
                            `${prefix}${prefix7}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                        }
                      },
                      get: (option?: { query?: Methods214['get']['query'], config?: T }) =>
                        fetch<Methods214['get']['resBody'], BasicHeaders, Methods214['get']['status']>(prefix, `${prefix5}${PATH24}`, GET, option).json(),
                      $get: (option?: { query?: Methods214['get']['query'], config?: T }) =>
                        fetch<Methods214['get']['resBody'], BasicHeaders, Methods214['get']['status']>(prefix, `${prefix5}${PATH24}`, GET, option).json().then(r => r.body),
                      post: (option: { body: Methods214['post']['reqBody'], config?: T }) =>
                        fetch<Methods214['post']['resBody'], BasicHeaders, Methods214['post']['status']>(prefix, `${prefix5}${PATH24}`, POST, option).json(),
                      $post: (option: { body: Methods214['post']['reqBody'], config?: T }) =>
                        fetch<Methods214['post']['resBody'], BasicHeaders, Methods214['post']['status']>(prefix, `${prefix5}${PATH24}`, POST, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods214['get']['query'] }) =>
                        `${prefix}${prefix5}${PATH24}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    reservation_categories: {
                      _reservation_category_id: (val7: number) => {
                        const prefix7 = `${prefix5}${PATH136}/${val7}`

                        return {
                          reservation_contents: {
                            get: (option?: { config?: T }) =>
                              fetch<Methods218['get']['resBody'], BasicHeaders, Methods218['get']['status']>(prefix, `${prefix7}${PATH178}`, GET, option).json(),
                            $get: (option?: { config?: T }) =>
                              fetch<Methods218['get']['resBody'], BasicHeaders, Methods218['get']['status']>(prefix, `${prefix7}${PATH178}`, GET, option).json().then(r => r.body),
                            $path: () => `${prefix}${prefix7}${PATH178}`
                          }
                        }
                      }
                    },
                    reservation_groups: {
                      _reservation_group_id: (val7: number) => {
                        const prefix7 = `${prefix5}${PATH63}/${val7}`

                        return {
                          execCancel: {
                            post: (option: { body: Methods221['post']['reqBody'], config?: T }) =>
                              fetch<Methods221['post']['resBody'], BasicHeaders, Methods221['post']['status']>(prefix, `${prefix7}${PATH179}`, POST, option).json(),
                            $post: (option: { body: Methods221['post']['reqBody'], config?: T }) =>
                              fetch<Methods221['post']['resBody'], BasicHeaders, Methods221['post']['status']>(prefix, `${prefix7}${PATH179}`, POST, option).json().then(r => r.body),
                            $path: () => `${prefix}${prefix7}${PATH179}`
                          },
                          get: (option?: { config?: T }) =>
                            fetch<Methods220['get']['resBody'], BasicHeaders, Methods220['get']['status']>(prefix, prefix7, GET, option).json(),
                          $get: (option?: { config?: T }) =>
                            fetch<Methods220['get']['resBody'], BasicHeaders, Methods220['get']['status']>(prefix, prefix7, GET, option).json().then(r => r.body),
                          $path: () => `${prefix}${prefix7}`
                        }
                      },
                      put: (option: { body: Methods219['put']['reqBody'], config?: T }) =>
                        fetch<Methods219['put']['resBody'], BasicHeaders, Methods219['put']['status']>(prefix, `${prefix5}${PATH63}`, PUT, option).json(),
                      $put: (option: { body: Methods219['put']['reqBody'], config?: T }) =>
                        fetch<Methods219['put']['resBody'], BasicHeaders, Methods219['put']['status']>(prefix, `${prefix5}${PATH63}`, PUT, option).json().then(r => r.body),
                      get: (option?: { query?: Methods219['get']['query'], config?: T }) =>
                        fetch<Methods219['get']['resBody'], BasicHeaders, Methods219['get']['status']>(prefix, `${prefix5}${PATH63}`, GET, option).json(),
                      $get: (option?: { query?: Methods219['get']['query'], config?: T }) =>
                        fetch<Methods219['get']['resBody'], BasicHeaders, Methods219['get']['status']>(prefix, `${prefix5}${PATH63}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods219['get']['query'] }) =>
                        `${prefix}${prefix5}${PATH63}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    }
                  }
                }
              },
              ex: {
                line: {
                  fetchCustomer: {
                    post: (option: { body: Methods222['post']['reqBody'], config?: T }) =>
                      fetch<Methods222['post']['resBody'], BasicHeaders, Methods222['post']['status']>(prefix, `${prefix3}${PATH180}`, POST, option).json(),
                    $post: (option: { body: Methods222['post']['reqBody'], config?: T }) =>
                      fetch<Methods222['post']['resBody'], BasicHeaders, Methods222['post']['status']>(prefix, `${prefix3}${PATH180}`, POST, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix3}${PATH180}`
                  }
                },
                reservation_candidate_dates: {
                  post: (option: { body: Methods223['post']['reqBody'], config?: T }) =>
                    fetch<Methods223['post']['resBody'], BasicHeaders, Methods223['post']['status']>(prefix, `${prefix3}${PATH181}`, POST, option).json(),
                  $post: (option: { body: Methods223['post']['reqBody'], config?: T }) =>
                    fetch<Methods223['post']['resBody'], BasicHeaders, Methods223['post']['status']>(prefix, `${prefix3}${PATH181}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH181}`
                },
                reservation_candidate_times: {
                  post: (option: { body: Methods224['post']['reqBody'], config?: T }) =>
                    fetch<Methods224['post']['resBody'], BasicHeaders, Methods224['post']['status']>(prefix, `${prefix3}${PATH182}`, POST, option).json(),
                  $post: (option: { body: Methods224['post']['reqBody'], config?: T }) =>
                    fetch<Methods224['post']['resBody'], BasicHeaders, Methods224['post']['status']>(prefix, `${prefix3}${PATH182}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH182}`
                },
                reservation_groups: {
                  post: (option: { body: Methods225['post']['reqBody'], config?: T }) =>
                    fetch<Methods225['post']['resBody'], BasicHeaders, Methods225['post']['status']>(prefix, `${prefix3}${PATH183}`, POST, option).json(),
                  $post: (option: { body: Methods225['post']['reqBody'], config?: T }) =>
                    fetch<Methods225['post']['resBody'], BasicHeaders, Methods225['post']['status']>(prefix, `${prefix3}${PATH183}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH183}`
                },
                web_reservation_areas: {
                  get: (option?: { query?: Methods226['get']['query'], config?: T }) =>
                    fetch<Methods226['get']['resBody'], BasicHeaders, Methods226['get']['status']>(prefix, `${prefix3}${PATH184}`, GET, option).json(),
                  $get: (option?: { query?: Methods226['get']['query'], config?: T }) =>
                    fetch<Methods226['get']['resBody'], BasicHeaders, Methods226['get']['status']>(prefix, `${prefix3}${PATH184}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods226['get']['query'] }) =>
                    `${prefix}${prefix3}${PATH184}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                web_reservation_menus: {
                  _web_reservation_menu_id: (val6: number) => {
                    const prefix6 = `${prefix3}${PATH185}/${val6}`

                    return {
                      staffs: {
                        get: (option?: { query?: Methods229['get']['query'], config?: T }) =>
                          fetch<Methods229['get']['resBody'], BasicHeaders, Methods229['get']['status']>(prefix, `${prefix6}${PATH161}`, GET, option).json(),
                        $get: (option?: { query?: Methods229['get']['query'], config?: T }) =>
                          fetch<Methods229['get']['resBody'], BasicHeaders, Methods229['get']['status']>(prefix, `${prefix6}${PATH161}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods229['get']['query'] }) =>
                          `${prefix}${prefix6}${PATH161}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      },
                      get: (option?: { query?: Methods228['get']['query'], config?: T }) =>
                        fetch<Methods228['get']['resBody'], BasicHeaders, Methods228['get']['status']>(prefix, prefix6, GET, option).json(),
                      $get: (option?: { query?: Methods228['get']['query'], config?: T }) =>
                        fetch<Methods228['get']['resBody'], BasicHeaders, Methods228['get']['status']>(prefix, prefix6, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods228['get']['query'] }) =>
                        `${prefix}${prefix6}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    }
                  },
                  get: (option?: { query?: Methods227['get']['query'], config?: T }) =>
                    fetch<Methods227['get']['resBody'], BasicHeaders, Methods227['get']['status']>(prefix, `${prefix3}${PATH185}`, GET, option).json(),
                  $get: (option?: { query?: Methods227['get']['query'], config?: T }) =>
                    fetch<Methods227['get']['resBody'], BasicHeaders, Methods227['get']['status']>(prefix, `${prefix3}${PATH185}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods227['get']['query'] }) =>
                    `${prefix}${prefix3}${PATH185}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                web_reservation_shops: {
                  get: (option?: { query?: Methods230['get']['query'], config?: T }) =>
                    fetch<Methods230['get']['resBody'], BasicHeaders, Methods230['get']['status']>(prefix, `${prefix3}${PATH186}`, GET, option).json(),
                  $get: (option?: { query?: Methods230['get']['query'], config?: T }) =>
                    fetch<Methods230['get']['resBody'], BasicHeaders, Methods230['get']['status']>(prefix, `${prefix3}${PATH186}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods230['get']['query'] }) =>
                    `${prefix}${prefix3}${PATH186}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                }
              },
              reservation_categories: {
                get: (option: { query: Methods231['get']['query'], config?: T }) =>
                  fetch<Methods231['get']['resBody'], BasicHeaders, Methods231['get']['status']>(prefix, `${prefix3}${PATH136}`, GET, option).json(),
                $get: (option: { query: Methods231['get']['query'], config?: T }) =>
                  fetch<Methods231['get']['resBody'], BasicHeaders, Methods231['get']['status']>(prefix, `${prefix3}${PATH136}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods231['put']['reqBody'], config?: T }) =>
                  fetch<Methods231['put']['resBody'], BasicHeaders, Methods231['put']['status']>(prefix, `${prefix3}${PATH136}`, PUT, option).json(),
                $put: (option: { body: Methods231['put']['reqBody'], config?: T }) =>
                  fetch<Methods231['put']['resBody'], BasicHeaders, Methods231['put']['status']>(prefix, `${prefix3}${PATH136}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods231['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH136}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              room_usages: {
                _room_usage_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH187}/${val5}`

                  return {
                    delete: (option: { query: Methods233['delete']['query'], config?: T }) =>
                      fetch<Methods233['delete']['resBody'], BasicHeaders, Methods233['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods233['delete']['query'], config?: T }) =>
                      fetch<Methods233['delete']['resBody'], BasicHeaders, Methods233['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods233['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                batchUpdate: {
                  post: (option: { body: Methods234['post']['reqBody'], config?: T }) =>
                    fetch<Methods234['post']['resBody'], BasicHeaders, Methods234['post']['status']>(prefix, `${prefix3}${PATH188}`, POST, option).json(),
                  $post: (option: { body: Methods234['post']['reqBody'], config?: T }) =>
                    fetch<Methods234['post']['resBody'], BasicHeaders, Methods234['post']['status']>(prefix, `${prefix3}${PATH188}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH188}`
                },
                get: (option: { query: Methods232['get']['query'], config?: T }) =>
                  fetch<Methods232['get']['resBody'], BasicHeaders, Methods232['get']['status']>(prefix, `${prefix3}${PATH187}`, GET, option).json(),
                $get: (option: { query: Methods232['get']['query'], config?: T }) =>
                  fetch<Methods232['get']['resBody'], BasicHeaders, Methods232['get']['status']>(prefix, `${prefix3}${PATH187}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods232['put']['reqBody'], config?: T }) =>
                  fetch<Methods232['put']['resBody'], BasicHeaders, Methods232['put']['status']>(prefix, `${prefix3}${PATH187}`, PUT, option).json(),
                $put: (option: { body: Methods232['put']['reqBody'], config?: T }) =>
                  fetch<Methods232['put']['resBody'], BasicHeaders, Methods232['put']['status']>(prefix, `${prefix3}${PATH187}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods232['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH187}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              shops: {
                _shop_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH141}/${val5}`

                  return {
                    checkReservableShopResources: {
                      post: (option: { body: Methods235['post']['reqBody'], config?: T }) =>
                        fetch<Methods235['post']['resBody'], BasicHeaders, Methods235['post']['status']>(prefix, `${prefix5}${PATH189}`, POST, option).json(),
                      $post: (option: { body: Methods235['post']['reqBody'], config?: T }) =>
                        fetch<Methods235['post']['resBody'], BasicHeaders, Methods235['post']['status']>(prefix, `${prefix5}${PATH189}`, POST, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH189}`
                    },
                    reservation_groups: {
                      _reservation_group_id: (val7: number) => {
                        const prefix7 = `${prefix5}${PATH63}/${val7}`

                        return {
                          patch: (option: { body: Methods237['patch']['reqBody'], config?: T }) =>
                            fetch<Methods237['patch']['resBody'], BasicHeaders, Methods237['patch']['status']>(prefix, prefix7, PATCH, option).json(),
                          $patch: (option: { body: Methods237['patch']['reqBody'], config?: T }) =>
                            fetch<Methods237['patch']['resBody'], BasicHeaders, Methods237['patch']['status']>(prefix, prefix7, PATCH, option).json().then(r => r.body),
                          $path: () => `${prefix}${prefix7}`
                        }
                      },
                      list: {
                        get: (option?: { query?: Methods238['get']['query'], config?: T }) =>
                          fetch<Methods238['get']['resBody'], BasicHeaders, Methods238['get']['status']>(prefix, `${prefix5}${PATH190}`, GET, option).json(),
                        $get: (option?: { query?: Methods238['get']['query'], config?: T }) =>
                          fetch<Methods238['get']['resBody'], BasicHeaders, Methods238['get']['status']>(prefix, `${prefix5}${PATH190}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods238['get']['query'] }) =>
                          `${prefix}${prefix5}${PATH190}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      },
                      get: (option: { query: Methods236['get']['query'], config?: T }) =>
                        fetch<Methods236['get']['resBody'], BasicHeaders, Methods236['get']['status']>(prefix, `${prefix5}${PATH63}`, GET, option).json(),
                      $get: (option: { query: Methods236['get']['query'], config?: T }) =>
                        fetch<Methods236['get']['resBody'], BasicHeaders, Methods236['get']['status']>(prefix, `${prefix5}${PATH63}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods236['get']['query'] }) =>
                        `${prefix}${prefix5}${PATH63}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    resources: {
                      machines: {
                        _shop_machine_id: (val8: number) => {
                          const prefix8 = `${prefix5}${PATH191}/${val8}`

                          return {
                            get: (option?: { config?: T }) =>
                              fetch<Methods240['get']['resBody'], BasicHeaders, Methods240['get']['status']>(prefix, prefix8, GET, option).json(),
                            $get: (option?: { config?: T }) =>
                              fetch<Methods240['get']['resBody'], BasicHeaders, Methods240['get']['status']>(prefix, prefix8, GET, option).json().then(r => r.body),
                            patch: (option: { body: Methods240['patch']['reqBody'], config?: T }) =>
                              fetch<Methods240['patch']['resBody'], BasicHeaders, Methods240['patch']['status']>(prefix, prefix8, PATCH, option).json(),
                            $patch: (option: { body: Methods240['patch']['reqBody'], config?: T }) =>
                              fetch<Methods240['patch']['resBody'], BasicHeaders, Methods240['patch']['status']>(prefix, prefix8, PATCH, option).json().then(r => r.body),
                            $path: () => `${prefix}${prefix8}`
                          }
                        },
                        get: (option?: { config?: T }) =>
                          fetch<Methods239['get']['resBody'], BasicHeaders, Methods239['get']['status']>(prefix, `${prefix5}${PATH191}`, GET, option).json(),
                        $get: (option?: { config?: T }) =>
                          fetch<Methods239['get']['resBody'], BasicHeaders, Methods239['get']['status']>(prefix, `${prefix5}${PATH191}`, GET, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix5}${PATH191}`
                      },
                      rooms: {
                        _shop_resource_id: (val8: number) => {
                          const prefix8 = `${prefix5}${PATH192}/${val8}`

                          return {
                            get: (option?: { config?: T }) =>
                              fetch<Methods242['get']['resBody'], BasicHeaders, Methods242['get']['status']>(prefix, prefix8, GET, option).json(),
                            $get: (option?: { config?: T }) =>
                              fetch<Methods242['get']['resBody'], BasicHeaders, Methods242['get']['status']>(prefix, prefix8, GET, option).json().then(r => r.body),
                            delete: (option: { query: Methods242['delete']['query'], config?: T }) =>
                              fetch<Methods242['delete']['resBody'], BasicHeaders, Methods242['delete']['status']>(prefix, prefix8, DELETE, option).json(),
                            $delete: (option: { query: Methods242['delete']['query'], config?: T }) =>
                              fetch<Methods242['delete']['resBody'], BasicHeaders, Methods242['delete']['status']>(prefix, prefix8, DELETE, option).json().then(r => r.body),
                            $path: (option?: { method: 'delete'; query: Methods242['delete']['query'] }) =>
                              `${prefix}${prefix8}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                          }
                        },
                        get: (option?: { config?: T }) =>
                          fetch<Methods241['get']['resBody'], BasicHeaders, Methods241['get']['status']>(prefix, `${prefix5}${PATH192}`, GET, option).json(),
                        $get: (option?: { config?: T }) =>
                          fetch<Methods241['get']['resBody'], BasicHeaders, Methods241['get']['status']>(prefix, `${prefix5}${PATH192}`, GET, option).json().then(r => r.body),
                        put: (option: { body: Methods241['put']['reqBody'], config?: T }) =>
                          fetch<Methods241['put']['resBody'], BasicHeaders, Methods241['put']['status']>(prefix, `${prefix5}${PATH192}`, PUT, option).json(),
                        $put: (option: { body: Methods241['put']['reqBody'], config?: T }) =>
                          fetch<Methods241['put']['resBody'], BasicHeaders, Methods241['put']['status']>(prefix, `${prefix5}${PATH192}`, PUT, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix5}${PATH192}`
                      }
                    },
                    searchReservationCandidateDates: {
                      post: (option: { body: Methods243['post']['reqBody'], config?: T }) =>
                        fetch<Methods243['post']['resBody'], BasicHeaders, Methods243['post']['status']>(prefix, `${prefix5}${PATH193}`, POST, option).json(),
                      $post: (option: { body: Methods243['post']['reqBody'], config?: T }) =>
                        fetch<Methods243['post']['resBody'], BasicHeaders, Methods243['post']['status']>(prefix, `${prefix5}${PATH193}`, POST, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH193}`
                    },
                    staffs: {
                      _staff_id: (val7: number) => {
                        const prefix7 = `${prefix5}${PATH161}/${val7}`

                        return {
                          get: (option?: { config?: T }) =>
                            fetch<Methods245['get']['resBody'], BasicHeaders, Methods245['get']['status']>(prefix, prefix7, GET, option).json(),
                          $get: (option?: { config?: T }) =>
                            fetch<Methods245['get']['resBody'], BasicHeaders, Methods245['get']['status']>(prefix, prefix7, GET, option).json().then(r => r.body),
                          put: (option: { body: Methods245['put']['reqBody'], config?: T }) =>
                            fetch<Methods245['put']['resBody'], BasicHeaders, Methods245['put']['status']>(prefix, prefix7, PUT, option).json(),
                          $put: (option: { body: Methods245['put']['reqBody'], config?: T }) =>
                            fetch<Methods245['put']['resBody'], BasicHeaders, Methods245['put']['status']>(prefix, prefix7, PUT, option).json().then(r => r.body),
                          $path: () => `${prefix}${prefix7}`
                        }
                      },
                      get: (option?: { config?: T }) =>
                        fetch<Methods244['get']['resBody'], BasicHeaders, Methods244['get']['status']>(prefix, `${prefix5}${PATH161}`, GET, option).json(),
                      $get: (option?: { config?: T }) =>
                        fetch<Methods244['get']['resBody'], BasicHeaders, Methods244['get']['status']>(prefix, `${prefix5}${PATH161}`, GET, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH161}`
                    }
                  }
                }
              },
              staff_groups: {
                _staff_group_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH194}/${val5}`

                  return {
                    delete: (option: { query: Methods247['delete']['query'], config?: T }) =>
                      fetch<Methods247['delete']['resBody'], BasicHeaders, Methods247['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods247['delete']['query'], config?: T }) =>
                      fetch<Methods247['delete']['resBody'], BasicHeaders, Methods247['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods247['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                batchUpdate: {
                  post: (option: { body: Methods248['post']['reqBody'], config?: T }) =>
                    fetch<Methods248['post']['resBody'], BasicHeaders, Methods248['post']['status']>(prefix, `${prefix3}${PATH195}`, POST, option).json(),
                  $post: (option: { body: Methods248['post']['reqBody'], config?: T }) =>
                    fetch<Methods248['post']['resBody'], BasicHeaders, Methods248['post']['status']>(prefix, `${prefix3}${PATH195}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH195}`
                },
                get: (option: { query: Methods246['get']['query'], config?: T }) =>
                  fetch<Methods246['get']['resBody'], BasicHeaders, Methods246['get']['status']>(prefix, `${prefix3}${PATH194}`, GET, option).json(),
                $get: (option: { query: Methods246['get']['query'], config?: T }) =>
                  fetch<Methods246['get']['resBody'], BasicHeaders, Methods246['get']['status']>(prefix, `${prefix3}${PATH194}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods246['put']['reqBody'], config?: T }) =>
                  fetch<Methods246['put']['resBody'], BasicHeaders, Methods246['put']['status']>(prefix, `${prefix3}${PATH194}`, PUT, option).json(),
                $put: (option: { body: Methods246['put']['reqBody'], config?: T }) =>
                  fetch<Methods246['put']['resBody'], BasicHeaders, Methods246['put']['status']>(prefix, `${prefix3}${PATH194}`, PUT, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods246['get']['query'] }) =>
                  `${prefix}${prefix3}${PATH194}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              web_reservation_customer_info_items: {
                get: (option?: { config?: T }) =>
                  fetch<Methods249['get']['resBody'], BasicHeaders, Methods249['get']['status']>(prefix, `${prefix3}${PATH196}`, GET, option).json(),
                $get: (option?: { config?: T }) =>
                  fetch<Methods249['get']['resBody'], BasicHeaders, Methods249['get']['status']>(prefix, `${prefix3}${PATH196}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods249['put']['reqBody'], config?: T }) =>
                  fetch<Methods249['put']['resBody'], BasicHeaders, Methods249['put']['status']>(prefix, `${prefix3}${PATH196}`, PUT, option).json(),
                $put: (option: { body: Methods249['put']['reqBody'], config?: T }) =>
                  fetch<Methods249['put']['resBody'], BasicHeaders, Methods249['put']['status']>(prefix, `${prefix3}${PATH196}`, PUT, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH196}`
              },
              web_reservation_menus: {
                _web_reservation_menu_id: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH197}/${val5}`

                  return {
                    get: (option?: { config?: T }) =>
                      fetch<Methods251['get']['resBody'], BasicHeaders, Methods251['get']['status']>(prefix, prefix5, GET, option).json(),
                    $get: (option?: { config?: T }) =>
                      fetch<Methods251['get']['resBody'], BasicHeaders, Methods251['get']['status']>(prefix, prefix5, GET, option).json().then(r => r.body),
                    delete: (option: { query: Methods251['delete']['query'], config?: T }) =>
                      fetch<Methods251['delete']['resBody'], BasicHeaders, Methods251['delete']['status']>(prefix, prefix5, DELETE, option).json(),
                    $delete: (option: { query: Methods251['delete']['query'], config?: T }) =>
                      fetch<Methods251['delete']['resBody'], BasicHeaders, Methods251['delete']['status']>(prefix, prefix5, DELETE, option).json().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods251['delete']['query'] }) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                get: (option?: { config?: T }) =>
                  fetch<Methods250['get']['resBody'], BasicHeaders, Methods250['get']['status']>(prefix, `${prefix3}${PATH197}`, GET, option).json(),
                $get: (option?: { config?: T }) =>
                  fetch<Methods250['get']['resBody'], BasicHeaders, Methods250['get']['status']>(prefix, `${prefix3}${PATH197}`, GET, option).json().then(r => r.body),
                put: (option: { body: Methods250['put']['reqBody'], config?: T }) =>
                  fetch<Methods250['put']['resBody'], BasicHeaders, Methods250['put']['status']>(prefix, `${prefix3}${PATH197}`, PUT, option).json(),
                $put: (option: { body: Methods250['put']['reqBody'], config?: T }) =>
                  fetch<Methods250['put']['resBody'], BasicHeaders, Methods250['put']['status']>(prefix, `${prefix3}${PATH197}`, PUT, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH197}`
              }
            }
          }
        }
      }
    }
  }
}

// prettier-ignore
export type ApiInstance = ReturnType<typeof api>
// prettier-ignore
export default api
