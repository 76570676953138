import { useEffectOnce } from '@/lib/hooks/useEffectOnce'
import { nextTick } from '@/lib/nextTick'
import { RecoilState, useResetRecoilState } from 'recoil'

export const useResetRecoilStateOnUnmount = (recoilValue: RecoilState<any>) => {
  const reset = useResetRecoilState(recoilValue)

  useEffectOnce(() => {
    return () => {
      nextTick(() => {
        reset()
      })
    }
  })
}
