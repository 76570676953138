/* eslint-disable @typescript-eslint/no-namespace */ const Kind = {
  title: 'ErrorKind',
  entries: [
    {
      id: 400000,
      key: 'bad_request',
      value: null,
      messages: {
        ja: {
          management: '不正なリクエストです',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 401000,
      key: 'unauthorized',
      value: null,
      messages: {
        ja: {
          management:
            '認証されていません。再ログインして操作をやり直してください。',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 403000,
      key: 'forbidden',
      value: null,
      messages: {
        ja: {
          management:
            'その操作は許可されていません。ご自身のスタッフアカウントの職種をご確認ください。',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 404000,
      key: 'not_found',
      value: null,
      messages: {
        ja: {
          management:
            '当該データが見つかりません。リロードして操作をやり直してください。',
          customer:
            'ページを再読込し操作をやり直してください。それでも解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 408000,
      key: 'request_timeout',
      value: null,
      messages: {
        ja: {
          management:
            'リクエストが中断されため、操作を完了出来ませんでした。操作をやり直してください。',
          customer:
            'リクエストが中断されため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 409000,
      key: 'conflict',
      value: null,
      messages: {
        ja: {
          management:
            'データが更新されたため、リロードして操作をやり直してください',
          customer:
            'ページを再読込し操作をやり直してください。それでも解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 429000,
      key: 'too_many_requests',
      value: null,
      messages: {
        ja: {
          management: '不正なリクエストです',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 500000,
      key: 'internal_server_error',
      value: null,
      messages: {
        ja: {
          management:
            '予期せぬ問題が発生しました。再度操作しても解消しない場合は、サポートにお問い合わせください。',
          customer:
            '予期せぬ問題が発生しました。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 502000,
      key: 'bad_gateway',
      value: null,
      messages: {
        ja: {
          management:
            '予期せぬ問題が発生しました。再度操作しても解消しない場合は、サポートにお問い合わせください。',
          customer:
            '予期せぬ問題が発生しました。再度操作しても解消しない場合は、サポートにお問い合わせください。',
        },
      },
    },
    {
      id: 503000,
      key: 'service_unavailable',
      value: null,
      messages: {
        ja: {
          management:
            '予期せぬ問題が発生しました。再度操作しても解消しない場合は、サポートにお問い合わせください。',
          customer:
            '予期せぬ問題が発生しました。再度操作しても解消しない場合は、サポートにお問い合わせください。',
        },
      },
    },
    {
      id: 400001,
      key: 'validation_failed',
      value: null,
      messages: {
        ja: {
          management:
            '入力データに誤りがあります。入力データをご確認の上、操作をやり直してください。',
          customer:
            '入力データに誤りがあります。入力データをご確認の上、操作をやり直してください。それでも解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400002,
      key: 'entity_in_used',
      value: null,
      messages: {
        ja: {
          management: '当該データが他の箇所で使用されているため削除出来ません',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400003,
      key: 'request_not_allowed',
      value: null,
      messages: {
        ja: {
          management: '指定されたデータは操作の対象外です',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400004,
      key: 'duplicate_entry',
      value: null,
      messages: {
        ja: {
          management:
            '既に存在するデータと区別出来ません。入力項目を見直して操作をやり直してください。',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400005,
      key: 'invalid_entity_status',
      value: null,
      messages: {
        ja: {
          management:
            '現在ご利用出来ないデータを指定しているので、リロードして操作をやり直してください。',
          customer:
            '選択された内容が現在ご利用いただけません。誠に恐れ入りますが、ご選択し直しをお願いいたします。',
        },
      },
    },
    {
      id: 400006,
      key: 'batch_update_failed',
      value: null,
      messages: {
        ja: {
          management:
            '一括更新時にエラーが発生しました。リロードして操作をやり直してください。',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400008,
      key: 'already_exists',
      value: null,
      messages: {
        ja: {
          management: '既に存在するため、追加登録出来ません',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 403001,
      key: 'out_of_term',
      value: null,
      messages: {
        ja: {
          management: 'WEB予約の利用期間外です',
          customer:
            '現在利用期間外です。ご利用可能な期間についてはお問い合わせください。',
        },
      },
    },
    {
      id: 404001,
      key: 'not_found_sub_entity',
      value: null,
      messages: {
        ja: {
          management:
            '現在ご利用出来ないデータを指定しているので、リロードして操作をやり直してください',
          customer:
            '選択された内容が現在ご利用いただけません。誠に恐れ入りますが、ご選択し直しをお願いします',
        },
      },
    },
    {
      id: 500001,
      key: 'failed_to_parse_template',
      value: null,
      messages: {
        ja: {
          management: 'メッセージテンプレートの形式が正しくありません',
          customer:
            '予期せぬ問題が発生しました。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 500002,
      key: 'failed_to_create_pdf',
      value: null,
      messages: {
        ja: {
          management:
            '%{DOCUMENT_TEMPLATE_TITLE}のPDFの作成に失敗しました。再度操作しても解消しない場合は、サポートにお問い合わせください。',
          customer:
            '予期せぬ問題が発生しました。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 500003,
      key: 'failed_to_send_message',
      value: null,
      messages: {
        ja: {
          management:
            'メッセージの送信が完了出来ませんでした。再度操作しても解消しない場合は、サポートにお問い合わせください。',
          customer:
            'メッセージの送信が完了出来ませんでした。再度操作しても解消しない場合は、サポートにお問い合わせください。',
        },
      },
    },
    {
      id: 404101,
      key: 'not_found_target_reservation',
      value: null,
      messages: {
        ja: {
          management:
            '本日の予約が登録されていないため、チェックインが出来ません。本日予約なさっていた方は、誠に恐れ入りますがお問い合わせください。',
          customer:
            '本日の予約が登録されていないため、チェックインが出来ません。本日予約なさっていた方は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 404102,
      key: 'not_found_email_temporary',
      value: null,
      messages: {
        ja: {
          management:
            'メールアドレスの認証が完了できませんでした。お手数ですが、お手持ちのデジタル診察券より再度メールアドレスの変更を行なってください。',
          customer:
            'メールアドレスの認証が完了できませんでした。お手数ですが、お手持ちのデジタル診察券より再度メールアドレスの変更を行なってください。',
        },
      },
    },
    {
      id: 400101,
      key: 'invalid_status_web_reservation_menu',
      value: null,
      messages: {
        ja: {
          management: '',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400102,
      key: 'over_web_reservation_concurrent_reservable_count',
      value: null,
      messages: {
        ja: {
          management:
            '指定されたメニューの規定の最大予約数を超える予約は出来ません。詳細につきましては、誠に恐れ入りますがお問い合わせの上ご確認ください。',
          customer:
            '指定されたメニューの規定の最大予約数を超える予約は出来ません。詳細につきましては、誠に恐れ入りますがお問い合わせの上ご確認ください。',
        },
      },
    },
    {
      id: 400103,
      key: 'over_web_reservation_monthly_reservable_count',
      value: null,
      messages: {
        ja: {
          management:
            '指定されたメニューの規定の月間予約数を超える予約は出来ません。詳細につきましては、誠に恐れ入りますがお問い合わせの上ご確認ください。',
          customer:
            '指定されたメニューの規定の月間予約数を超える予約は出来ません。詳細につきましては、誠に恐れ入りますがお問い合わせの上ご確認ください。',
        },
      },
    },
    {
      id: 400104,
      key: 'not_enough_web_reservation_interval_days',
      value: null,
      messages: {
        ja: {
          management:
            '指定されたメニューの規定より短い間隔の予約は出来ません。詳細につきましては、誠に恐れ入りますがお問い合わせの上ご確認ください。',
          customer:
            '指定されたメニューの規定より短い間隔の予約は出来ません。詳細につきましては、誠に恐れ入りますがお問い合わせの上ご確認ください。',
        },
      },
    },
    {
      id: 400105,
      key: 'over_web_reservation_duty_total_num',
      value: null,
      messages: {
        ja: {
          management:
            'ご利用可能な回数を超える予約は出来ません。詳細につきましては、誠に恐れ入りますがお問い合わせの上ご確認ください。',
          customer:
            'ご利用可能な回数を超える予約は出来ません。詳細につきましては、誠に恐れ入りますがお問い合わせの上ご確認ください。',
        },
      },
    },
    {
      id: 400106,
      key: 'web_reservation_disabled_in_specified_time',
      value: null,
      messages: {
        ja: {
          management:
            '誠に恐れ入りますが、ご希望の日時は現在ご予約いただけません。日時選択画面に戻り、再度ご希望の日時のご選択をお願いいたします',
          customer:
            '誠に恐れ入りますが、ご希望の日時は現在ご予約いただけません。日時選択画面に戻り、再度ご希望の日時のご選択をお願いいたします',
        },
      },
    },
    {
      id: 400107,
      key: 'expired_email_temporary',
      value: null,
      messages: {
        ja: {
          management:
            'リンクの有効期限が切れています。お手数ですが、お手持ちのデジタル診察券より再度メールアドレスの変更を行なってください。',
          customer:
            'リンクの有効期限が切れています。お手数ですが、お手持ちのデジタル診察券より再度メールアドレスの変更を行なってください。',
        },
      },
    },
    {
      id: 400108,
      key: 'expired_connect_line_temporary',
      value: null,
      messages: {
        ja: {
          management:
            '確認の有効期限を過ぎています。誠に恐れ入りますが、ID連携をし直してください。',
          customer:
            '確認の有効期限を過ぎています。誠に恐れ入りますが、ID連携をし直してください。',
        },
      },
    },
    {
      id: 400109,
      key: 'not_match_connect_line_temporary_code',
      value: null,
      messages: {
        ja: {
          management:
            '確認コードが一致しません。ご確認の上、入力し直してください。',
          customer:
            '確認コードが一致しません。ご確認の上、入力し直してください。',
        },
      },
    },
    {
      id: 500100,
      key: 'line_communication_failure',
      value: null,
      messages: {
        ja: {
          management:
            'LINEのサービス利用時に予期せぬ問題が起きました。操作をやり直して下さい。',
          customer:
            '予期せぬ問題が発生しました。再度操作しても解消しない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400110,
      key: 'duplicate_customer_by_tel',
      value: null,
      messages: {
        ja: {
          management:
            '既に登録されている方は新規予約出来ません。\n登録した覚えがない場合は、誠に恐れ入りますがお問い合わせください。',
          customer:
            '既に登録されている方は新規予約出来ません。\n登録した覚えがない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400111,
      key: 'already_answered_interview',
      value: null,
      messages: {
        ja: {
          management: 'WEB問診票にご回答済みです。',
          customer: 'WEB問診票にご回答済みです。',
        },
      },
    },
    {
      id: 400112,
      key: 'line_invalid_customer',
      value: null,
      messages: {
        ja: {
          management: '確認コードを送信出来ませんでした。',
          customer:
            '確認用コードの送信に失敗しました。ご入力いただいた内容を確認の上、再度操作しても解消されない場合は、誠に恐れ入りますが店舗までお問い合わせください。',
        },
      },
    },
    {
      id: 400113,
      key: 'unable_to_send_mail',
      value: null,
      messages: {
        ja: {
          management:
            'メールアドレスが登録されていないため、メールを送信できません',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400114,
      key: 'unable_to_send_sms',
      value: null,
      messages: {
        ja: {
          management: '電話番号が登録されていないため、SMSを送信できません',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400115,
      key: 'unable_to_send_line_message',
      value: null,
      messages: {
        ja: {
          management:
            'LINEのユーザーIDが登録されていないため、LINEを送信できません',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400116,
      key: 'unable_to_cancel_reservation',
      value: null,
      messages: {
        ja: {
          management:
            '予約のキャンセルができません。誠に恐れ入りますが、予約店舗までお問い合わせください',
          customer:
            '予約のキャンセルができません。誠に恐れ入りますが、予約店舗までお問い合わせください',
        },
      },
    },
    {
      id: 400117,
      key: 'disabled_to_edit_ekarte',
      value: null,
      messages: {
        ja: {
          management: 'カルテの編集・削除は当日のみ可能です',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400118,
      key: 'room_not_found',
      value: null,
      messages: {
        ja: {
          management: '当該予約種類を対応予約種類に含む部屋がありません',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400119,
      key: 'unable_to_send_test_with_not_template',
      value: null,
      messages: {
        ja: {
          management: 'テスト送信はメールテンプレートでのみ可能です',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400120,
      key: 'unable_to_send_test_with_invalid_staff',
      value: null,
      messages: {
        ja: {
          management:
            'アカウントが停止中またはメールアドレス未登録のスタッフはテスト送信出来ません',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400121,
      key: 'over_limit_register_count',
      value: null,
      messages: {
        ja: {
          management:
            '登録可能件数を超えています。上限を超えて登録したい場合は、サポートにお問い合わせ下さい。',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400122,
      key: 'invalid_id_or_password',
      value: null,
      messages: {
        ja: {
          management:
            'IDまたはパスワードが間違っています。ご確認の上入力し直して下さい。',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400123,
      key: 'over_duty_capacity',
      value: null,
      messages: {
        ja: {
          management:
            '役務回数分の予約が既に登録されている為、登録できません。',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400124,
      key: 'invalid_staff',
      value: null,
      messages: {
        ja: {
          management: '状態が無効なスタッフアカウントではログイン出来ません',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400125,
      key: 'expired_password_reset',
      value: null,
      messages: {
        ja: {
          management:
            'パスワードリセットの有効期限を過ぎました。再度パスワードリセットをご依頼ください。',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400126,
      key: 'not_purchased_product',
      value: null,
      messages: {
        ja: {
          management:
            '対象の商品は購入されていないため施術実績を登録できません',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400127,
      key: 'specified_previous_shift',
      value: null,
      messages: {
        ja: {
          management:
            '以前のシフト設定を指定しているため、シフトを更新出来ません。リロードして操作をやり直してください。',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400128,
      key: 'unable_to_disable_system_role',
      value: null,
      messages: {
        ja: {
          management: 'システム規定の職種は無効化できません',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400129,
      key: 'unable_to_change_communication',
      value: null,
      messages: {
        ja: {
          management: 'ご指定のコミュニケーション種類には変更出来ません',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400130,
      key: 'unable_to_cancel_noncancelled_contract',
      value: null,
      messages: {
        ja: {
          management:
            'キャンセルされていない販売のため、キャンセル取り消しは出来ません',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400131,
      key: 'unable_to_cancel_noncancelled_contract_detail',
      value: null,
      messages: {
        ja: {
          management:
            'キャンセルされていない販売明細のため、キャンセル取り消しは出来ません',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400132,
      key: 'failed_to_batch_update_for_shop_reservation_group',
      value: null,
      messages: {
        ja: {
          management:
            'キャンセルされた予約を含むため処理が完了しませんでした。リロードして操作をやり直してください。',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400133,
      key: 'updated_contract_for_customer',
      value: null,
      messages: {
        ja: {
          management:
            '当該顧客の販売が変更されたため、リロードして操作をやり直してください。',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400134,
      key: 'failed_to_create_document',
      value: null,
      messages: {
        ja: {
          management:
            '販売がキャンセルされたか、商品が契約対象でなくなったため処理が完了しませんでした。リロードして操作をやり直してください。',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400135,
      key: 'unable_to_delete_the_only_admin_account',
      value: null,
      messages: {
        ja: {
          management:
            '管理者権限を持つ有効なスタッフアカウントがなくなってしまうため変更出来ません',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400136,
      key: 'unable_to_get_enquete_in_the_template',
      value: null,
      messages: {
        ja: {
          management:
            'テンプレートに記載されたアンケートが取得できませんでした',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400137,
      key: 'already_answered_enquete',
      value: null,
      messages: {
        ja: {
          management: 'アンケートにご回答済みです。',
          customer: 'アンケートにご回答済みです。',
        },
      },
    },
    {
      id: 400138,
      key: 'unable_to_delete_the_sent_enquete',
      value: null,
      messages: {
        ja: {
          management: '顧客に送信済みのため削除出来ません',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 404002,
      key: 'not_found_zip_code',
      value: null,
      messages: {
        ja: {
          management:
            '存在しない郵便番号です。正しい郵便番号を入力してください。',
          customer:
            '存在しない郵便番号です。正しい郵便番号を入力してください。',
        },
      },
    },
    {
      id: 400139,
      key: 'online_payment_session_expired',
      value: null,
      messages: {
        ja: {
          management:
            '対象のクレジット決済は有効期限が切れています。誠に恐れ入りますがお問い合わせください。',
          customer:
            '対象のクレジット決済は有効期限が切れています。誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400140,
      key: 'online_payment_invalid_status',
      value: null,
      messages: {
        ja: {
          management:
            'リクエストに問題があったため、操作を完了出来ませんでした。誠に恐れ入りますがお問い合わせください。',
          customer: '支払い済みです。',
        },
      },
    },
    {
      id: 400141,
      key: 'unable_to_use_online_payment',
      value: null,
      messages: {
        ja: {
          management:
            'オンライン決済は利用できません。管理者にお問い合わせください。',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400142,
      key: 'unable_to_use_online_payment_installment',
      value: null,
      messages: {
        ja: {
          management:
            'ご指定の分割方法はクレジット決済に対応していません。誠に恐れ入りますがお問い合わせください。',
          customer:
            'ご指定の分割方法はクレジット決済に対応していません。誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400143,
      key: 'online_payment_invalid_deposit_amount',
      value: null,
      messages: {
        ja: {
          management:
            'ご精算金額が更新されました。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
          customer:
            'ご精算金額が更新されました。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400144,
      key: 'denied_signin_by_not_available_tenant',
      value: null,
      messages: {
        ja: {
          management: 'クローズされた事業者様のためサインインできません',
          customer: 'クローズされた事業者様のためサインインできません',
        },
      },
    },
    {
      id: 400146,
      key: 'already_fixed_to_contract',
      value: null,
      messages: {
        ja: {
          management:
            '既に販売確定済みです。リロードして操作をやり直してください。',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 400147,
      key: 'contract_receipt_session_invalid_or_expired',
      value: null,
      messages: {
        ja: {
          management: '無効なsessionもしくは有効期限切れです。',
          customer:
            '間違ったURLまたは有効期限切れのため、領収書をダウンロードできませんでした。デジタル診察券から保存し直してください。',
        },
      },
    },
    {
      id: 400148,
      key: 'valid_visitation_status_not_exists',
      value: null,
      messages: {
        ja: {
          management:
            '予約管理画面上で表示する項目を一つ以上設定してください。',
          customer:
            'リクエストに問題があったため、操作を完了出来ませんでした。再度操作しても解消されない場合は、誠に恐れ入りますがお問い合わせください。',
        },
      },
    },
    {
      id: 503999,
      key: 'under_maintenance',
      value: null,
      messages: {
        ja: {
          management: 'メンテナンス期間中です。',
          customer: 'メンテナンス期間中です。',
        },
      },
    },
  ],
}
const ids = {
  BadRequest: 400000,
  Unauthorized: 401000,
  Forbidden: 403000,
  NotFound: 404000,
  RequestTimeout: 408000,
  Conflict: 409000,
  TooManyRequests: 429000,
  InternalServerError: 500000,
  BadGateway: 502000,
  ServiceUnavailable: 503000,
  ValidationFailed: 400001,
  EntityInUsed: 400002,
  RequestNotAllowed: 400003,
  DuplicateEntry: 400004,
  InvalidEntityStatus: 400005,
  BatchUpdateFailed: 400006,
  AlreadyExists: 400008,
  OutOfTerm: 403001,
  NotFoundSubEntity: 404001,
  FailedToParseTemplate: 500001,
  FailedToCreatePdf: 500002,
  FailedToSendMessage: 500003,
  NotFoundTargetReservation: 404101,
  NotFoundEmailTemporary: 404102,
  InvalidStatusWebReservationMenu: 400101,
  OverWebReservationConcurrentReservableCount: 400102,
  OverWebReservationMonthlyReservableCount: 400103,
  NotEnoughWebReservationIntervalDays: 400104,
  OverWebReservationDutyTotalNum: 400105,
  WebReservationDisabledInSpecifiedTime: 400106,
  ExpiredEmailTemporary: 400107,
  ExpiredConnectLineTemporary: 400108,
  NotMatchConnectLineTemporaryCode: 400109,
  LineCommunicationFailure: 500100,
  DuplicateCustomerByTel: 400110,
  AlreadyAnsweredInterview: 400111,
  LineInvalidCustomer: 400112,
  UnableToSendMail: 400113,
  UnableToSendSms: 400114,
  UnableToSendLineMessage: 400115,
  UnableToCancelReservation: 400116,
  DisabledToEditEkarte: 400117,
  RoomNotFound: 400118,
  UnableToSendTestWithNotTemplate: 400119,
  UnableToSendTestWithInvalidStaff: 400120,
  OverLimitRegisterCount: 400121,
  InvalidIdOrPassword: 400122,
  OverDutyCapacity: 400123,
  InvalidStaff: 400124,
  ExpiredPasswordReset: 400125,
  NotPurchasedProduct: 400126,
  SpecifiedPreviousShift: 400127,
  UnableToDisableSystemRole: 400128,
  UnableToChangeCommunication: 400129,
  UnableToCancelNoncancelledContract: 400130,
  UnableToCancelNoncancelledContractDetail: 400131,
  FailedToBatchUpdateForShopReservationGroup: 400132,
  UpdatedContractForCustomer: 400133,
  FailedToCreateDocument: 400134,
  UnableToDeleteTheOnlyAdminAccount: 400135,
  UnableToGetEnqueteInTheTemplate: 400136,
  AlreadyAnsweredEnquete: 400137,
  UnableToDeleteTheSentEnquete: 400138,
  NotFoundZipCode: 404002,
  OnlinePaymentSessionExpired: 400139,
  OnlinePaymentInvalidStatus: 400140,
  UnableToUseOnlinePayment: 400141,
  UnableToUseOnlinePaymentInstallment: 400142,
  OnlinePaymentInvalidDepositAmount: 400143,
  DeniedSigninByNotAvailableTenant: 400144,
  AlreadyFixedToContract: 400146,
  ContractReceiptSessionInvalidOrExpired: 400147,
  ValidVisitationStatusNotExists: 400148,
  UnderMaintenance: 503999,
} as const
export namespace ErrorKind {
  export type ids = (typeof ids)[keyof typeof ids]
  export const entries = Kind.entries
  export const getIdByLabel = (label: string) =>
    entries.find((kind) => kind.value === label)?.id || 0
  export const getLabelById = (id: number) =>
    entries.find((kind) => kind.id === id)?.value || 'undefined'
  export namespace BadRequest {
    export const id = ids.BadRequest
    export const label = getLabelById(id)
  }
  export namespace Unauthorized {
    export const id = ids.Unauthorized
    export const label = getLabelById(id)
  }
  export namespace Forbidden {
    export const id = ids.Forbidden
    export const label = getLabelById(id)
  }
  export namespace NotFound {
    export const id = ids.NotFound
    export const label = getLabelById(id)
  }
  export namespace RequestTimeout {
    export const id = ids.RequestTimeout
    export const label = getLabelById(id)
  }
  export namespace Conflict {
    export const id = ids.Conflict
    export const label = getLabelById(id)
  }
  export namespace TooManyRequests {
    export const id = ids.TooManyRequests
    export const label = getLabelById(id)
  }
  export namespace InternalServerError {
    export const id = ids.InternalServerError
    export const label = getLabelById(id)
  }
  export namespace BadGateway {
    export const id = ids.BadGateway
    export const label = getLabelById(id)
  }
  export namespace ServiceUnavailable {
    export const id = ids.ServiceUnavailable
    export const label = getLabelById(id)
  }
  export namespace ValidationFailed {
    export const id = ids.ValidationFailed
    export const label = getLabelById(id)
  }
  export namespace EntityInUsed {
    export const id = ids.EntityInUsed
    export const label = getLabelById(id)
  }
  export namespace RequestNotAllowed {
    export const id = ids.RequestNotAllowed
    export const label = getLabelById(id)
  }
  export namespace DuplicateEntry {
    export const id = ids.DuplicateEntry
    export const label = getLabelById(id)
  }
  export namespace InvalidEntityStatus {
    export const id = ids.InvalidEntityStatus
    export const label = getLabelById(id)
  }
  export namespace BatchUpdateFailed {
    export const id = ids.BatchUpdateFailed
    export const label = getLabelById(id)
  }
  export namespace AlreadyExists {
    export const id = ids.AlreadyExists
    export const label = getLabelById(id)
  }
  export namespace OutOfTerm {
    export const id = ids.OutOfTerm
    export const label = getLabelById(id)
  }
  export namespace NotFoundSubEntity {
    export const id = ids.NotFoundSubEntity
    export const label = getLabelById(id)
  }
  export namespace FailedToParseTemplate {
    export const id = ids.FailedToParseTemplate
    export const label = getLabelById(id)
  }
  export namespace FailedToCreatePdf {
    export const id = ids.FailedToCreatePdf
    export const label = getLabelById(id)
  }
  export namespace FailedToSendMessage {
    export const id = ids.FailedToSendMessage
    export const label = getLabelById(id)
  }
  export namespace NotFoundTargetReservation {
    export const id = ids.NotFoundTargetReservation
    export const label = getLabelById(id)
  }
  export namespace NotFoundEmailTemporary {
    export const id = ids.NotFoundEmailTemporary
    export const label = getLabelById(id)
  }
  export namespace InvalidStatusWebReservationMenu {
    export const id = ids.InvalidStatusWebReservationMenu
    export const label = getLabelById(id)
  }
  export namespace OverWebReservationConcurrentReservableCount {
    export const id = ids.OverWebReservationConcurrentReservableCount
    export const label = getLabelById(id)
  }
  export namespace OverWebReservationMonthlyReservableCount {
    export const id = ids.OverWebReservationMonthlyReservableCount
    export const label = getLabelById(id)
  }
  export namespace NotEnoughWebReservationIntervalDays {
    export const id = ids.NotEnoughWebReservationIntervalDays
    export const label = getLabelById(id)
  }
  export namespace OverWebReservationDutyTotalNum {
    export const id = ids.OverWebReservationDutyTotalNum
    export const label = getLabelById(id)
  }
  export namespace WebReservationDisabledInSpecifiedTime {
    export const id = ids.WebReservationDisabledInSpecifiedTime
    export const label = getLabelById(id)
  }
  export namespace ExpiredEmailTemporary {
    export const id = ids.ExpiredEmailTemporary
    export const label = getLabelById(id)
  }
  export namespace ExpiredConnectLineTemporary {
    export const id = ids.ExpiredConnectLineTemporary
    export const label = getLabelById(id)
  }
  export namespace NotMatchConnectLineTemporaryCode {
    export const id = ids.NotMatchConnectLineTemporaryCode
    export const label = getLabelById(id)
  }
  export namespace LineCommunicationFailure {
    export const id = ids.LineCommunicationFailure
    export const label = getLabelById(id)
  }
  export namespace DuplicateCustomerByTel {
    export const id = ids.DuplicateCustomerByTel
    export const label = getLabelById(id)
  }
  export namespace AlreadyAnsweredInterview {
    export const id = ids.AlreadyAnsweredInterview
    export const label = getLabelById(id)
  }
  export namespace LineInvalidCustomer {
    export const id = ids.LineInvalidCustomer
    export const label = getLabelById(id)
  }
  export namespace UnableToSendMail {
    export const id = ids.UnableToSendMail
    export const label = getLabelById(id)
  }
  export namespace UnableToSendSms {
    export const id = ids.UnableToSendSms
    export const label = getLabelById(id)
  }
  export namespace UnableToSendLineMessage {
    export const id = ids.UnableToSendLineMessage
    export const label = getLabelById(id)
  }
  export namespace UnableToCancelReservation {
    export const id = ids.UnableToCancelReservation
    export const label = getLabelById(id)
  }
  export namespace DisabledToEditEkarte {
    export const id = ids.DisabledToEditEkarte
    export const label = getLabelById(id)
  }
  export namespace RoomNotFound {
    export const id = ids.RoomNotFound
    export const label = getLabelById(id)
  }
  export namespace UnableToSendTestWithNotTemplate {
    export const id = ids.UnableToSendTestWithNotTemplate
    export const label = getLabelById(id)
  }
  export namespace UnableToSendTestWithInvalidStaff {
    export const id = ids.UnableToSendTestWithInvalidStaff
    export const label = getLabelById(id)
  }
  export namespace OverLimitRegisterCount {
    export const id = ids.OverLimitRegisterCount
    export const label = getLabelById(id)
  }
  export namespace InvalidIdOrPassword {
    export const id = ids.InvalidIdOrPassword
    export const label = getLabelById(id)
  }
  export namespace OverDutyCapacity {
    export const id = ids.OverDutyCapacity
    export const label = getLabelById(id)
  }
  export namespace InvalidStaff {
    export const id = ids.InvalidStaff
    export const label = getLabelById(id)
  }
  export namespace ExpiredPasswordReset {
    export const id = ids.ExpiredPasswordReset
    export const label = getLabelById(id)
  }
  export namespace NotPurchasedProduct {
    export const id = ids.NotPurchasedProduct
    export const label = getLabelById(id)
  }
  export namespace SpecifiedPreviousShift {
    export const id = ids.SpecifiedPreviousShift
    export const label = getLabelById(id)
  }
  export namespace UnableToDisableSystemRole {
    export const id = ids.UnableToDisableSystemRole
    export const label = getLabelById(id)
  }
  export namespace UnableToChangeCommunication {
    export const id = ids.UnableToChangeCommunication
    export const label = getLabelById(id)
  }
  export namespace UnableToCancelNoncancelledContract {
    export const id = ids.UnableToCancelNoncancelledContract
    export const label = getLabelById(id)
  }
  export namespace UnableToCancelNoncancelledContractDetail {
    export const id = ids.UnableToCancelNoncancelledContractDetail
    export const label = getLabelById(id)
  }
  export namespace FailedToBatchUpdateForShopReservationGroup {
    export const id = ids.FailedToBatchUpdateForShopReservationGroup
    export const label = getLabelById(id)
  }
  export namespace UpdatedContractForCustomer {
    export const id = ids.UpdatedContractForCustomer
    export const label = getLabelById(id)
  }
  export namespace FailedToCreateDocument {
    export const id = ids.FailedToCreateDocument
    export const label = getLabelById(id)
  }
  export namespace UnableToDeleteTheOnlyAdminAccount {
    export const id = ids.UnableToDeleteTheOnlyAdminAccount
    export const label = getLabelById(id)
  }
  export namespace UnableToGetEnqueteInTheTemplate {
    export const id = ids.UnableToGetEnqueteInTheTemplate
    export const label = getLabelById(id)
  }
  export namespace AlreadyAnsweredEnquete {
    export const id = ids.AlreadyAnsweredEnquete
    export const label = getLabelById(id)
  }
  export namespace UnableToDeleteTheSentEnquete {
    export const id = ids.UnableToDeleteTheSentEnquete
    export const label = getLabelById(id)
  }
  export namespace NotFoundZipCode {
    export const id = ids.NotFoundZipCode
    export const label = getLabelById(id)
  }
  export namespace OnlinePaymentSessionExpired {
    export const id = ids.OnlinePaymentSessionExpired
    export const label = getLabelById(id)
  }
  export namespace OnlinePaymentInvalidStatus {
    export const id = ids.OnlinePaymentInvalidStatus
    export const label = getLabelById(id)
  }
  export namespace UnableToUseOnlinePayment {
    export const id = ids.UnableToUseOnlinePayment
    export const label = getLabelById(id)
  }
  export namespace UnableToUseOnlinePaymentInstallment {
    export const id = ids.UnableToUseOnlinePaymentInstallment
    export const label = getLabelById(id)
  }
  export namespace OnlinePaymentInvalidDepositAmount {
    export const id = ids.OnlinePaymentInvalidDepositAmount
    export const label = getLabelById(id)
  }
  export namespace DeniedSigninByNotAvailableTenant {
    export const id = ids.DeniedSigninByNotAvailableTenant
    export const label = getLabelById(id)
  }
  export namespace AlreadyFixedToContract {
    export const id = ids.AlreadyFixedToContract
    export const label = getLabelById(id)
  }
  export namespace ContractReceiptSessionInvalidOrExpired {
    export const id = ids.ContractReceiptSessionInvalidOrExpired
    export const label = getLabelById(id)
  }
  export namespace ValidVisitationStatusNotExists {
    export const id = ids.ValidVisitationStatusNotExists
    export const label = getLabelById(id)
  }
  export namespace UnderMaintenance {
    export const id = ids.UnderMaintenance
    export const label = getLabelById(id)
  }
}
